import { message } from "antd";
import { FC, useEffect, useState } from "react";
import { shallowEqual, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import { PayChannel } from "../../../constants/payment";
import { makeOrder } from "../../../services/payment";
import { handleOrder } from "../../../services/order";
import {
  rechargeVipBalance,
  HandlerVipBalance,
} from "../../../services/vipcard";
import { useKeyPress } from "ahooks";
import QRCode from "qrcode";
import { clearKeyTime, startInterval } from "../../../utils/interval";
import { INIT, Ordertype, OrderTypeIndex } from "../../../constants/order";
import { getStorageSync, setStorageSync } from "../../../utils/uitls";
import { showLoading } from "../../../utils/Toast";
import getPayResult from "../../../utils/pay-result";

import "./index.scss";
import { formatUrl } from "../../../utils/format";
interface IScanningPayDialogProps {
  type?: string;
  RQCode?: string;
  payInfo?: any;
  memberNumber?: string; // 会员电话
  OrderAmount?: string; //购物车直接扫码支付金额或者会员充值金额
  giftCardNumber?: string; //礼品卡卡号
  memberInfo?: object; // 会员信息
  setMemberPayShow?(handle: boolean): void; //会员支付弹窗显示
  setScanShow?(handle: boolean): void; // 会员支付弹窗显示
  paymethod?: number;
  orderid?: any;
  status?: any;
  getMemberDetail?():void;
}
var scancode = ''

const ScanningPayDialog: FC<IScanningPayDialogProps> = (
  props: IScanningPayDialogProps
) => {
  const {
    memberNumber,
    OrderAmount,
    type,
    orderid,
    RQCode,
    payInfo,
    setScanShow,
    giftCardNumber,
    memberInfo,
    paymethod,
    status,
    getMemberDetail,
  } = props;
  console.log('propsprops', props)
  const history = useHistory();


  const cartStore = useSelector((state: any) => state.cart, shallowEqual);
  const [payType, setPayType] = useState("");
  const [tipsVisible, setTipsVisible] = useState(false)
  const [urlCode, setUrlCode] = useState('')

  const filterKey = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  useKeyPress(filterKey, (event) => {
    scancode += event.key;
  });

  useEffect(() => {
    scancode = "";
  }, []);

  const getQRCode = (url: any) => {
    let opts = {
      type: "svg",
      width: '120',
      height: '120',
      colorDark: "#000000",
      colorLight: "#ffffff",
    };
    QRCode.toCanvas(
      document.getElementById("canvas"),
      url,
      opts,
      function (error: any) {
        if (error) console.error(error);
      }
    );
  };

  const switchChannel = (flag: any) => {
    if (flag === 'boxPay' && scancode.substring(0, 1) !== '2') {
      return 1
    }
    else if (flag === 'boxPay' && scancode.substring(0, 1) === '2') {
      return 2
    }
    else if (flag === 'aliPay') {
      return 2
    }
    else if (flag === 'weChatPay') {
      return 1
    }
  }

  const clearAllTimer = () => {
    clearKeyTime('SEARCH_ORDER')
    clearKeyTime('GET_PAY_CODE')
    clearKeyTime('LISTEN_SCAN_BOX_CODE')
    clearKeyTime('USER_PAY_CODE')
  }

  const makeHandler = async (flag: any, is_scan_box: any, source: any) => { // true为盒子收款
    console.log('payType === flag', payType === flag)
    if(payType === flag) return false;
    console.log(456)
    clearAllTimer();
    const orderType = formatUrl()?.type;
    const selfinfo = getStorageSync('self-info') || {}
    const distributeinfo = getStorageSync('distribute-info') || {}
    const params = type !== 'vipRecharge' ? { /* 商品下单make参数 */
      number: '',
      pay_method: 2,
      pay_channel: switchChannel(flag), // 1微信， 2支付宝
      pay_type: 2, // 线上支付
      gift_card_id: giftCardNumber,
      delivery_type: OrderTypeIndex[getStorageSync("orderType")],
      order_type: Ordertype[JSON.parse(localStorage.getItem('orderType')).data],
      mobile: memberNumber,
      // 自提信息
      name: '',
      phone: selfinfo?.selfNumber || distributeinfo?.disNumber,
      pre_pick_up_at: selfinfo.selfTime,
      // 配送信息
      pre_delivery_at: distributeinfo.disTime,
      pre_make_at: OrderTypeIndex[getStorageSync("orderType")] === 1 ? selfinfo?.selfTime - 18000 : distributeinfo.disTime - 18000,
      remark: distributeinfo.disGoodsRemark,
      delivery_remark: distributeinfo.disRemark,
      contact_name: distributeinfo.disName,
      address: distributeinfo.disDetailAddress,
      location: 0,
      province_id: distributeinfo.disAddressInfo?.province_id,
      city_id: distributeinfo.disAddressInfo?.city_id,
      area_id: distributeinfo.disAddressInfo?.area_id,

      sku_items: cartStore.products.map((v) => {
        return {
          sku_id: v?.sku.sku_id,
          count: v?.count,
        };
      }),
    }
      : {
        user_id: memberInfo?.user_id || "0", /* 会员充值下单参数 */
        price: OrderAmount,
        user_member_card_id: memberInfo?.user_member_card_id,
        mobile: memberInfo?.mobile,
        pay_type: 2,
        pay_method: 2, // 电子支付
        pay_channel: switchChannel(flag),
        remark: '',
      }

    if (OrderTypeIndex[getStorageSync("orderType")] === 0) {
      delete params.pre_make_at
      delete params.pre_delivery_at
      delete params.pre_pick_up_at
    }

    if (source === 'detailhandler') {
      if (type !== "vipRecharge") {
        console.log('下单');
        // 走商品下单
        const payload = {
          action: status !== INIT ? 10 : 16,
          order_id: orderid
        }
        if (!is_scan_box) {
          /** 生成付款码 */
          // startInterval('GET_PAY_CODE', async () => {
            const params = {
              order_id: orderid,
              action: 1,
              params: flag !== 'boxPay' ? { scan: 2 } : { scan: 1, code: scancode },
              //scan:1 被扫 {scan:1, code: "xxx"}
              //scan:2 主扫
            }
            const res = await handleOrder(params)
            if (res?.result?.code_url || res?.result?.qr_code) { // 走用户主动扫付款码
              getQRCode(res?.result?.code_url || res?.result?.qr_code)
              setUrlCode(res?.result?.code_url || res?.result?.qr_code)
              // 查询订单状态
              console.log('here');

              setTimeout(async () => {
                await getPayResult('pro', payload, () => {
                }).then((result) => {
                  if (!result) {
                    setTipsVisible(true)
                    setPayType('')
                  }
                  else {
                    history.push(
                      `/orderdetail?type=${getStorageSync("orderType")
                      }&id=${orderid}`
                    );
                  }
                })
              }, 2000);
            }
            else {
              clearAllTimer()
              setPayType("");
            }
          // }, 60000);
          return
        }
        else {
          // startInterval('USER_PAY_CODE', async () => {
            const params = {
              order_id: orderid,
              action: 1,
              params: flag !== 'boxPay' ? { scan: 2 } : { scan: 1, code: scancode },
              //scan:1 被扫 {scan:1, code: "xxx"}
              //scan:2 主扫
            }
            const res = await handleOrder(params)

            if (res) {
              // 查询订单状态
              setTimeout(async () => {
                const result = await getPayResult('pro', payload, () => {
                  setScanShow(false)
                })
              }, 2000);
            }

          // }, 60000);
        }
      }
      else { // 走会员下单
        const payload = {
          action: status !== INIT ? 10 : 16,
          order_id: orderid
        }
        if (!is_scan_box) {
          /** 生成付款码 */
          // startInterval('GET_PAY_CODE', async () => {
            const params = {
              order_id: orderid,
              action: 1,
              params: flag !== 'boxPay' ? { scan: 2 } : { scan: 1, code: scancode },
              //scan:1 被扫 {scan:1, code: "xxx"}
              //scan:2 主扫

            }

            const res = await HandlerVipBalance(params)
            console.log('resres----', res)

            if (res?.result?.code_url || res?.result?.qr_code) { // 走用户主动扫付款码
              getQRCode(res?.result?.code_url || res?.result?.qr_code)
              setUrlCode(res?.result?.code_url || res?.result?.qr_code)
              setTimeout(async () => {
                await getPayResult('vip', payload, () => {
                  setScanShow(false)
                }).then((result) => {
                  if (!result) {
                    setTipsVisible(true)
                    setPayType('')
                  }
                  else {
                    history.push(
                      `/orderdetail?type=${getStorageSync("orderType")
                      }&id=${orderid}`
                    );
                  }
                })
              }, 2000);
            }
            else {
              clearAllTimer()
              setPayType("");
            }
          // }, 60000);
          return
        }
        else {

          startInterval('USER_PAY_CODE', async () => {

            const params = {
              order_id: orderid,
              action: 1,
              params: flag !== 'boxPay' ? { scan: 2 } : { scan: 1, code: scancode },
              //scan:1 被扫 {scan:1, code: "xxx"}
              //scan:2 主扫

            }

            const res = await HandlerVipBalance(params)
            console.log('resres----', res)

            if (res) {
              // 查询订单状态
              setTimeout(async () => {
                const result = await getPayResult('vip', payload, () => {
                  setScanShow(false)
                })
              }, 2000);
            }

          }, 60000);

        }
      }
    }
    else if (source === 'make') {
      if (type !== "vipRecharge") {
        // 走商品下单
        makeOrder(params).then(async (res) => {
          var ress = res;
          if (res) {
            setStorageSync('ORDER_ID', res.order_id)
            const payload = {
              action: 10,
              order_id: res.order_id
            }
            if (!is_scan_box) {
              /** 生成付款码 */
              // startInterval('GET_PAY_CODE', async () => {
                const params = {
                  order_id: ress.order_id,
                  action: 1,
                  params: flag !== 'boxPay' ? { scan: 2 } : { scan: 1, code: scancode },
                  //scan:1 被扫 {scan:1, code: "xxx"}
                  //scan:2 主扫
                }
                const res = await handleOrder(params)
                if (res?.result?.code_url || res?.result?.qr_code) { // 走用户主动扫付款码
                  getQRCode(res?.result?.code_url || res?.result?.qr_code)
                  setUrlCode(res?.result?.code_url || res?.result?.qr_code)
                  // 查询订单状态
                  console.log('here');

                  setTimeout(async () => {
                    await getPayResult('pro', payload, () => {
                    }).then((result) => {
                      if (!result) {
                        setTipsVisible(true)
                        setPayType('')
                      }
                      else {
                        history.push(
                          `/orderdetail?type=${getStorageSync("orderType")
                          }&id=${result?.result?.order_id}`
                        );
                      }
                    })
                  }, 2000);
                }
                else {
                  clearAllTimer()
                  setPayType("");
                }
              // }, 60000);
              return
            }
            else {
              startInterval('USER_PAY_CODE', async () => {
                const params = {
                  order_id: ress.order_id,
                  action: 1,
                  params: flag !== 'boxPay' ? { scan: 2 } : { scan: 1, code: scancode },
                  //scan:1 被扫 {scan:1, code: "xxx"}
                  //scan:2 主扫
                }
                const res = await handleOrder(params)

                if (res) {
                  // 查询订单状态
                  setTimeout(async () => {
                    const result = await getPayResult('pro', payload, () => {
                      setScanShow(false)
                    })
                  }, 2000);
                }

              }, 60000);
            }
          }
        })
      }
      else { // 走会员下单
        rechargeVipBalance(params).then(async (res) => {
          var ress = res
          if (res) {
            const payload = {
              action: 10,
              order_id: res.order_id
            }
            if (!is_scan_box) {
              /** 生成付款码 */
              // startInterval('GET_PAY_CODE', async () => {
                const params = {
                  order_id: ress.order_id,
                  action: 1,
                  params: flag !== 'boxPay' ? { scan: 2 } : { scan: 1, code: scancode },
                  //scan:1 被扫 {scan:1, code: "xxx"}
                  //scan:2 主扫

                }

                const res = await HandlerVipBalance(params)
                console.log('resres----', res)

                if (res?.result?.code_url || res?.result?.qr_code) { // 走用户主动扫付款码
                  getQRCode(res?.result?.code_url || res?.result?.qr_code)
                  setUrlCode(res?.result?.code_url || res?.result?.qr_code)
                  setTimeout(async () => {
                    await getPayResult('vip', payload, () => {
                      setScanShow(false)
                    }).then((result) => {
                      if (!result) {
                        setTipsVisible(true)
                        setPayType('')
                      }
                      else {
                        getMemberDetail();
                        // history.push(
                        //   `/orderdetail?type=${getStorageSync("orderType")
                        //   }&id=${result?.result?.order_id}`
                        // );
                      }
                    })
                  }, 2000);
                }
                else {
                  clearAllTimer()
                  setPayType("");
                }
              // }, 60000);
              return
            }

            else {

              startInterval('USER_PAY_CODE', async () => {

                const params = {
                  order_id: ress.order_id,
                  action: 1,
                  params: flag !== 'boxPay' ? { scan: 2 } : { scan: 1, code: scancode },
                  //scan:1 被扫 {scan:1, code: "xxx"}
                  //scan:2 主扫

                }

                const res = await HandlerVipBalance(params)
                console.log('resres----', res)

                if (res) {
                  // 查询订单状态
                  setTimeout(async () => {
                    const result = await getPayResult('vip', payload, () => {
                      setScanShow(false)
                    })
                  }, 2000);
                }

              }, 60000);

            }

          }
        });
      }
    }
  };

  return (
    <div
      className="ScanningPay"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="fs orderAmount">
        支付金额：
        <span>¥{payInfo?.order_amount || Number(OrderAmount).toFixed(2)}</span>
      </div>
      {orderid !== '' && <div className="payType flex">
        {
          paymethod === PayChannel.ALIPAY &&
          <div style={{ marginRight: '5rem' }}>
            <div
              className={`fc aliPay ${payType === "aliPay" ? "current" : ""}`}
              onClick={() => {
                  // clearAllTimer()
                  setTipsVisible(false);
                  setPayType("aliPay");
                  makeHandler("aliPay", false, 'detailhandler');
              }}
            >
              <div className={` ${payType === "aliPay" ? "mb-2" : ""}`}>支付宝收款码</div>
              {payType === "aliPay" && (
                <canvas
                  id="canvas"
                  style={{ width: "9rem", height: "9rem" }}
                ></canvas>
              )}
            </div>
          </div>
        }
        {
          paymethod === PayChannel.WECHAT &&
          <div style={{ marginRight: '5rem' }}>
            <div
              style={{ backgroundColor: payType === "weChatPay" ? '#439057' : '' }}
              className={`fc weChatPay ${payType === "weChatPay" ? "current" : ""
                }`}
              onClick={() => {
                  // clearAllTimer()
                  setTipsVisible(false)
                  setPayType("weChatPay");
                  makeHandler("weChatPay", false, 'detailhandler');
              }}
            >
              <div className={`${payType === "weChatPay" ? "mb-2" : ""}`}>微信收款码</div>
              {payType === "weChatPay" && (
                <canvas
                  id="canvas"
                  style={{ width: "9rem", height: "9rem" }}
                ></canvas>
              )}
            </div>
          </div>
        }
        <div>
          <div
            className={`boxPay fc ${payType === "boxPay" ? "current" : ""}`}
            onClick={() => {
              // clearAllTimer()
              setTipsVisible(false)
              setPayType("boxPay");
              scancode = "";
              /** 监听扫码 */
              startInterval('LISTEN_SCAN_BOX_CODE', () => {
                if (scancode.length == 18 && !isNaN(Number(scancode))) {
                  clearAllTimer()
                  makeHandler('boxPay', true, 'detailhandler')
                }
              }, 1000);

              if (scancode.length !== 18 || isNaN(Number(scancode))) {
                setTimeout(() => {
                  clearAllTimer()
                  setPayType('')
                  setTipsVisible(true)
                }, 60000);
              }

            }}>

            <div>
              盒子收款
            </div>
            <div />
          </div>
        </div>
      </div>}
      {orderid === '' && <div className="payType fb">
        <div>
          <div
            className={`fc aliPay ${payType === "aliPay" ? "current" : ""}`}
            onClick={() => {
              // if (urlCode !== '') return false;
              // clearAllTimer()
              setTipsVisible(false)
              setPayType("aliPay");
              makeHandler("aliPay", false, 'make', "weChatPay");
            }}
          >
            <div className={` ${payType === "aliPay" ? "mb-2" : ""}`}>支付宝收款码</div>
            {payType == "aliPay" && (
              <canvas
                id="canvas"
                style={{ width: "9rem", height: "9rem" }}
              ></canvas>
            )}
          </div>
        </div>
        <div onClick={(e) => {
          e.stopPropagation()
        }}>
          <div
            style={{ backgroundColor: payType === "weChatPay" ? '#439057' : '' }}
            className={`fc weChatPay ${payType === "weChatPay" ? "current" : ""
              }`}
            onClick={() => {
              // if (urlCode !== '') return false;
              // clearAllTimer()
              setTipsVisible(false)
              setPayType("weChatPay");
              makeHandler("weChatPay", false, 'make', "aliPay");
            }}
          >
            <div className={`${payType === "weChatPay" ? "mb-2" : ""}`}>微信收款码</div>
            {payType == "weChatPay" && (
              <canvas
                id="canvas"
                style={{ width: "9rem", height: "9rem" }}
              ></canvas>
            )}
          </div>
        </div>
        <div>
          <div
            className={`boxPay fc ${payType === "boxPay" ? "current" : ""}`}
            onClick={() => {
              clearAllTimer()
              setTipsVisible(false)
              setPayType("boxPay");
              scancode = "";
              /** 监听扫码 */
              startInterval('LISTEN_SCAN_BOX_CODE', () => {
                if (scancode.length == 18 && !isNaN(Number(scancode))) {
                  clearAllTimer()
                  makeHandler('boxPay', true, 'make')
                }
              }, 1000);

              if (scancode.length !== 18 || isNaN(Number(scancode))) {
                setTimeout(() => {
                  clearAllTimer()
                  setPayType('')
                  setTipsVisible(true)
                }, 60000);
              }

            }}>

            <div>
              盒子收款
            </div>
            <div />
          </div>
        </div>
      </div>}
      {tipsVisible && <div className='tips fc'>
        <div className='icon iconfont iconshouyintai-weitianxie'></div>
        支付超时，请重新选择支付方式
      </div>}
    </div>
  );
};

export default ScanningPayDialog;