import { useState, Fragment, memo } from "react";
import { Modal } from "@douyinfe/semi-ui";
import "./pop.scss";

interface PopUpProps {
  width?: string;
  show: boolean;
  children?: any;
  open?: any;
  title?: string;
  onOk?: any;
  onClose?: any;
  footer?: any;
  okText?: any;
  centered?: boolean;
  setShow(type: boolean): any;
}

const PopUp = memo((props: PopUpProps) => {
  const {
    width,
    centered,
    show,
    setShow,
    children,
    open,
    title,
    onOk,
    onClose,
    footer,
    okText,
  } = props;

  const [showToggle, setShowToggle] = useState(false);
  const [keyBoard, setkeyBoard] = useState(false);

  const toggle = (bool: boolean) => {
    // debugger;
    setShow ? setShow(bool) : setShowToggle(bool);
  };

  const onKeyClick = (key) => {
    if (["close", "ok"].indexOf(key) > -1) {
      setkeyBoard(!keyBoard);
      return;
    }
    // do something...
  };
  const foot = footer ? { footer } : {};
  return (
    <Fragment>
      <div className="open" onClick={() => toggle(true)}>
        {open}
      </div>
      <Modal
        centered={centered ?? true}
        okText={okText}
        visible={show || showToggle}
        title={title}
        {...foot}
        width={width || "max-content"}
        maskClosable={false}
        onOk={() => {
          onOk && onOk();
        }}
        onCancel={() => {
          onClose && onClose();
          toggle(false);
        }}
      >
        <div className="popup-box">
          <div>{children}</div>
          {/* <div onClick={() => setkeyBoard(true)}>虚拟键盘</div> */}
          {/* {footer
                        && <div>
                            {footer(toggle)}
                        </div>
                    } */}
        </div>
      </Modal>

      {/* <KeyboardPicker visible={keyBoard} type='price' onKeyClick={onKeyClick} /> */}
    </Fragment>
  );
});

export default PopUp;
