import React, { FC, useState, useContext, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Pull } from "zarm";
import PullBox from "../../../global-component/pullBox/pull";

import "./index.scss";

interface itemValue {
  key: string;
  value: string | number;
  show?: boolean;
}
interface objValue {
  title: string;
  icon: string;
  properties: itemValue[];
}

interface infoValue {
  obj: objValue[];
}

const DetailCard: FC<infoValue> = (props: infoValue) => {

  const style = { overflowY: "auto", maxHeight: 'calc(100vh - 23rem)' };
  const globalStore = useSelector((state: any) => state.global, shallowEqual);
  const [objarray, setObjarray] = useState<objValue[]>([]);

  useEffect(() => {
    const { obj } = props;
    setObjarray(obj);
  }, [props]);

  const switchColor = (type: any) => {
    switch (type) {
      case 2:
        return "warn-msg";
      case 5:
        return "red-msg";
      case 3:
        return "pay-msg";
      default:
        break;
    }
  };

  return (
    <PullBox
      maxHeight={style.maxHeight}
      noRequest={true}
    >

      <div className="detailCard fb" style={{ paddingBottom: '10rem' }}>
        {objarray.map((item, index) => {
          return (
            <div key={index} className="item">
              <div className="title-row fb">
                <div className="flex">
                  <div className={`icon ${item.icon}`}></div>
                  <div>{item.title}</div>
                </div>
                {item.title === "订单详情" && (
                  <div className={switchColor(globalStore.payObj.pay_status)}>
                    {globalStore.payObj.pay_status_msg}
                  </div>
                )}
              </div>
              <div>
                {item.properties.map((etem, index) => {
                  return (
                    <div
                      style={{ display: `${etem.show ? "flex" : "none"}` }}
                      key={index}
                      className="item-row fb text-3"
                    >
                      <div>{etem.key}</div>
                      <div
                        className={`${(etem.key === "到账金额" ||
                          etem.key === "会员充值后余额") &&
                          (globalStore.payObj.pay_status === 5 ||
                            globalStore.payObj.pay_status === 2)
                          ? "text-line"
                          : ""
                          }`}
                      >
                        {etem.value}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </PullBox>
  );
};

export default DetailCard;
