import { FC, Fragment, useEffect, useState } from "react";
import { Input, Modal } from "@douyinfe/semi-ui";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import { clearKeyTime, getNumber } from "../../../utils/interval";
import { makeOrder, previewOrder } from "../../../services/payment";
import {
  getVipCardDetail,
  rechargeVipBalance,
} from "../../../services/vipcard";
import {
  Ordertype,
  OrderTypeIndex,
} from "../../../constants/order";
import { getStorageSync } from "../../../utils/uitls";

import PopUp from "../../../global-component/pop-up/PopUp";
import OrderInfo from "./orderInfo";
import MemberInfo from "./memberInfo";
import ScanningPayDialog from "../scaningPayDialog";
import PayMethodDialog from "../payMethodDialog";

import "./index.scss";
import CashPayDialog from "../cashPayDialog";

interface MemberPayDialogProps {
  giftAmount?: string; // 礼品卡金额
  giftCardNumber?: string; //礼品卡卡号
  setMemberPayShow?(handle: boolean): void;
  setGiftPayShow?(handle: boolean): void;
  setLoadingShow?(handle: boolean): void;
}

let money = {};
// 会员支付弹窗
const MemberPayDialog: FC<MemberPayDialogProps> = (
  props: MemberPayDialogProps
) => {
  const {
    giftCardNumber,
    giftAmount,
    setMemberPayShow,
    setGiftPayShow,
    setLoadingShow,
  } = props;

  const cartStore = useSelector((state) => state.cart, shallowEqual);
  const [showMemberInfo, setShowMemberInfo] = useState(false);
  const [memberNumber, setMemberNumber] = useState<string>("");
  const [memberInfo, setMemberInfo] = useState<any>(null);
  const [remark, setRemark] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [vipOrderInfo, setVipOrderInfo] = useState({});
  const [disCountArray, setDisCountArray] = useState([]);
  const [scanShow, setScanShow] = useState(false);
  const [scanRechargeShow, setScanRechargeShow] = useState(false);
  const [placeholder, setPlaceholder] = useState("请输入");
  const history = useHistory();

  const selfinfo =
    getStorageSync("self-info") !== "" ? getStorageSync("self-info") : {};

  useEffect(() => {
    memberInfo && init();
  }, [props, memberInfo]);

  const init = () => {
    const params = {
      pay_method: 1,
      pay_channel: 0,
      pay_type: 2,
      gift_card_id: giftCardNumber,
      mobile: memberNumber,
      delivery_type: OrderTypeIndex[getStorageSync("orderType")],
      order_type: Ordertype[getStorageSync("orderType")],
      date: selfinfo?.selfTime,
      sku_items: cartStore.products.map((v) => {
        return {
          sku_id: v?.sku.sku_id,
          count: v?.count,
        };
      }),
    };
    previewOrder(params).then((res) => {
      if (res) {
        setVipOrderInfo(res);
        setDisCountArray(res.order_discount);
      }
    });
  };

  const getRechargeMoney = (value: any) => {
    setInputValue(value);
  };

  const balanceNotEnough =
    memberInfo?.balance -
    Number(
      vipOrderInfo?.order_discount?.filter((item) => {
        return item.type === 7;
      })[0].amount
    ) <
    0;

  const add = (params: any) => {
    setMemberInfo({ ...memberInfo, price: params });
  };

  const getVipDetail = () => {
    const params = {
      mobile: memberNumber,
    };
    getVipCardDetail(params).then((res) => {
      if (res) {
        setMemberInfo(res);
        setShowMemberInfo(true);
      } else {
        setShowMemberInfo(false);
      }
    });
  };

  const cashHandler = () => {
    if (money?.remain < 0 || money.actualPay === "") {
      message.error("请输入大于应收的金额");
      return;
    } else if (!getStorageSync("OFFLINE_PAY")) {
      message.error("请选择收款方式");
      return;
    }
    const params = {
      user_id: memberInfo?.user_id || "0",
      price: memberInfo.price,
      user_member_card_id: memberInfo.user_member_card_id,
      mobile: memberInfo.mobile,
      pay_method: getStorageSync("OFFLINE_PAY_METHOD").pay_method,
      pay_channel: getStorageSync("OFFLINE_PAY_METHOD").pay_channel,
      pay_type: getStorageSync("OFFLINE_PAY_METHOD").pay_type,
      custom_item_id: getStorageSync("OFFLINE_PAY_METHOD")?.custom_item_id,
      remark: remark.toString(),
    };
    rechargeVipBalance(params).then((res) => {
      if (res) {
        message.success(res.pay_status_msg);
        setMemberPayShow(true);
        getVipDetail();
        setInputValue("");
      } else {
        message.error("充值失败");
        setMemberPayShow(true);
        getVipDetail();
      }
    });
  };

  const handleMake = () => {
    const selfinfo =
      getStorageSync("self-info") !== "" ? getStorageSync("self-info") : {};
    const distributeinfo =
      getStorageSync("distribute-info") !== ""
        ? getStorageSync("distribute-info")
        : {};

    const params = {
      number: "",
      pay_method: 1,
      pay_channel: 0,
      pay_type: 2,
      gift_card_id: giftCardNumber,
      delivery_type: OrderTypeIndex[getStorageSync("orderType")],
      order_type: Ordertype[getStorageSync("orderType")],
      // 自提信息
      name: "",
      mobile: memberNumber,
      phone: selfinfo?.selfNumber || distributeinfo?.disNumber,
      // 配送信息
      pre_delivery_at: distributeinfo.disTime,
      pre_make_at:
        OrderTypeIndex[getStorageSync("orderType")] === 0
          ? 0
          : OrderTypeIndex[getStorageSync("orderType")] === 1
            ? selfinfo.selfTime - 1800
            : distributeinfo.disTime - 1800,
      pre_pick_up_at: selfinfo?.selfTime || "",
      remark: distributeinfo.disGoodsRemark || "",
      delivery_remark: distributeinfo.disRemark || "",
      contact_name: distributeinfo.disName || "",
      address: distributeinfo.disDetailAddress || "",
      location: 0,
      province_id: distributeinfo.disAddressInfo?.province_id,
      city_id: distributeinfo.disAddressInfo?.city_id,
      area_id: distributeinfo.disAddressInfo?.area_id,

      sku_items: cartStore.products.map((v: any) => {
        return {
          sku_id: v?.sku.sku_id,
          count: v?.count,
        };
      }),
    };
    makeOrder(params).then((res) => {
      if (res) {
        message.success("会员收款成功");
        history.push(
          `/orderdetail?type=${getStorageSync("orderType")}&id=${res.order_id}`
        );
      } else if (Number(res.code) !== 0) {
        message.error(res.msg);
      }
    });
  };

  return (
    <div className="memberPay">
      <div className="content">
        <div className="memberPhone fb">
          <div className="inputPhone">会员联系方式</div>
          <Input
            showClear
            value={memberNumber}
            maxLength={11}
            placeholder={placeholder}
            prefix={<span className="iconfont icona-shouyintaisousuo" />}
            onFocus={() => {
              setPlaceholder("");
            }}
            onBlur={() => {
              setPlaceholder("请输入");
            }}
            onChange={(value: any) => {
              setMemberNumber(getNumber(value));
            }}
            onEnterPress={(e) => {
              getVipDetail();
            }}
          />
          <div className="search" onClick={getVipDetail}>
            查找
          </div>
        </div>
        {memberInfo && showMemberInfo && (
          <Fragment>
            <div
              style={{ maxHeight: "calc(100vh - 38rem)", overflowY: "auto" }}
            >
              <MemberInfo
                memberInfo={memberInfo}
                balanceNotEnough={balanceNotEnough}
                setMember={add}
                inputValue={inputValue}
                getRechargeMoney={getRechargeMoney}
              />
              {giftAmount ? (
                <OrderInfo
                  vipOrderInfo={vipOrderInfo}
                  giftAmount={Number(giftAmount)}
                  disCountArray={disCountArray}
                  giftCardNumber={giftCardNumber}
                />
              ) : (
                <OrderInfo
                  vipOrderInfo={vipOrderInfo}
                  disCountArray={disCountArray}
                />
              )}
            </div>

            {!giftAmount && (
              <div className="actualAmount">
                <span>
                  实收金额：
                  <span>
                    ￥
                    <span>{Number(vipOrderInfo?.order_amount).toFixed(2)}</span>
                  </span>
                </span>
              </div>
            )}
            <div className="payMethod flex">
              <>
                <div className="balancePay">
                  <span className="icon iconfont iconshouyintai-saomachongzhi" />
                  <span
                    className="type"
                    onClick={() => {
                      if (!Boolean(inputValue)) {
                        message.error("请输入充值金额");
                      } else {
                        setScanRechargeShow(true);
                      }
                    }}
                  >
                    扫码充值
                  </span>
                </div>
                <Modal
                  style={{
                    width: "70rem",
                  }}
                  onCancel={() => {
                    setScanShow(false);
                    clearKeyTime("SEARCH_ORDER");
                    clearKeyTime("GET_PAY_CODE");
                    clearKeyTime("LISTEN_SCAN_BOX_CODE");
                    clearKeyTime("USER_PAY_CODE");
                  }}
                  centered
                  title="请选择支付方式"
                  visible={scanRechargeShow}
                  footer={<div style={{ height: "2rem" }}></div>}
                >
                  <ScanningPayDialog
                    OrderAmount={inputValue}
                    setMemberPayShow={setMemberPayShow}
                    setScanShow={setScanRechargeShow}
                    type="vipRecharge"
                    orderid=''
                  />
                </Modal>
              </>

              {
                // balanceNotEnough &&
                !Boolean(Number(inputValue)) ? (
                  <div className="balancePay">
                    <span className="icon iconfont iconshouyintai-xianjinchongzhi"></span>
                    <span
                      className="type"
                      onClick={() => message.error("请输入不为0的充值金额")}
                    >
                      线下充值
                    </span>
                  </div>
                ) : (
                  <PopUp
                    title="线下充值"
                    okText="收款"
                    onOk={() => {
                      cashHandler();
                    }}
                    open={
                      <div className="balancePay">
                        <span className="icon iconfont iconshouyintai-xianjinchongzhi"></span>
                        <span className="type">线下充值</span>
                      </div>
                    }
                    onClose={() => { }}
                  >
                    <div style={{ width: "60rem" }}>
                      <PayMethodDialog
                        getInputValue={(value: any) => {
                          setRemark(Number(value).toFixed(2));
                        }}
                        inputValue={Number(inputValue)}
                        onOutoutPrice={(price: any) => {
                          money = price;
                        }}
                        memberInfo={memberInfo}
                      />
                    </div>
                  </PopUp>
                )
              }
              {balanceNotEnough ? (
                <div
                  className="balancePay balanceNotEnough"
                  onClick={() => {
                    message.error("请充值！");
                  }}
                >
                  <div className="scanningPay fc   ">
                    <span className="icon iconfont iconshouyintai-yuekoukuan"></span>
                    <span className="type">余额扣款</span>
                  </div>
                </div>
              ) : (
                <div
                  className="balancePay"
                  onClick={() => {
                    handleMake();
                  }}
                >
                  <div className="scanningPay fc ">
                    <span className="icon iconfont iconshouyintai-yuekoukuan"></span>
                    <span className="type">余额扣款</span>
                  </div>
                </div>
              )}
              {balanceNotEnough && (
                <>
                  <div className="balancePay">
                    <span className="icon iconfont iconshouyintai-saomachongzhi" />
                    <span
                      className="type"
                      onClick={() => {
                        // if (!Boolean(inputValue)) {
                        //   message.error("请输入充值金额");
                        // } else {
                          setScanRechargeShow(true);
                        // }
                      }}
                    >
                      扫码支付
                    </span>
                  </div>
                  <Modal
                    style={{
                      width: "70rem",
                    }}
                    onCancel={() => {
                      setScanShow(false);
                      clearKeyTime("SEARCH_ORDER");
                      clearKeyTime("GET_PAY_CODE");
                      clearKeyTime("LISTEN_SCAN_BOX_CODE");
                      clearKeyTime("USER_PAY_CODE");
                    }}
                    centered
                    title="请选择支付方式"
                    visible={scanRechargeShow}
                    footer={<div style={{ height: "2rem" }}></div>}
                  >
                    <ScanningPayDialog
                      OrderAmount={inputValue}
                      setMemberPayShow={setMemberPayShow}
                      setScanShow={setScanRechargeShow}
                      type="vipRecharge"
                      orderid=''
                    />
                  </Modal>
                </>
              )}
              {balanceNotEnough && (
                <div
                  className="balancePay balanceNotEnough"
                  onClick={() => {
                    message.error("请充值！");
                  }}
                >
                  <PopUp
                    title="线下支付"
                    okText="收款"
                    onOk={() => {
                      cashHandler();
                    }}
                    open={
                      <div className="balancePay">
                        <span className="icon iconfont iconshouyintai-xianjinchongzhi"></span>
                        <span className="type">线下支付</span>
                      </div>
                    }
                    onClose={() => { }}
                  >
                    <div style={{ width: "45rem" }}>
                      <CashPayDialog
                        getInputValue={(value: any) => {
                          setRemark(Number(value).toFixed(2));
                        }}
                        inputValue={Number(inputValue)}
                        onOutoutPrice={(price: any) => {
                          money = price;
                        }}
                        memberInfo={memberInfo}
                      />
                    </div>
                  </PopUp>
                </div>
              )}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default MemberPayDialog;
