/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
// import * as actionType from './contants'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { OrderType, OrderTypeIndex } from '../constants/order'
import { previewOrder } from '../services/payment'
import { formatUrl } from '../utils/format'
import { getStorageSync } from '../utils/uitls'
import { actions as cartActions } from './cart'
/**
 * 初始化数据
 */
const initialState = {
    preview: {},
}
/**
 * reducers
 */

const reducers = {

}


/**
 * @param {*} builder 
 * 监听异步完成处理state
 */
const extraReducers = {  // 两种写法
    // [cartActions.add](state, action) {
    // }
}


const cartSlice = createSlice({
    name: 'order',
    initialState,
    reducers,
    extraReducers,
})


export const actions = {
    ...cartSlice.actions,
    // cartAsync
};
export default cartSlice.reducer;