import {
  DISTRUIBUTING_STATUS,
  FINISHED_STATUS,
  NOTPRODUCED_STATUS,
  PRODUCED_STATUS,
  WILLDELIVER_STATUS,
  WILLPICK_STATUS,
} from "./imagePath";
/* 收银台 */
export const SELF = "自提单";
export const DISTRIBUTION = "配送单";
export const SHOP = "门店打包单";
export const ALL = "全部";
/* 小程序 */
export const APPLETSELF = "小程序自提单";
export const APPLETDISTRIBUTION = "小程序配送单";
/* app端 */
export const APPSELF = "APP自提单";
export const APPDISTRIBUTION = "APP配送单";

export enum OrderType {
  Self = "self",
  Distribute = "distribute",
  Shop = "shop",
  All = "all",
  APPLETSELF='appleSelf',
  APPLETDISTRIBUTION='appleDistribute',
  APPSELF='appSelf',
  APPDISTRIBUTION='appDistribute'
}

export const OrderTypeNumber: any = {
  [1]: "自提单",
  [2]: "配送单",
  [0]: "打包单",
};

export const OrderTypeString: any = {
  all: "全部订单",
  self: "自提单",
  distribute: "配送单",
  shop: "门店打包单",
  appleSelf: '小程序自提单',
  appleDistribute: '小程序配送单',
  appSelf: 'APP自提单',
  appDistribute: 'APP配送单',
};

export const OrderSource: any = {
  self: 3,
  distribute: 3,
  shop: 3,
};

export const OrderTypeIndex: any = {
  self: 1,
  distribute: 2,
  shop: 0,
  all: "",
};

export const ORDER_TYPE: any = {
  [SELF]: {
    number:2,
    value:OrderType.Self
  },
  [DISTRIBUTION]:{
    number:2,
    value:OrderType.Distribute
  },
  [SHOP]: {
    number:1,
    value:OrderType.Shop
  },
  [ALL]: {
    number:'',
    value:OrderType.All
  },
  [APPLETSELF]: {
    number:'',
    value:OrderType.APPLETSELF
  },
  [APPLETDISTRIBUTION]: {
    number:'',
    value:OrderType.APPLETDISTRIBUTION
  },
  [APPSELF]: {
    number:'',
    value:OrderType.APPSELF
  },
  [APPDISTRIBUTION]: {
    number:'',
    value:OrderType.APPDISTRIBUTION
  },
};

export const DeliveryType: any = {
  [OrderType.Self]: {
    number: 1,
    orderType: 2,
  },
  [OrderType.Distribute]: {
    number: 2,
    orderType: 2,
  },
  [OrderType.Shop]: {
    number: 0,
    orderType: 1,
  },
  [OrderType.APPLETSELF]: {
    number: 1,
    orderType: 2,
  },
  [OrderType.APPLETDISTRIBUTION]: {
    number: 2,
    orderType: 2,
  },
  [OrderType.APPSELF]: {
    number: 1,
    orderType: 2,
  },
  [OrderType.APPDISTRIBUTION]: {
    number: 2,
    orderType: 2,
  },
  [OrderType.All]: {
    number: "",
    orderType: "",
  },
};

export const Ordertype: any = {
  shop: 1, // 打包
  distribute: 2,
  self: 2, // 外卖
  EAT_IN: 3, //堂食
};

export const OrderDeliveryType: any = {
  [1]: {
    name: "自提单",
  },
  [2]: {
    name: "配送单",
  },
  [0]: {
    name: "门店打包单",
  },
};


export const INIT = 100; //待支付
export const PAY_SUCCESS = 200; // 待接单
export const WAIT = 300; // 待完善
export const NotProduced = 310; // "未出品";
export const Produced = 401; // "已出品";
export const WillPick = 401; // "待取货";
export const WillDeliver = 401; // "待配送";
export const Distributing = 411; // "配送中";
export const WAITING_SIGN = 412; // 待签收
export const Finished = 500; //"已完成";
export const CANCEL = 600; // "订单关闭"; // 订单关闭

export enum EOrderHandle {
  PAY = 1, // 支付订单
  CANCEL = 2, // 取消订单
  WIPE_OUT = 3, // 核销订单
  READY = 4, // 订单出货
  SIGN = 5, // 用户签收 完成
  APPLY_REFUND = 6, // 申请退款
  ACCEPT_REFUND = 7, //同意退款
  REFUSE_REFUND = 8, // 拒绝退款
  PAY_NOTIFY = 9, //支付通知
  PAY_QUERY = 10, //支付查询
  PAY_CLOSE = 11, //支付关闭
  SHOP_TAKE = 12, //商家接单
  CHANGE_PRICE = 13, //改价
  MAKE = 14, //生产商品
  DISTRIBUTION = 15, //立即配送
}

export const OrderStatusColor: any = {
  ["未出品"]: {
    color: "#FD7E24",
  },
  ["已出品"]: {
    color: "#46CB81",
  },
  ["待取货"]: {
    color: "#B365CB",
  },
  ["待配送"]: {
    color: "#037AEE",
  },
  ["配送中"]: {
    color: "#00BDD8",
  },
  ["已完成"]: {
    color: "#999999",
  },
  ["待支付"]: {
    color: "#FD7E24",
  },
  ["待接单"]: {
    color: "#FD7E24",
  },
  ["待完善"]: {
    color: "#FD7E24",
  },
  ["待签收"]: {
    color: "#FD7E24",
  },
  ["订单关闭"]: {
    color: "#999999",
  },
};

export const OrderStatus: any = {
  [1]: {
    [NotProduced]: {
      color: "#FD7E24",
      bg: "#46CB81",
      handle: "出品",
      action: EOrderHandle.READY,
      image: NOTPRODUCED_STATUS,
    },

    [WillPick]: {
      color: "#B365CB",
      bg: "#00BDD8",
      handle: "取货",
      action: EOrderHandle.SIGN,
      image: WILLDELIVER_STATUS,
    },
    [Finished]: {
      color: "#333333",
      bg: "",
      handle: "",
      image: FINISHED_STATUS,
    },
  },
  [2]: {
    [NotProduced]: {
      color: "#FD7E24",
      bg: "#46CB81",
      handle: "出品",
      action: EOrderHandle.READY,
      image: NOTPRODUCED_STATUS,
    },
    [WillDeliver]: {
      color: "#46CB81",
      bg: "#00BDD8",
      handle: "配送",
      action: EOrderHandle.DISTRIBUTION,
      image: WILLDELIVER_STATUS,
    },
    [Distributing]: {
      color: "#00BDD8",
      bg: "#037AEE",
      handle: "完成",
      action: EOrderHandle.SIGN,
      image: DISTRUIBUTING_STATUS,
    },
    [Finished]: {
      color: "#333333",
      bg: "",
      handle: "",
      image: FINISHED_STATUS,
    },
  },
  [0]: {
    [INIT]: {
      color: "#FD7E24",
      handle: "出品",
      action: EOrderHandle.READY,
      image: NOTPRODUCED_STATUS,
    },
    [Produced]: {
      color: "#46CB81",
      handle: "取货",
      action: EOrderHandle.SIGN,
    },
    [WillPick]: {
      color: "#B365CB",
      handle: "取货",
      action: EOrderHandle.SIGN,
    },
    [WillDeliver]: {
      color: "#037AEE",
      handle: "配送",
      action: EOrderHandle.DISTRIBUTION,
    },
    [Distributing]: {
      color: "#00BDD8",
      handle: "完成",
      action: EOrderHandle.SIGN,
    },
    [Finished]: {
      color: "#333333",
      handle: "",
      image: FINISHED_STATUS,
    },
  },
};

export const PAY_METHOD: any = {
  ["微信"]: {
    color: "#BDFFBD",
  },
  ["支付宝"]: {
    color: "#B9F6FF",
  },
  ["现金"]: {
    color: "#CCE6FF",
  },
  ["会员余额"]: {
    color: "#FFE2AA",
  },
};
