import dayjs from "dayjs";

// 倒计时转化Fn 时间戳
function formatSeconds(value) {
    if (value === 0) return null;
    let theTime = parseInt(value); // 秒
    let theTime1 = 0; // 分
    let theTime2 = 0; // 小时
    let theTime3 = 0; // 天
    if (theTime > 60) {
        theTime1 = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (theTime1 > 60) {
            theTime2 = parseInt(theTime1 / 60);
            theTime1 = parseInt(theTime1 % 60);
        }
        if (theTime2 > 24) {
            theTime3 = parseInt(theTime2 / 24);
        }
    }

    let result = `${parseInt(theTime)}`;
    if (result < 10) {
        result = `0${result}`;
    }
    if (theTime1 > 0) {
        result = `${parseInt(theTime1)}:${result}`;

        if (theTime1 < 10) {
            result = `0${result}`;
        }
    } else {
        result = `${result}`;
    }
    if (theTime2 > 0) {
        // 暂时用不到
        if (theTime2 > 24) {
            result = `${theTime3}天${parseInt(theTime2 % 24)}:${result}`;
        } else {
            result = `${parseInt(theTime2)}:${result}`;
        }
    }

    if (value < 60) {
        // 60秒以内操作
        result = `00:${result}`;
    } else if (value === 60) {
        result = "01:00";
    }
    return result;
}

function formatUrl() {
    if (window.location.search) {
        let iterator = new URLSearchParams(window.location.search).entries();
        let _obj = {};
        function Next(params) {
            let a = params.next();
            if (a.value) {
                _obj[a.value[0]] = a.value[1];
                Next(iterator);
            }
        }
        Next(iterator);
        return { ..._obj, str: window.location.search };
    }
    return "no find search-url";
}
/**
 * @param {*} setTimer -设置页面定时器id （用于清除）
 * @param {*} value -目标时间戳
 * @param {*} setTime -设置当前倒计时state str
 */
function countdown(setTimer, value, setTime) {
    let timer = setInterval(() => {
        value -= 1;
        let today_unix = dayjs().unix(); // 当前时间
        let un = value - today_unix;
        if (un > 0) {
            setTime(formatSeconds(un));
        } else {
            setTime("");
            clearInterval(timer);
        }
    }, 999.8);
    setTimer(timer);
}

// 范围随机数
function randomFrom(lowerValue, upperValue) {
    return Math.floor(Math.random() * (upperValue - lowerValue + 1) + lowerValue);
}
/**
 * 手机号验证
 * @param tel
 */
function isPhoneNumber(tel) {
    var reg = /^0?1[3|4|5|6|7|8|9][0-9]\d{8}$/;
    if (reg.test(tel)) {
        return true;
    } else {
        return false;
    }
}
function weekdeta(value, i) {
    if (!value) return "暂无";
    var date1 = new Date();
    var date2 = new Date(value);
    // date2.setDate(date1.getDate() + aa);
    let month = "";
    let day = "";
    if (date2.getMonth() + 1 < 10) month = "0" + (date2.getMonth() + 1);
    else month = date2.getMonth() + 1;

    if (date2.getDate() < 10) day = "0" + date2.getDate();
    else day = date2.getDate();

    const week = `(${dayjs(time2).format("dddd").replace("星期", "周")})`;
    var time2 = date2.getFullYear() + "-" + month + "-" + day;

    let today =
        dayjs(time2).format("YY-MM-DD dddd") == dayjs(date1).format("YY-MM-DD dddd")
            ? `今日`
            : "";
    let tomorrow =
        dayjs(time2).format("YY-MM-DD dddd") ==
            dayjs(date1.valueOf() + 86400000).format("YY-MM-DD dddd")
            ? `明日`
            : "";
    let yesterday =
        dayjs(time2).format("YY-MM-DD dddd") ==
            dayjs(date1.valueOf() - 86400000).format("YY-MM-DD dddd")
            ? `昨日`
            : "";

    const time3 = dayjs(value).format("HH:mm");
    const str = `${today || tomorrow || yesterday || dayjs(time2).format("MM月DD号 ")
        }`;

    const day_7 = new Date(date1 + 604800000);
    let Show_7 = false;
    if (day_7.getMonth() == date2.getMonth()) {
        if (Math.abs(date2.getDate() - day_7.getDate()) <= 7) {
            Show_7 = true;
        }
    }
    const _today = `${today || tomorrow || yesterday}`;

    return {
        str,
        time: time2,
        // show: `${!Show_7 ? time2 : str} ${time3}`,
        show: `${!_today ? time2 : str} ${time3}`,
        today: _today,
        hours: dayjs(value).format("HH:mm"),
        twelve_hours: `${!_today ? time2 : str} ${dayjs(value).format("A hh:mm")}`,
    };
}

const getCurrentTime = (time, type) => {
    var date1 = new Date(new Date(new Date(time).toLocaleDateString()).getTime());
    var date2 = new Date(
        new Date(new Date(time).toLocaleDateString()).getTime() +
        24 * 60 * 60 * 1000 -
        1
    );
    var beginTime =
        date1.getFullYear() +
        "-" +
        (date1.getMonth() + 1 < 10
            ? "0" + (date1.getMonth() + 1)
            : date1.getMonth() + 1) +
        "-" +
        (date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate()) +
        " " +
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":" +
        (date1.getMinutes() < 10 ? "0" + date1.getMinutes() : date1.getMinutes());

    var endTime =
        date2.getFullYear() +
        "-" +
        (date2.getMonth() + 1 < 10
            ? "0" + (date2.getMonth() + 1)
            : date2.getMonth() + 1) +
        "-" +
        (date2.getDate() < 10 ? "0" + date2.getDate() : date2.getDate()) +
        " " +
        (date2.getHours() < 10 ? "0" + date2.getHours() : date2.getHours()) +
        ":" +
        (date2.getMinutes() < 10 ? "0" + date2.getMinutes() : date2.getMinutes());
    if (type === "begin") {
        return beginTime;
    } else if (type === "end") {
        return endTime;
    } else {
        return false;
    }
};

export {
    formatSeconds,
    formatUrl,
    countdown,
    randomFrom,
    isPhoneNumber,
    weekdeta,
    getCurrentTime,
};
