/* eslint-disable react-hooks/exhaustive-deps */
import paging, { initing } from './paging';
import { useState, useEffect, useRef } from 'react';
import { Pull, BackToTop, ConfigProvider, ActivityIndicator, Icon } from 'zarm';
import './index.scss'
import { LOAD_STATE, REFRESH_STATE } from '../../constants/comment';

/**
<PullBox
    isTopBtn
    isWindowBox={false}
    maxHeight={300}
    request={{
        params: {
            page: 1,
        },
        http: getTestList
    }}
    onScrollBottom={(_list) => {
        console.log(_list, 'list');
        // setTimeout(() => {
        if (_list.page > 1) setPageData([...pageData, ..._list.list])
        else setPageData(_list.list)
        // }, 200);


    }}
>
  
    {
        pageData?.map((e, i) => {
            return <Cell key={'list_pull_' + i}>{e} ---- {i}</Cell>
        })
    }
</PullBox>
 */


const PullBox = ({
    isWindowBox = false,
    isTopBtn = false,
    init = '',
    request = { params: Object.prototype, http: Function.prototype },
    onScrollBottom = Function.prototype, // 滚动到底部回调
    maxHeight,
    children,
    noRequest
}) => {


    const pullRef = useRef();
    const [bodyScroll, setBodyScroll] = useState(false);
    const [refreshing, setRefreshing] = useState(REFRESH_STATE.normal);
    const [loading, setLoading] = useState(LOAD_STATE.normal);
    const [page, setPage] = useState(1)

    useEffect(() => {
        setBodyScroll(isWindowBox);
    }, [])

    useEffect(() => {
        !noRequest && refreshData()
    }, [init])


    // 模拟请求数据
    const refreshData = async (params) => {
        setRefreshing(REFRESH_STATE.loading);
        initing(request, (newList) => {
            let refreshState = REFRESH_STATE.failure
            setPage(1)
            if (newList) {
                if (!newList.list[0]) {
                    setLoading(LOAD_STATE.complete);
                }
                onScrollBottom(newList)
                refreshState = REFRESH_STATE.normal
            }
            setRefreshing(refreshState);
        })
    };

    const mockRefresh = () => {
        setRefreshing(REFRESH_STATE.loading);
        setTimeout(() => {
            setRefreshing(REFRESH_STATE.success);
        }, 1000);
    }

    // 模拟加载更多数据
    const loadData = async () => {
        setLoading(LOAD_STATE.loading);
        paging(request, page, (newList) => {
            console.log('newListnewListnewList', newList)
            let loadingState = LOAD_STATE.complete
            if (newList) {
                if (newList.list[0]) {
                    onScrollBottom(newList);
                    setPage(page + 1)
                    loadingState = LOAD_STATE.success
                } else {

                    loadingState = LOAD_STATE.complete
                }
            } else {
                loadingState = LOAD_STATE.failure
            }
            setLoading(loadingState);
        })
    };

    const style = bodyScroll ? {} : { overflowY: 'auto', maxHeight, paddingBottom: '2rem' };

    const scrollContainer = () => {
        return bodyScroll ? window : pullRef.current && pullRef.current.scrollContainer;
    };

    return (
        <div style={{ position: 'relative' }}>
            <ConfigProvider primaryColor={'#037AEE'}>
                <Pull
                    ref={pullRef}
                    style={style}
                    refresh={{
                        state: refreshing,
                        handler: !noRequest ? refreshData : mockRefresh,
                        // render: (refreshState, percent) => { 
                        //     const cls = 'custom-control flex';
                        //     switch (refreshState) {
                        //         case REFRESH_STATE.pull:
                        //             return (
                        //                 <div className={cls}>
                        //                     <ActivityIndicator loading={false} percent={percent} />
                        //                     <span>下拉刷新</span>
                        //                 </div>
                        //             );

                        //         case REFRESH_STATE.drop:
                        //             return (
                        //                 <div className={cls}>
                        //                     <ActivityIndicator loading={false} percent={100} />
                        //                     <span>释放立即刷新</span>
                        //                 </div>
                        //             );

                        //         case REFRESH_STATE.loading:
                        //             return (
                        //                 <div className={cls}>
                        //                     <ActivityIndicator type="spinner" />
                        //                     <span>加载中</span>
                        //                 </div>
                        //             );

                        //         case REFRESH_STATE.success:
                        //             return (
                        //                 <div className={cls}>
                        //                     <Icon type="right-round" theme="success" />
                        //                     <span>加载成功</span>
                        //                 </div>
                        //             );

                        //         case REFRESH_STATE.failure:
                        //             return (
                        //                 <div className={cls}>
                        //                     <Icon type="wrong-round" theme="danger" />
                        //                     <span>加载失败</span>
                        //                 </div>
                        //             );

                        //         default:
                        //     }
                        // },
                    }}
                    load={{
                        state: loading,
                        distance: 30,
                        handler: !noRequest ? loadData : () => { setRefreshing(REFRESH_STATE.normal); setLoading(LOAD_STATE.normal); return false },
                        // render: (loadState) => {
                        //   const cls = 'custom-control';
                        //   switch (loadState) {
                        //     case LOAD_STATE.loading:
                        //       return <div className={cls}><ActivityIndicator type="spinner" /></div>;

                        //     case LOAD_STATE.failure:
                        //       return <div className={cls}>加载失败</div>;

                        //     case LOAD_STATE.complete:
                        //       return <div className={cls}>我是有底线的</div>;
                        //   }
                        // },
                    }}
                >
                    {children}
                </Pull>
            </ConfigProvider>
            {/* 
            <BackToTop scrollContainer={scrollContainer} onClick={() => console.log('click back to top')}>
                <div
                    style={{
                        width: 60,
                        height: 60,
                        lineHeight: '60px',
                        textAlign: 'center',
                        backgroundColor: '#fff',
                        color: '#999',
                        fontSize: '14px',
                        borderRadius: 30,
                        boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
                        cursor: 'pointer',
                    }}
                >
                    Up
                </div>
            </BackToTop> */}
        </div>
    );
};
export default PullBox