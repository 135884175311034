import { useState, memo } from "react";
import { LOGO } from "../../../constants/imagePath";
import Times from "../../../global-component/time";

import './index.scss'

const BasicLayout = memo(() => {
  return <div className="basiclayout flex" >
    <header className="fb">
      <div className="logo flex">
        <div className='img-logo fc'>
          <img src={LOGO} />
        </div>
        <div className="shop-title">
          森森店铺收银台
        </div>
      </div>
      <div className="info flex">
        <div className="time">
          <Times />
        </div>
      </div>
    </header>
  </div>
})
export default BasicLayout