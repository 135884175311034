import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Button } from "zarm";

import { LOGO } from "../../constants/imagePath";
import { setStorageSync } from "../../utils/uitls";
import { formatUrl } from "../../utils/format";

import "./index.scss";

const MakeOrder = (props: any) => {
    const cart = useSelector((s: any) => s?.cart, shallowEqual);
    const history = useHistory();
    const showOrderType = Boolean(formatUrl()?.type);
    const [current, setCurrent] = useState('shop')
    setStorageSync('orderType', current)

    const [sumList, setSumList] = useState([
        {
            title: '总订单数',
            value: 'xxx'
        },
        {
            title: '订单流水',
            value: 'xxx'
        },
        {
            title: '实际入账',
            value: 'xxx'
        },
        {
            title: '全部抵扣',
            value: 'xxx'
        },

        {
            title: '线下入账',
            value: '总计xxx'
        },
        {
            title: '支付方式1',
            value: 'xxx'
        },
        {
            title: '支付方式2',
            value: 'xxx'
        },

        {
            title: '线上入账',
            value: '总计xxx'
        },
        {
            title: '支付方式1',
            value: 'xxx'
        },
        {
            title: '支付方式2',
            value: 'xxx'
        },

        {
            title: '会员卡抵扣',
            value: '总计xxx'
        },
        {
            title: '礼品卡抵扣',
            value: '总计xxx'
        },
    ])

    return (
        <div className="makeOrder">
            {!showOrderType && <div className="logo-image">
                <img src={LOGO} />
            </div>}
            {!showOrderType && <div className="text-tips fc text-6">
                {!showOrderType ? "当前暂无订单" : ""}
            </div>}
            {!showOrderType && <div>
                <div className='fc text-3'>
                    请在下方选择下单类型
                </div>
                <div className='text-margin fc text-3'>
                    选择类型后
                </div>
                <div className='text-margin-bt fc text-3'>
                    点击右方菜单即可立即点单
                </div>
                <div className='fc'>
                    <Button
                        className={`${current === 'shop' ? 'active-btn' : ''}`}
                        shape="round"
                        onClick={() => {
                            setCurrent('shop');
                            setStorageSync('orderType', 'shop')
                            history.push(`/cartorder`)
                        }}
                    >
                        门店打包单
                    </Button>
                </div>
                <div className='btn-row fc'>
                    <Button
                        className={`${current === 'distribute' ? 'active-btn' : ''}`}
                        shape="round"
                        onClick={() => {
                            setCurrent('distribute');
                            setStorageSync('orderType', 'distribute')
                            history.push(`/cartorder`)
                        }}
                    >
                        配送单
                    </Button>
                </div>
                <div className='fc'>
                    <Button
                        className={`${current === 'self' ? 'active-btn' : ''}`}
                        shape="round"
                        onClick={() => {
                            setCurrent('self');
                            setStorageSync('orderType', 'self')
                            history.push(`/cartorder`)
                        }}
                    >自提单
                    </Button>
                </div>

            </div>}
            {
                showOrderType && 
                <div className="sum-section">
                    <div className="order-type text-3">xxx订单</div>
                </div>
            }
            {
                 showOrderType && <div className="sum-section">
                    {sumList && sumList.map((v)=>{
                        return <div className="fb order-type">
                            <div className="title">{v.title}</div>
                            <div className="value">{v.value}</div>
                        </div>
                    })}
                </div>
            }
        </div>
    );
}

export default MakeOrder;
