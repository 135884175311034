import { FC } from "react";
import { Select } from "antd";
import { shallowEqual, useSelector, useStore } from "react-redux";

import "./index.scss";

interface IOrderInfoProps {
    giftAmount?: number; // 礼品卡金额
    giftCardNumber?: string;// 礼品卡卡号
    couponArray?: [];
    currentOrderInfo?: {};
    vipOrderInfo?: any;
    disCountArray: any[];
}

const OrderInfo: FC<IOrderInfoProps> = (props: IOrderInfoProps) => {
    const { giftAmount, currentOrderInfo, vipOrderInfo, giftCardNumber, disCountArray } = props;
    const couponArray = ["暂不使用优惠券"]; //TODO 暂时写死优惠券

    const { Option } = Select;
    const predata = useSelector((state: any) => state.cart?.previewData, shallowEqual);

    return (
        <div className="orderInfo">
            <div className="title flex">
                <div className="icon iconfont iconshouyintai-bencidingdanxinxi"></div>
                <div className="text">本次订单信息</div>
            </div>


            <div className="content">
                {giftCardNumber && <div className="gitCardNo">
                    礼品卡卡号
                    <span>{giftCardNumber}</span>

                </div>}
                <div className="orderAmount">
                    订单金额
                    <span>
                        <span>￥</span>
                        <span>
                            {(
                                Number(vipOrderInfo?.order_amount) +
                                Number(vipOrderInfo?.order_discount_amount)
                            ).toFixed(2)}
                        </span>
                    </span>
                </div>
                {disCountArray?.map((item) => {
                    return (
                        <div key={item?.order_discount_id} className="preferAmount">
                            {item.detail}
                            <span>
                                <span>￥</span>
                                <span>
                                    {item?.amount}
                                </span>
                            </span>
                        </div>
                    );
                })}
                <div className="coupon">
                    优惠券
                    <span
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Select placeholder="请选择优惠券" bordered={false} defaultValue={couponArray[0]}>
                            {couponArray.map((item, index) => {
                                return (
                                    <Option key={index} value={item}>
                                        {item}
                                    </Option>
                                );
                            })}
                        </Select>
                    </span>
                </div>

                {giftCardNumber && <div className="needPay">
                    还需支付金额
                    <span>
                        ¥
                        <span>{vipOrderInfo?.order_amount}</span>
                    </span>

                </div>}
            </div>
        </div >
    );
};

export default OrderInfo;
