import React, { FC, useState, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import MakeOrder from './order/makeorder';
import SumPage from './sum-list';
import BasicLayout from './component/BasicLayout'
import OrderList from './order-list';
import PayList from './pay-list'
import Detail from './detail';

import './index.scss';

const LeftIndex = (props: any) => {
    const [flag, setFlag] = useState('')

    useEffect(() => {
        setFlag(window.location.pathname)
    }, [props])

    return (
        <div className="left-pages">
            {flag !== '/login' && <BasicLayout />}
            <Switch>
                <Route path='/menu' exact component={MakeOrder} />
                <Route path='/makeorder' component={SumPage} />
                <Route path='/orderdetail' component={Detail} />
                <Route path='/orderlist' exact component={OrderList} />
                <Route path='/payorder' component={PayList} />
                <Route path='/cartorder' exact component={OrderList} />
                {/* <Redirect to="/404" /> */}
            </Switch>
        </div>
    );
}

export default LeftIndex;
