/* eslint-disable react/jsx-indent-props */
import React, { useMemo, useState, Fragment } from 'react';
import './skew.scss'

const SkewText = ({
    text = [], // '会员价格', , '¥200'
}) => {
    return (
        <div className='flex skew-wrap'>
            {
                // text.map((e, i) => {
                //     return (
                //         <View className='skew' key={e}>
                //             <View className='bg' style={i == 1 && { background: '#fff', border: '#00D0BF 2rpx solid' }} />
                //             <Text className='tx' style={i == 1 && { color: '#00D0BF' }}>
                //                 {e}
                //             </Text>
                //         </View>
                //     )
                // })
            }
            {text.map((item, index) => {
                return <div key={index} className='skew_box'>
                    <div className={`${index === 1 ? 'member_price' : 'member_text'}`}>{item}</div>
                </div>
            })}
        </div>
    )
}
export default SkewText;

