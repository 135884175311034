import { FC, useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Input, message } from "antd";
import { DatePicker } from "@douyinfe/semi-ui";
import dayjs from "dayjs";

import { getOrderList } from "../../services/order";
import { getSetting } from "../../services/login";
import { actions as global } from "../../store/global";
import { DeliveryType } from "../../constants/order";
import { getNumber } from "../../utils/interval";
import { formatUrl, getCurrentTime } from "../../utils/format";

import PullBox from "../../global-component/pullBox/pull";
import OrderCard from "../component/orderCard";
import OrderTab from "../component/orderTab";
import MemberManage from "../component/memberManage";

import "./index.scss";
import { getStorageSync, setStorageSync } from "../../utils/uitls";

interface OrderListProps { }

const OrderList: FC<OrderListProps> = (props: OrderListProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const globalStore = useSelector((state: any) => state.global, shallowEqual);
    const deliveryType = DeliveryType[formatUrl()?.type].number;
    const orderType = DeliveryType[formatUrl()?.type].orderType;
    const orderStatus = getStorageSync("OrderTabStatus") || '';
    const [pageData, setPageData] = useState<any>([]);
    const [flag, setFlag] = useState(true);
    const [dateValue, setDateValue] = useState(
        dayjs(new Date(new Date())).toString()
    );
    const [searchValue, setSearchValue] = useState("");
    const [current, setCurrent] = useState(formatUrl()?.id);
    const searchDate = dayjs(dateValue).format("YYYYMMDD");
    const [timeOpen, setTimeOpen] = useState(false);
    const [visible, setVisible] = useState(true);
    
    useEffect(()=>{
        if(globalStore.rightListRefresh) handleFlag(); // 刷新列表
    },[globalStore.rightListRefresh])
    
    const handleCurrent = (id: string) => {
        setCurrent(id);
    };

    const handleFlag = () => {
        setVisible(false)
        setFlag(!flag);

        setTimeout(() => {
            setVisible(true)
            setFlag(!flag);
        });
    };

    useEffect(() => {
        handleCurrent(formatUrl()?.id);
    }, []);



    const changeOrderType = (value: string) => {
        setStorageSync("OrderTabStatus", '');
    };

    const changeOrderStatus = (value: any) => {
        handleFlag()
    };

    const getTimeParams = () => {
        const obj = {
            pre_delivery_at: {
                start:
                    dateValue && deliveryType === 2
                        ? dayjs(getCurrentTime(dateValue, "begin")).unix()
                        : "",
                end:
                    dateValue && deliveryType === 2
                        ? dayjs(getCurrentTime(dateValue, "end")).unix()
                        : "",
            },
            pre_pick_up_at: {
                start:
                    dateValue && deliveryType === 1
                        ? dayjs(getCurrentTime(dateValue, "begin")).unix()
                        : "",
                end:
                    dateValue && deliveryType === 1
                        ? dayjs(getCurrentTime(dateValue, "end")).unix()
                        : "",
            },
            pre_make_at: {
                start:
                    (dateValue && deliveryType === "") ||
                        (dateValue && deliveryType === 0)
                        ? dayjs(getCurrentTime(dateValue, "begin")).unix()
                        : "",
                end:
                    (dateValue && deliveryType === "") ||
                        (dateValue && deliveryType === 0)
                        ? dayjs(getCurrentTime(dateValue, "end")).unix()
                        : "",
            }
        }
        return JSON.parse(JSON.stringify(obj))
    }

    const getConfig = () => {
        getSetting({}).then((res) => {
            console.log('resresres', res)
            if (res) {
                dispatch(global.getConfig(res));
                localStorage.setItem('config', JSON.stringify(res))

                const list = res?.list.filter((v: any) => {
                    return v.key === 'CASHIER_DISPLAY_ORDER_TYPE'
                })[0].value.filter((item: any) => {
                    return item.value === 1
                }).map((etem: any) => {
                    return etem.key.split('_')[0]
                })

                const array = Array.from(new Set(list)).map((v) => {
                    if (v === 'applet') {
                        return 1
                    }
                    else if (v === 'app') {
                        return 2
                    }
                    else if (v === 'cashier') {
                        return 3
                    }
                })
                console.log('arrayarray', array)
                return array
            }
        })
    }

    const params = useMemo(() => {
        return {
            params: {
                multi_source: globalStore.tabItem.multi_source,
                source: globalStore.tabItem.source,
                delivery_type: deliveryType,
                order_type: orderType,
                searchValue: searchValue,
                date: Number(searchDate),
                status: Number(orderStatus),
                page: 1,
                ...getTimeParams()
            },
            http: getOrderList,
        };
    }, [
        deliveryType,
        orderType,
        searchValue,
        searchDate,
        orderStatus,
        dateValue,
    ]);

    console.log(globalStore.detailRefresh);
    

    return (
        <div className="order-list">
            <div style={{ marginBottom: "1rem" }} className="header-input">
                {/* <div className="search-logo fc">
                    <div className="logo iconfont icona-shouyintaisousuo"></div>
                </div> */}
                <MemberManage />
            </div>

            <div className="flex top-section">
                <div className="left-section">
                    <OrderTab
                        handleCurrent={handleCurrent}
                        changeOrderType={changeOrderType}
                        changeOrderStatus={changeOrderStatus}
                        handleFlag={handleFlag}
                    />
                </div>
                <div
                    className="right-section flex"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <div className='time-box'>
                        <span className="icon iconfont iconshouyintai-rili1" onClick={() => {
                            setTimeOpen(true)
                        }} />
                        <DatePicker
                            autoAdjustOverflow
                            open={timeOpen}
                            value={dateValue}
                            onBlur={() => { setTimeOpen(false) }}
                            onFocus={() => { setTimeOpen(true) }}
                            position="bottomRight"
                            hideDisabledOptions={false}
                            onChange={(date: any) => {
                                setDateValue(date);
                                setTimeOpen(false)
                                handleFlag()
                            }}
                        // disabledDate={(date) => {
                        //     return (dayjs(date).unix() > dayjs().unix())
                        // }}
                        />
                    </div>
                    <Input
                        value={searchValue}
                        placeholder="请输入单号／手机号"
                        onChange={(e) => {
                            setSearchValue(getNumber(e.target?.value));
                        }}
                        prefix={<span className='iconfont icona-shouyintaisousuo' />}
                        onPressEnter={() => {
                            if (
                                searchValue.length > 4 &&
                                searchValue.length !== 11 &&
                                searchValue.length !== 0
                            ) {
                                message.error("请输入正确的手机号");
                            } else {
                                setFlag(!flag);
                            }
                        }}
                    />
                </div>
            </div>
            {visible && <PullBox
                isTopBtn
                init={flag}
                noRequest={false}
                isWindowBox={false}
                request={params}
                maxHeight="calc(100vh - 8rem - 17rem)"
                onScrollBottom={(result: any) => {
                    if (result.page > 1) {
                        if (deliveryType !== "") {
                            setPageData([
                                ...pageData,
                                ...result.list.filter((item: any) => {
                                    return item.delivery_type === Number(deliveryType);
                                }),
                            ]);
                        }
                        else {
                            setPageData([
                                ...pageData,
                                ...result.list.filter((item: any) => {
                                    return item;
                                }),
                            ]);
                        }
                    }
                    else {
                        setPageData(result.list);
                    }

                    if ((result.total === 0)) {
                        setPageData([]);
                    }
                }}
            >
                <div className="cards flex" style={{ paddingBottom: '2rem' }}>
                    {pageData.map((item: any, index: number) => {
                        return (
                            <div
                                key={index}
                                className={`card-box ${current === item.order_id ? "border" : ""
                                    }`}
                            >
                                <OrderCard
                                    orderInfo={item}
                                    number={index}
                                    flag={flag}
                                    deliveryType={deliveryType}
                                    searchValue={searchValue}
                                    handleFlag={handleFlag}
                                    handleCurrent={handleCurrent}
                                />
                            </div>
                        );
                    })}
                </div>
            </PullBox>}
        </div>
    );
};

export default OrderList;