import { FC, useState, useEffect } from "react";
import { Input } from "@douyinfe/semi-ui";
import { DatePicker } from "@douyinfe/semi-ui";
import dayjs from "dayjs";

import { getStorageSync } from "../../../utils/uitls";
import { getNumber } from "../../../utils/interval";

import "./index.scss";

interface IPickUpInfoDialogProps {
    showDelivery: boolean;
    autoTime: boolean;
    intervalTime: number;
    getSelfInfo(info: any): void;
}

// 自提信息弹窗
const PickUpInfoDialog: FC<IPickUpInfoDialogProps> = (
    props: IPickUpInfoDialogProps
) => {
    const { showDelivery, autoTime, intervalTime, getSelfInfo } = props;

    const defaultSelfInfo = getStorageSync("self-info") || {};
    const [selfTime, setSelfTime] = useState(
        Boolean(defaultSelfInfo?.selfTime)
            ? new Date(defaultSelfInfo?.selfTime * 1000)
            : new Date()
    ); // 自提信息时间
    const [makeTime, setMakeTime] = useState(
        Boolean(defaultSelfInfo?.makeTime)
            ? new Date(defaultSelfInfo?.makeTime * 1000)
            : new Date()
    );
    const [selfNumber, setSelfNumber] = useState(defaultSelfInfo?.selfNumber); // 自提信息电话
    const [selfGoodsRemark, setSelfGoodsRemark] = useState(
        defaultSelfInfo?.selfGoodsRemark
    ); // 自提信息商品备注
    const [timeOpen, setTimeOpen] = useState(false);
    const [makeOpen, setMakeOpen] = useState(false);

    useEffect(() => {
        if (Boolean(defaultSelfInfo?.selfTime) && Boolean(defaultSelfInfo?.makeTime)) {
            setSelfTime(new Date(defaultSelfInfo?.selfTime * 1000))
            setMakeTime(new Date(defaultSelfInfo?.makeTime * 1000))
        }
        else {
            if (showDelivery) {
                setMakeTime((Number(selfTime) - (intervalTime * 1000)))
                setSelfTime(selfTime)
            }
            else if (!showDelivery) {
                setMakeTime(makeTime)
                setSelfTime((Number(makeTime) - (intervalTime * 1000)))
            }
        }
    }, [])

    useEffect(() => {
        const info = {
            selfTime: dayjs(!showDelivery ? Number(makeTime) + intervalTime * 1000 : selfTime).unix(),
            makeTime: dayjs(
                !showDelivery ? makeTime : Number(selfTime) - intervalTime * 1000
            ).unix(),
            selfNumber,
            selfGoodsRemark,
        };
        getSelfInfo(info);
    }, [selfTime, selfNumber, makeTime, selfGoodsRemark]);

    const start = () => {
        return;
    };
    start();
    return (
        <div className="pick-info">
            {autoTime && showDelivery && (
                <div className="pickTime">
                    <div className="title">
                        取货时间<span className="mustInput">*</span>
                    </div>
                    <div
                        className="pick-time"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <span
                            className="icon iconfont iconshouyintai-rili1"
                            onClick={() => {
                                setTimeOpen(true);
                            }}
                        />

                        <DatePicker
                            autoAdjustOverflow
                            value={selfTime}
                            open={timeOpen}
                            placeholder="选择取货时间"
                            type="dateTime"
                            format="yyyy年MM月dd日 hh:mm a"
                            hideDisabledOptions={false}
                            onBlur={() => {
                                setTimeOpen(false);
                            }}
                            onFocus={() => {
                                setTimeOpen(true);
                            }}
                            onChange={(date: any) => {
                                setSelfTime(dayjs(date as any).unix() * 1000 as any);
                            }}
                            disabledDate={(date) => {
                                return dayjs(date).unix() < dayjs().unix() - 86400;
                            }}
                            timePickerOpts={{
                                minuteStep: 10,
                                hideDisabledOptions: true,
                            }}
                            // defaultValue={selfTime}
                        />
                    </div>
                </div>
            )}

           { autoTime && !showDelivery && <div className="pickTime">
                <div className="title">
                    出品时间<span className="mustInput">*</span>
                </div>
                <div
                    className="pick-time"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <span
                        className="icon iconfont iconshouyintai-rili1"
                        onClick={() => {
                            setMakeOpen(true);
                        }}
                    />

                    <DatePicker
                        value={makeTime}
                        open={makeOpen}
                        type="dateTime"
                        placeholder="选择出品时间"
                        format="yyyy年MM月dd日 hh:mm a"
                        hideDisabledOptions={false}
                        onBlur={() => {
                            setMakeOpen(false);
                        }}
                        onFocus={() => {
                            setMakeOpen(true);
                        }}
                        onChange={(date) => {
                            setMakeTime(dayjs(date as any).unix() * 1000 as any);
                        }}
                        disabledDate={(date) => {
                            return dayjs(date).unix() < dayjs().unix() - 86400;
                        }}
                        timePickerOpts={{
                            // use12Hours: true,
                            minuteStep: 10,
                            hideDisabledOptions: true,
                        }}
                    />
                </div>
            </div>}

            <div className="customer-phone">
                <div className="title">
                    联系方式<span className="mustInput">*</span>
                </div>
                <Input
                    value={selfNumber}
                    maxLength={11}
                    placeholder="收货人手机号"
                    onChange={(value: any) => {
                        setSelfNumber(getNumber(value));
                    }}
                />
            </div>
            <div className="goods-remark">
                <div className="title">商品备注</div>
                <Input
                    value={selfGoodsRemark}
                    placeholder="商品备注(非必填)"
                    onChange={(value: any) => {
                        setSelfGoodsRemark(value);
                    }}
                />
            </div>
        </div>
    );
};

export default PickUpInfoDialog;
