import { FC, useState, useEffect } from "react";

import { cate, children_cate } from "../../../interfaces/cate";
import { getGoodsCategoryList } from "../../../services/product";

import "./index.scss";
interface itemValue {
  onChange: Function;
}

const Tab: FC<itemValue> = (props: itemValue) => {
  const { onChange } = props
  const [array, setArray] = useState<cate[]>([]);
  // 选中的一级分类
  const [current, setCurrent] = useState<cate>(array[0]);
  const [result, setResult] = useState<children_cate>();

  useEffect(() => {
    getGoodsCategoryList({}).then((res) => {
      setArray([{
        category_name: '全部',
        category_id: '',
        items: [],
      }, ...res?.list]);
    });
    changeStatus({
      category_name: '全部',
      category_id: '',
      items: [],
    });
  }, []);

  const changeStatus = (item: cate) => {
    setCurrent(item);
    setResult({} as children_cate);
    onChange(item);
  };

  const change = (item: children_cate) => {
    setResult(item);
    onChange(item);
  };

  return (
    <div className="tabs">
      <div className="flex" >
        {array?.map((item: cate) => {
          return (
            <div
              key={item?.category_id}
              className={`item-box fc ${current?.category_id === item.category_id ? "active-item" : ""}`}
              onClick={() => {
                changeStatus(item);
              }}
            >
              <div>{item.category_name}</div>
            </div>
          );
        })}
      </div>
      {
        current?.items[0] && <div className="flex" style={{ marginTop: '1rem' }}>
          {current?.items.map((item: children_cate) => {
            return (
              <div
                key={item?.category_id}
                className={`item-box fc ${result === item ? "active-item" : ""}`}
                onClick={() => {
                  change(item);
                }}
              >
                <div>{item.category_name}</div>
              </div>
            );
          })}
        </div>
      }
    </div>
  );
};

export default Tab;
