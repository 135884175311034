import { FC, useState, useMemo } from "react";
import { Popup } from "zarm";
import { Input, message } from "antd";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { cate } from "../../interfaces/cate";
import { getGoodsDetail, getGoodsList } from "../../services/product";
import { actions as cart } from "../../store/cart";
import { showToast } from "../../utils/Toast";

import PullBox from "../../global-component/pullBox/pull";
import GoodsCard from "../component/goodsCard";
import Skuhooks from "../../global-component/sku-hook/sku-hooks";
import Tab from "../component/tab";
import MemberManage from "../component/memberManage";

import "./index.scss";
import data_filter from "../../global-component/sku-hook/sku-utils/data_filter";

interface IGoodsListProps { }

const GoodsList: FC<IGoodsListProps> = (props: IGoodsListProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [sku, setSku] = useState<any>(null);
    const [pageData, setPageData] = useState<any>([]);
    const [selectCategoryID, setSelectCategoryID] = useState("");
    const [flag, setFlag] = useState(true);
    const [searchString, setSearchString] = useState("");
    const goodsListNone = pageData.length == 0;
    const cartStore = useSelector((state: any) => state.cart, shallowEqual);

    const params = useMemo(() => {
        return {
            params: {
                category_id: selectCategoryID,
                search: searchString,
            },
            http: getGoodsList,
        };
    }, [selectCategoryID, searchString]);

    const is_have_sku = useMemo(() => {
        // setSku(sku)
        // 无商品情况下
        // 当只有一个默认sku的时候不展示弹框,无规格 0为默认规格，1为有规格
        if (sku?.product_setting?.has_sku === 0) {
            dispatch(
                cart.add({
                    ...sku,
                    sku: {
                        ...sku,
                        sku_id: sku.skuList[sku?.skuSpec[0]?.specAttrList[0].id]?.sku_id,
                        stock: sku.product_skus[sku?.skuSpec[0]?.specAttrList[0].id]?.stock,
                    },
                    spec_detail: sku.str,
                    sku_price: sku.discount_price,
                })
            );

            if (
                cartStore.products.filter((item: any) => {
                    return (
                        item.sku.sku_id ===
                        sku.skuList[sku?.skuSpec[0]?.specAttrList[0].id]?.sku_id
                    );
                })[0]?.count +
                1 >
                sku.product_skus[sku?.skuSpec[0]?.specAttrList[0].id]?.stock
            ) {
                message.error("达到库存上限");
            } else {
                setTimeout(() => {
                    showToast.message("加入成功");
                    history.push("/cartorder");
                }, 200);
            }
            return false;
        } else if (sku?.product_setting?.has_sku === 1) {
            return true;
        } else if (!sku?.skuList) {
            return false;
        }
        // return false
    }, [sku]);

    const handleSkuList = (goodsInfo: any) => {
        if (Number(goodsInfo?.product_stock) !== 0) {
            getGoodsDetail({ product_id: goodsInfo.product_id }).then((res) => {
                setSku(data_filter(res));
            });
        } else {
            message.error("该商品已无库存,请补充！");
        }
    };

    const handlerImg = (url: any) => {
        return new Promise((resolve: any, reject: any) => {
            var newimg = new Image();
            newimg.onload = () => {
                resolve(newimg);
                // resolve({width: newimg.width, height: newimg.height})
            };

            newimg.onerror = () => {
                resolve(newimg);
            };

            newimg.src = url;

            // if(newimg.complete){
            //     console.log('缓存newimgnewimgnewimg', '宽', newimg.width, '高', newimg.height)
            // }
            // else {
            //     newimg.onload = () => {
            //         console.log('newimgnewimgnewimg', '宽', newimg.width, '高', newimg.height)
            //         getValue({
            //             width: newimg.width,
            //             height: newimg.height
            //         })
            //     }
            // }
        });
    };

    return (
        <div className="goods-list">
            <div style={{ marginBottom: "1rem" }} className="header-input">
                <MemberManage />
            </div>
            <div className="tab-box fb">
                <Tab
                    onChange={(cate: cate) => {
                        setSelectCategoryID(cate.category_id);
                        setFlag(!flag);
                    }}
                />
                <div className="search-box">
                    <Input
                        value={searchString}
                        placeholder="请输入商品名称"
                        prefix={<span className="iconfont icona-shouyintaisousuo" />}
                        onChange={(e) => {
                            setSearchString(e.target.value);
                        }}
                        onPressEnter={() => {
                            setFlag(!flag);
                        }}
                        onBlur={() => {
                            setFlag(!flag);
                        }}
                    />
                </div>
            </div>
            <Popup title="商品信息" visible={is_have_sku} direction="center">
                <div className="sku-box" style={{ width: "35vw" }}>
                    <div className="fb sku-header">
                        <div className="pro-title">商品信息</div>
                        <div
                            onClick={() => {
                                setSku(null);
                            }}
                            className="popup-close iconfont icon-close flex"
                        />
                    </div>
                    <div>
                        <Skuhooks setShow={setSku} product={sku} />
                    </div>
                </div>
            </Popup>
            {
                <PullBox
                    isTopBtn
                    init={flag}
                    noRequest={false}
                    request={params}
                    maxHeight="calc(100vh - 8rem - 17rem)"
                    onScrollBottom={(result: any) => {
                        if (result.page > 1) {
                            setPageData([...pageData, ...result.list]);
                        } else {
                            setPageData(result.list);
                        }
                        if (result.total === 0) {
                            setPageData([]);
                        }
                    }}
                >
                    <div className="cards flex">
                        {!goodsListNone &&
                            pageData.map((item: any, index: number) => {
                                return (
                                    <div
                                        key={item.product_id}
                                        className="goodCard"
                                        onClick={() => {
                                            handleSkuList(item);
                                        }}
                                    >
                                        <GoodsCard goodsInfo={item} product={sku} />
                                    </div>
                                );
                            })}
                        {goodsListNone && <div className="goodListNone">暂无商品</div>}
                    </div>
                </PullBox>
            }
        </div>
    );
};

export default GoodsList;
