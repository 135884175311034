import CashPayDialog from "../dialog/payDialog/cashPayDialog";

export const REFRESH_STATE = {
  normal: 0, // 普通
  pull: 1, // 下拉刷新（未满足刷新条件）
  drop: 2, // 释放立即刷新（满足刷新条件）
  loading: 3, // 加载中
  success: 4, // 加载成功
  failure: 5, // 加载失败
};

export const LOAD_STATE = {
  normal: 0, // 普通
  abort: 1, // 中止
  loading: 2, // 加载中
  success: 3, // 加载成功
  failure: 4, // 加载失败
  complete: 5, // 加载完成（无新数据）
};


export  let ModalArr = {
    ['payMethodDialog']:{
        key:'payMethodDialog',
        title:'选择线下收款方式',
        
    },
    ['cashPayDialog']:{
        key:'cashPayDialog',
        title:'现金支付',

    },
    ['memberPayDialog']:{
        key:'memberPayDialog',
        title:'会员结账',
        

    },
    ['scanningPayDialog']:{
        key:'scanningPayDialog',
        title:'请选择支付方式',

    },
    ['giftCardDialog']:{
        key:'giftCardDialog',
        title:'礼品卡结账',

    },
    ['secondSureDialog']:{
        key:'secondSureDialog',
        title:'提示',

    },
    ['setProTimeDialog']:{
        key:'setProTimeDialog',
        title:'设置出品时间',

    },
    ['memberManage']:{
        key:'memberManage',
        title:'会员管理',

    },
    ['memberAdd']:{
        key:'memberAdd',
        title:'新增会员',
    },
    ['memberRecharge']:{
        key:'memberRecharge',
        title:'会员充值',
    },
    ['memberAddTips']:{
        key:'memberAddTips',
        title:'会员新增提示',
    },
    ['distributionInfoDialog']:{
        key:'distributionInfoDialog',
        title:'填写配送信息',

    },
    ['pickUpInfoDialog']:{
        key:'pickUpInfoDialog',
        title:'填写自提信息',

    },
    ['loadingDialog']:{
        key:'loadingDialog',
        title:'',
    },
    
}



