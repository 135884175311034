import { FC, useState, useEffect, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Select, message } from "antd";
import { Input } from "@douyinfe/semi-ui";

import { getNumber } from "../../../utils/interval";
import { getStorageSync, setStorageSync } from "../../../utils/uitls";
import { payMethodList } from "../../../services/payment";

import "./index.scss";

interface IPayMethodDialogProps {
    memberInfo?: any;
    inputValue?: any;
    orderInfo?: any
    onOutoutPrice?: Function;
    getInputValue?(value: any): void;

}
// 线下支付弹窗
const PayMethodDialog: FC<IPayMethodDialogProps> = (
    props: IPayMethodDialogProps
) => {
    const { Option } = Select;
    const { inputValue, memberInfo, onOutoutPrice, orderInfo } = props;
    const offLinePayProps = getStorageSync("OFFLINE_PAY_METHOD");
    const cartStore = useSelector(
        (state: any) => state.cart?.previewData,
        shallowEqual
    );
    const [placeholder, setPlaceholder] = useState("请输入");
    const [payListOne, setPayListOne] = useState([]);
    const [payListTwo, setPayListTwo] = useState([]);

    const [shouldPay, setShouldPay] = useState(
        inputValue ? inputValue : memberInfo?.order_amount
    );
    const [actualPay, setActualPay] = useState(
        shouldPay ?? cartStore.order_amount
    );

    const [id, setId] = useState(undefined);
    const [idTwo, setIdTwo] = useState('');

    useEffect(() => {
        setStorageSync("OFFLINE_PAY_METHOD", '');
        setStorageSync('OFFLINE_PAY', '')
    }, [])

    let remain = useMemo(() => {
        return inputValue ? actualPay - Number(shouldPay) : Number(actualPay) - Number(cartStore.order_amount)
        // : Number(actualPay) - Number(cartStore.order_amount);
    }, [actualPay]);

    useEffect(() => {
        onOutoutPrice &&
            onOutoutPrice({
                remain,
                actualPay,
            });
    }, [remain, actualPay]);

    useEffect(() => {
        payMethodList().then((res) => {
            setPayListOne(res);
        });
    }, []);

    const switchPayMethodOne = (value: any, opt: any) => {
        if (opt.paymethod?.items?.length > 0) {
            setStorageSync('OFFLINE_PAY', true)
            setStorageSync("OFFLINE_PAY_METHOD", { ...opt.paymethod, custom_item_id: opt.paymethod?.items[0]?.custom_item_id });
        } else {
            setStorageSync('OFFLINE_PAY', true)
            setStorageSync("OFFLINE_PAY_METHOD", opt.paymethod);
        }
        setPayListTwo(opt.paymethod?.items);
        setId(value);
        setIdTwo('')

    };

    const switchPayMethodTwo = (value: any, opt: any) => {
        setStorageSync('OFFLINE_PAY', true)
        setIdTwo(value);
        setStorageSync("OFFLINE_PAY_METHOD", {
            ...offLinePayProps,
            custom_item_id: opt.paymethod.custom_item_id,
        });
    };

    return (
        <div
            className="payMethod-dialog"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <div className="content">
                <div className="method-select flex">
                    收款方式:
                    <span
                        className="select-box"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Select
                            value={id}
                            bordered={false}
                            placeholder="请选择线下收款方式"
                            onSelect={switchPayMethodOne}
                        >
                            {payListOne?.map((item: any, index) => {
                                return (
                                    <Option value={item.custom_id} key={index} paymethod={item}>
                                        {item.text}
                                    </Option>
                                );
                            })}
                        </Select>

                        {payListTwo && payListTwo?.length !== 0 && (
                            <Select
                                value={idTwo || payListTwo[0]?.custom_item_id}
                                bordered={false}
                                placeholder="请选择线下收款方式"
                                onSelect={switchPayMethodTwo}
                            >
                                {payListTwo?.map((item: any, index: number) => {
                                    return (
                                        <Option
                                            key={index}
                                            value={item?.custom_item_id}
                                            paymethod={item}
                                        >
                                            {item.text}
                                        </Option>
                                    );
                                })}
                            </Select>
                        )}
                    </span>
                </div>
                <div className="received flex">
                    应收金额:
                    <span>
                        <span>￥</span>
                        {shouldPay ?? cartStore.order_amount}
                    </span>
                </div>
                <div className="actual flex">
                    <div>实际金额:</div>
                    <span>
                        <Input
                            placeholder={placeholder}
                            value={actualPay}
                            onFocus={() => {
                                setPlaceholder("");
                            }}
                            onBlur={() => {
                                setPlaceholder("请输入");
                            }}
                            onChange={(value) => {
                                setActualPay(getNumber(value));
                            }}
                        />
                    </span>
                </div>

                <div className="change">
                    找零金额:
                    <span>
                        <span>￥</span>
                        {remain.toFixed(2)}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PayMethodDialog;
