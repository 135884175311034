/**
 *  商品API
 */

import request from '../http/index'


// 获取分类列表
export const getGoodsCategoryList = async (params) => {
    const result = await request({
        method: 'post',
        url: '/cashier/product/category/list',
        data: {
            page: {
                all: "0",
                total: "1",
                page: params.page || "1",
                page_size: "20",
            },
        },
    })
    return result;
}


// 获取商品列表
export const getGoodsList = async (params) => {
    const result = await request({
        method: 'post',
        url: '/cashier/product/list',
        data: {
            "condition": {
                "with_month_sale": 1,
                "with_tag": 1,
                "with_coupon": 1,
                "with_stock": 1,
                "with_has_sku": 1
            },
            "search": {
                "category_id": params.category_id || "",
                "search": params.search || ""
            },
            "sort": {
                "create_at": "",
                "discount_price": "",
                "sale": "",
                "recommend": ""
            },
            "page": {
                "all": 0,
                "total": 1,
                "page": params.page || 1,
                "page_size": 20
            },
        }
    })
    return result;
}

// 获取商品详情
export const getGoodsDetail = async (params) => {
    const result = await request({
        method: 'post',
        url: '/cashier/product/detail',
        data: {
            "product_id": params.product_id,
            "condition": {
                "product_detail": 1,
                "product_images": 1,
                "product_tags": 1,
                "product_specs": 1,
                "product_skus": 1,
                "product_setting": 1
            }
        },
    })
    return result;
}

