import React, { FC, Fragment } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import HandleInput from "../../../global-component/sku-hook/handle-input/HandleInput";

import { formatUrl } from "../../../utils/format";
import { actions as cart } from "../../../store/cart";

import "./index.scss";

interface IMakeProductProps {
  info?: any;
}

const MakeProduct: FC<IMakeProductProps> = (props: IMakeProductProps) => {
  const { info } = props;
  const cartStore = useSelector((state) => state.cart, shallowEqual);
  const isOrder = Boolean(formatUrl()?.id);
  const dispatch = useDispatch();

  const clearOrder = (obj: any, index: any) => {
    dispatch(
      cart.deletePro({ id: obj.id, spec_detail: obj.spec_detail, index: index })
    );
  };




  return (
    <Fragment>
      {(info?.order_detail || cartStore?.products).map(
        (product: any, index: number) => {
          return (
            <Fragment key={index}>
              <div className="proItem fd">
                <div className="flex">
                  {/* 排号 */}
                  <div className="proitem-box " style={{ width: "20%" }}>
                    <div>{index + 1}</div>
                  </div>
                  {/* 品名 */}
                  <div className="proitem-box " style={{ width: "30%" }}>
                    <div>{product?.product_name}</div>
                  </div>
                  {/* 规格 */}
                  <div className="proitem-box " style={{ width: "20%" }}>
                    <div>{product?.spec_detail}</div>
                  </div>
                  {/* 份数 */}
                  <div className="proitem-box" style={{ width: "20%" }}>
                    <HandleInput
                      isOrder={isOrder}
                      num={product?.sku_count ?? product?.count}
                      product={product}
                    />
                  </div>
                  {/* 金额 */}
                  <div className="proitem-box money " style={{ width: "20%" }}>
                    {info?.order_detail && info.pay_method === 1 && Number( product.member_price) !== 0 && (
                      <div className="member-price">
                        ¥{(Number(product?.member_price).toFixed(2) * product?.sku_count).toFixed(2)}
                      </div>
                    )}
                    {/* 会员订单详情展示 */}
                    {
                      <div>¥{info ? (Number(product?.discount_price).toFixed(2) * product?.sku_count).toFixed(2) : (Number(product?.sku_price).toFixed(2) * product?.count).toFixed(2)}</div>
                    }
                    {/* 售价 购物车和非会员展示 */}
                  </div>
                </div>
                {isOrder ? (
                  <div></div>
                ) : (
                  <div
                    className="clear"
                    style={{ textAlign: "right", width: "100%" }}
                    onClick={() => {
                      clearOrder(product, index);
                    }}
                  >
                    删除
                  </div>
                )}
              </div>
            </Fragment>
          );
        }
      )}
    </Fragment>
  );
};

export default MakeProduct;
