import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
  withRouter,
} from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Modal } from "@douyinfe/semi-ui";

import RightIndex from "./rightpages";
import LeftIndex from "./leftpages";
import LoginIndex from "./loginpages";
import LoadingDialog from "./dialog/loadingDialog";

import "./assets/lkicon.css";
import "./var.scss";
import { setStorageSync } from "./utils/uitls";


const App = (props: any) => {
  const globalStore = useSelector((state: any) => state.global, shallowEqual);

  useEffect(() => {
    setStorageSync("OrderTabStatus", '');
  }, []);

  return (
    <div className="App">
      <Router>
        <Switch>
          {!globalStore.isLogin && (
            <Route path="/login" exact component={LoginIndex} />
          )}
          <Route
            path="/"
            render={() => {
              return globalStore.isLogin ? (
                <Redirect to="/menu" />
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
        </Switch>
        {globalStore.isLogin && (
          <div className="contain flex">
            <div
              className="left"
              style={{
                width: "40%",
                height: "100%",
                background: "white",
                overflow: "hidden",
              }}
            >
              <LeftIndex />
            </div>
            <div
              className="right"
              style={{
                width: "60%",
                height: "100%",
                background: "#fff",
                overflow: "hidden",
              }}
            >
              <RightIndex />
            </div>
          </div>
        )}
      </Router>
      <Modal
        centered
        style={{
          width: "60rem",
        }}
        visible={globalStore.modal.findIndex(e => {
          return e === 'loadingDialog'
        }) > -1}
        footer={<div style={{ height: "2rem" }}></div>}

      >
        <LoadingDialog />
      </Modal>
    </div>
  );
};

export default App;
