import { FC, memo } from "react";
import { message } from "antd";

import { Goods } from "../../../interfaces/product";

import "./index.scss";

interface IGoodsCardProps {
    goodsInfo?: Goods;
    product?: object;
}

const GoodsCard: FC<IGoodsCardProps> = (props: IGoodsCardProps) => {
    const {
        goodsInfo,
        product,
    } = props


    return (
        <div className="goods-card">
            <div className="goods-img fc">
                <img src={goodsInfo?.cover} />
                {Number(goodsInfo?.product_stock) === 0 && (
                    <div className="modal-mask" />
                )}
            </div>
            <div className="goods-info fc">
                <div className="goods-title fc">
                    <div>{goodsInfo?.product_name}</div>
                </div>
                <div className="goods-price fc">￥{goodsInfo?.discount_price}</div>
                {Number(goodsInfo?.has_sku) === 0 && Number(goodsInfo?.product_stock) !== 0 && <div className="goods-stock fc">库存{goodsInfo?.product_stock}</div>}
                {Number(goodsInfo?.product_stock) === 0 && (
                    <div className="goods-status fc text-6">沽</div>
                )}
                {Number(goodsInfo?.product_stock) === 0 && (
                    <div className="modal-mask" />
                )}
            </div>
        </div>
    );
};

export default memo(GoodsCard);
