import React, { useState, useContext, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useDispatch } from "react-redux";
import dayjs from 'dayjs';

import PayCard from '../component/paycard';
import DetailCard from '../component/detailcard';

import './index.scss'

function PayOrder(props) {

    const dispatch = useDispatch();
    const globalStore = useSelector(state => state.global, shallowEqual)
    const [array, setArray] = useState([])
    const [arrays, setArrays] = useState([])

    useEffect(() => {
        setArrays([
            {
                key: '会员类型',
                value: globalStore.payObj.member_card?.card_name || ''
            },
            {
                key: '性质',
                value: globalStore.payObj.member_card?.card_name || ''
            },
            {
                key: '充值金额',
                value: globalStore.payObj.order_amount || ''
            },
            {
                key: '赠送金额',
                value: globalStore.payObj.order_discount_amount || ''
            },
            {
                key: '充值时间',
                value: globalStore.payObj.create_at ? dayjs(Number(globalStore.payObj.create_at) * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
            },
            {
                key: '实际到账',
                value: globalStore.payObj.recharge_amount || ''
            },
            {
                key: '实付',
                value: globalStore.payObj.order_amount ? (Number(globalStore.payObj.order_amount)).toFixed(2) : ''
            },
        ])
        setArray([{
            title: '会员信息',
            icon: 'iconfont iconshouyintai-huiyuanxinxi',
            properties: [
                {
                    key: '会员编号',
                    show: true,
                    value: globalStore.payObj.user_member_card_id || ''
                },
                {
                    key: '会员名称',
                    show: true,
                    value: globalStore.payObj.member_card?.card_name || ''
                },
                {
                    key: '会员充值后余额',
                    show: true,
                    value: globalStore.payObj.user_member_card_record?.after || ''
                },
            ]
        },
        {
            title: '订单详情',
            icon: 'iconfont iconshouyintai-dingdanxiangqing',
            properties: [
                {
                    key: '订单号',
                    show: true,
                    value: globalStore.payObj.order_id || ''
                },
                {
                    key: '充值金额',
                    show: true,
                    value: globalStore.payObj.order_amount || ''
                },
                {
                    key: '赠送金额',
                    show: true,
                    value: globalStore.payObj.order_discount_amount || ''
                },
                {
                    key: '到账金额',
                    show: true,
                    value: globalStore.payObj.recharge_amount || ''
                },
                {
                    key: '交易时间',
                    show: true,
                    value: globalStore.payObj.create_at ? dayjs(Number(globalStore.payObj.create_at) * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
                },
            ]
        },
        {
            title: '付款凭证',
            icon: 'iconfont iconshouyintai-fukuanpingzheng',
            properties: [
                {
                    key: '付款类型',
                    show: true,
                    value: globalStore.payObj.pay_type_msg || ''
                },
                {
                    key: '付款方式',
                    show: true,
                    value: globalStore.payObj.pay_channel === 2 ? globalStore.payObj.pay_channel_msg : globalStore.payObj.pay_method_msg
                },
                /*  {
                     key: '微信支付订单号',
                     show: (globalStore.payObj.pay_channel) === 0 ? false : true,
                     value: globalStore.payObj.pay_order?.transaction_id || ''
                 },
                 {
                     key: '微信商户订单号',
                     show: (globalStore.payObj.pay_channel) === 0 ? false : true,
                     value: globalStore.payObj.pay_order?.transaction_id || ''
                 }, */
                {
                    key: "会员手机号",
                    show: globalStore.payObj?.pay_method === 51 ? false : true,
                    value: globalStore.payObj?.user_member_card?.mobile,
                },
                {
                    key: "支付订单号",
                    show: globalStore.payObj?.pay_channel === 51 ? false : true,
                    value: globalStore.payObj?.user_member_card_id,
                },
            ]
        }])
    }, [props])

    return (
        <div className="payorder">
            <PayCard obj={arrays} />
            <DetailCard obj={array} />
        </div>
    );
}

export default PayOrder;
