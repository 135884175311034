import { FC, useState, useEffect, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Input } from "@douyinfe/semi-ui";

import { getNumber } from "../../../utils/interval";

import "./index.scss";

interface ICashPayDialogProps {
  memberInfo?: any;
  inputValue?: any;
  onOutoutPrice?: Function;
  getInputValue?(value: any): void;
}
// 现金支付弹窗
const CashPayDialog: FC<ICashPayDialogProps> = (props: ICashPayDialogProps) => {
  const { inputValue, memberInfo, onOutoutPrice } = props;
  const [placeholder, setPlaceholder] = useState("请输入");

  const cartStore = useSelector(
    (state: any) => state.cart?.previewData,
    shallowEqual
  );
  const [shouldPay, setShouldPay] = useState(
    inputValue ? inputValue : memberInfo?.order_amount
  );
  const [actualPay, setActualPay] = useState(
    memberInfo ? shouldPay : cartStore.order_amount
  );

  let remain = useMemo(() => {
    return memberInfo
      ? actualPay - Number(shouldPay)
      : Number(actualPay) - Number(cartStore.order_amount);
  }, [actualPay]);


  useEffect(() => {
    onOutoutPrice &&
      onOutoutPrice({
        remain,
        actualPay,
      });
  }, [remain, actualPay]);

  return (
    <div
      className="cashPay-dialog"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="content">
        <div className="received">
          应收金额:
          <span>
            <span>￥</span>
            {memberInfo ? shouldPay : cartStore.order_amount}
          </span>
        </div>
        <div className="actual flex">
          <div>实际金额:</div>
          <span>
            <Input
              placeholder={placeholder}
              value={actualPay}
              onFocus={() => {
                setPlaceholder("");
              }}
              onBlur={() => {
                setPlaceholder("请输入");
              }}
              onChange={(value) => {
                setActualPay(getNumber(value));
              }}
            />
          </span>
        </div>

        <div className="change">
          找零金额:
          <span>
            <span>￥</span>
            {remain.toFixed(2)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CashPayDialog;
