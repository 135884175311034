import { FC, useState, useEffect, useMemo } from "react";
import { DatePicker } from "@douyinfe/semi-ui";
import dayjs from "dayjs";

import { setStorageSync } from "../../utils/uitls";

import "./index.scss";

interface ISetProTimeDialogProps {
  proTime: any;
  changePreTime(value: any): void;

}
// 出品时间弹窗
const SetProTimeDialog: FC<ISetProTimeDialogProps> = (props: ISetProTimeDialogProps) => {

  const { proTime, changePreTime } = props

  // const [proTime, setProTime] = useState(dayjs().toString())
  const [timeOpen, setTimeOpen] = useState(false);


  return (
    <div
      className="proTime-dialog"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="title">
        出品时间
      </div>
      <div className="proTime">
        <span
          className="icon iconfont iconshouyintai-rili1"
          onClick={() => {
            setTimeOpen(true);
          }}
        />
        <DatePicker
          value={proTime}
          open={timeOpen}
          type="dateTime"
          placeholder="选择配送时间"
          format="yyyy年MM月dd日 hh:mm a"
          hideDisabledOptions={false}
          onBlur={() => {
            setTimeOpen(false);
          }}
          onFocus={() => {
            setTimeOpen(true);
          }}
          onChange={(date) => {
            changePreTime(dayjs(date).unix() * 1000);
          }}
          disabledDate={(date) => {
            return dayjs(date).unix() < dayjs().unix() - 86400;
          }}
          timePickerOpts={{
            minuteStep: 10,
            hideDisabledOptions: true,

          }}
        />

      </div>

    </div>
  );
};

export default SetProTimeDialog;
