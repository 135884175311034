// 支付渠道
export enum PayChannel{
    UNKNOWN=0,
    WECHAT =1, // 微信
    ALIPAY =2, // 支付宝
}

// 支付方式
export enum PayMode {
    UNKNOWN = 0,
    MEMBER_BALANCE = 1, // 会员余额
    ONLINE_PAY = 2, // 电子支付
    BANK_CARD = 50, // 刷卡
    CASH = 51 ,// 现金
    WEI_DIAN = 52, // 微店
    MEI_TUAN = 53, //美团
    COUPON = 54, // 券
    CUSTOM = 10,// 自定义
}

// 支付类型
export enum PayType {
    OFFLINE = 1, // 线下支付
    ONLINE = 2, // 线上支付
}

// 支付订单类型
export enum PayOrderType{
    ORDER = 1 ,//购物订单
    RECHARGE_ORDER = 2, //充值订单
}

// 扫码方式
export enum ScanningType{
    UNKNOWN = 0,
    SWIPE = 1, //刷卡（被扫）
    SCAN = 2, //扫码（主扫）
}