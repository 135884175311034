/* eslint-disable react/jsx-indent-props */
import { message } from "antd";
import React, { memo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actions as cart } from "../../../store/cart";

import "./handleinput.scss";


const HandleInput = (props) => {
    const { num, product, isSku, isOrder, onChange = Function.prototype } = props;
    const dispatch = useDispatch();
    const cartStore = useSelector((state) => state.cart, shallowEqual);

    const _onChange = (number) => {
        onChange(number);
        !isSku && dispatch(cart.add({ ...product, count: number, type: 'preOrder' }));
        // dispatch(cart.getCount())
        // product
    };

    return (
        <div
            className="handleInput fc"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {isOrder ? <div className='orderNum'>{num}</div> : <div className="handle_input">
                <button
                    className={`minus btn ${num == 1 && "no"} fc`}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (num > 1) _onChange(num - 1);
                        else {
                            message.error('该商品不能减少了哟～')
                        }
                    }}
                >
                    -
                </button>
                {/* <input
                    // type="number"
                    style={{ boxSizing: "border-box" }}
                    className={`num ${window && "fc"}`}
                    value={num}
                    disabled={true}
                    onInput={(e) => {
                        e.stopPropagation();
                        if (e.detail?.value < 0) {
                            _onChange(1);
                            return;
                        }
                        _onChange(e.detail.value);
                    }}
                /> */}
                <div className='num'>
                    {num}
                </div>
                <button
                    className="add btn fc"
                    onClick={(e) => {
                        e.stopPropagation();
                        _onChange(Number(num) + 1);
                    }}
                >
                    +
                </button>
            </div>}
        </div>
    );
};

export default memo(HandleInput);
