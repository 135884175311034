import { FC, useState, useEffect } from "react";
import { Button } from "zarm";
import { Input } from "@douyinfe/semi-ui";
import { message, Select } from 'antd';
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { createVipInfo, getVipCardDetail, getVipIDs } from "../../../services/vipcard";
import { hideLoading, showLoading } from "../../../utils/Toast";
import { isTel } from "../../../utils/uitls";
import { getNumber } from "../../../utils/interval";

import "./index.scss";
import { actions } from "../../../store/global";

interface IMemberAddProps {
    memberNumber: string;
    setAddMemberTipsShow(type: boolean): void;
    handleAddMember(res: boolean): void;
    getMemberNumber(phone: string): void;
    getMemberDetail(detail: any): void;
    getMemberMsg: Function
}

// 新增会员弹窗
const MemberAdd: FC<IMemberAddProps> = (props: IMemberAddProps) => {
    const { memberNumber, setAddMemberTipsShow, handleAddMember, getMemberNumber, getMemberDetail, getMemberMsg } = props

    const { Option } = Select;
    const [memberTypeArray, setMemberTypeArray] = useState([]);
    const [memberType, setMemberType] = useState('1');
    const [memberName, setMemberName] = useState('');
    // const [memberNumber, setMemberNumber] = useState('');
    const [placeholderName, setPlaceholderName] = useState("请输入会员姓名")
    const dispatch = useDispatch()


    useEffect(() => {
        const params = {}
        getVipIDs(params).then(res => {
            setMemberTypeArray(res.list)
        })
    }, [])

    const createInfo = {
        data: {
            mobile: memberNumber,
            name: memberName,
            card_id: memberType,
            balance: 0,
        }
    }
    const isPhone = isTel(createInfo.data.mobile)



    const createMember = () => {
        if (isPhone) {
            createVipInfo(createInfo).then(res => {
                if (res.code) {
                    getMemberMsg(res)
                    handleAddMember(false)
                } else {
                    showLoading()
                    handleAddMember(true)
                    const params = {
                        mobile: memberNumber
                    }
                    getVipCardDetail(params).then(res => {
                        getMemberDetail(res)
                    })
                    hideLoading();


                }
            })
            dispatch(actions.closeModal('memberAdd'))
            setAddMemberTipsShow(true);
        } else {
            message.error('请输入正确的手机号')
        }

    }

    return (
        <div className="memberAdd">
            <div className="title">会员开通</div>
            <div className="content">
                <div className="memberAddInfo">
                    <div className="title">会员基本信息</div>
                    <div className="member-name">
                        <Input
                            placeholder="请输入会员姓名"
                            value={memberName}
                            // onFocus={() => {
                            //     setPlaceholderName('')
                            // }}
                            onChange={(value: any) => {
                                setMemberName(value);
                            }}
                        />
                    </div>
                    <div className="member-number">
                        <Input
                            placeholder="请输入会员联系方式"
                            maxLength={11}
                            value={memberNumber}
                            onChange={(value: any) => {
                                getMemberNumber(getNumber(value));
                            }}
                        />
                    </div>
                </div>
                <div className="memberType">
                    <div className="title">开通选择类型</div>
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Select
                            bordered={false}
                            defaultValue={'1'}
                            placeholder="请选择开通会员类型"
                            optionFilterProp="children"
                            onSelect={(value: string) => {
                                setMemberType(value)
                            }}
                        >
                            {
                                memberTypeArray.map((item: any, index) => {
                                    return <Option key={index} value={item.card_id} >{item.card_name}</Option>
                                })
                            }
                        </Select>
                    </div>
                </div>
                <div className="add-member-btn flex">
                    <Button
                        onClick={() => {
                            dispatch(actions.closeModal('memberAdd'))
                        }}
                    >
                        取消
                    </Button>
                    <Button onClick={() => {
                        createMember()
                        getMemberNumber(memberNumber)
                    }}>开通</Button>
                </div>

            </div>

        </div>
    );
};

export default MemberAdd;
