import { FC, useState, useEffect } from "react";
import { message } from "antd";
import { Input, Modal } from "@douyinfe/semi-ui";
import {
  getRechargeSend,
} from "../../../../services/vipcard";

import "./index.scss";
import { getNumber } from "../../../../utils/interval";

interface INewMemberInfoProps {
  memberInfo: any;
  inputValue: any;
  setMember(params: any): void;
  setScanShow(type: boolean): void; //扫码弹窗
  setCashShow(type: boolean): void; //现金弹窗
  setRechargeMoney(value: string): void //设置充值金额
}

const NewMemberInfo: FC<INewMemberInfoProps> = (props: INewMemberInfoProps) => {
  const { inputValue, memberInfo, setMember, setScanShow, setCashShow, setRechargeMoney } = props;
  const [sendPrice, setSendPrice] = useState("");

  return (
    <div className="newMemberInfo">
      <div className="new-Member">
        <div className="flex">
          <span className="icon iconfont iconshouyintai-huiyuanxinxi"></span>
          <div className="title">新增会员信息</div>
        </div>


        <div className="content">
          <div className="memberType">
            会员类型<span>{memberInfo?.card?.card_name}</span>
          </div>
          <div className="memberName">
            会员名称：<span>{memberInfo?.name}</span>
          </div>
          <div className="memberNumber">
            会员联系方式：<span>{memberInfo?.mobile}</span>
          </div>
          <div className="memberBalance">
            会员当前余额：
            <span>
              <span className="balanceUnit">￥</span> {memberInfo?.frozen}
            </span>
          </div>
          <div>
            <div className="recharge flex">
              <span className="recharge-title">充值金额</span>
              <Input
                placeholder="输入充值金额"
                value={inputValue}
                onChange={(value: any) => {
                  setRechargeMoney(getNumber(value));
                  setMember(value)
                  const params = { price: Number(value) * 100 };
                  getRechargeSend(params).then((res) => {
                    setSendPrice(res.gift_price);
                  });
                }}
              />
            </div>
            {Number(sendPrice) !== 0 && (
              <div className="row-two">
                <span className="gift-amount">
                  <span>赠送金额：￥</span>
                  <span>{sendPrice}</span>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="payMethod fc">
        <div
          className="scanningPay fc"
          onClick={() => {
            if (!Boolean(Number(inputValue))) {
              message.error("请输入充值金额");
            } else {
              setScanShow(true);
            }
          }}
        >
          <span className="icon iconfont iconshouyintai-saomajiezhang"></span>
          <span className="type">扫码充值</span>
        </div>

        <div className="balancePay fc" onClick={() => {
          if (!Boolean(Number(inputValue))) {
            message.error("请输入充值金额");
          } else {
            setCashShow(true);
          }
        }}>
          <div className="icon iconfont iconshouyintai-xianjinchongzhi"></div>
          <div className="type">线下充值</div>
        </div>
      </div>
    </div>
  );
};
export default NewMemberInfo;
