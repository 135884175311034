import { FC, useState, useEffect, Fragment } from "react";
import { Modal } from "@douyinfe/semi-ui";
import { message } from "antd";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getStorageSync, setStorageSync } from "../../../../utils/uitls";
import { clearKeyTime } from "../../../../utils/interval";
import {
    EOrderHandle,
    Ordertype,
    OrderTypeIndex,
} from "../../../../constants/order";
import { rechargeVipBalance } from "../../../../services/vipcard";
import { makeOrder, previewOrder } from "../../../../services/payment";

import GiftCardInfo from "../giftCardInfo";
import MemberInfo from "../../memberPayDialog/memberInfo";
import OrderInfo from "../../memberPayDialog/orderInfo";
import PopUp from "../../../../global-component/pop-up/PopUp";
import ScanningPayDialog from "../../scaningPayDialog";
import MemberPayDialog from "../../memberPayDialog";

import "./index.scss";
import PayMethodDialog from "../../payMethodDialog";
import { handleOrder } from "../../../../services/order";

interface IGiftCardShowProps {
    memberCardShare?: boolean;
    useMemberCard?: boolean;
    balanceNotEnough: boolean;
    MemberBalanceNotEnough?: boolean;
    giftCardNumber?: string;
    giftCardInfo?: any;
    memberNumber: string;
    memberInfo?: any;
    giftCardShow: boolean;
    setMemberInfo(handle: any): void;
    setGiftPayShow(handle: boolean): void;
    getVipDetail(): void;
}

let money = {} as any;

const GiftCardShow: FC<IGiftCardShowProps> = (props: IGiftCardShowProps) => {
    // const { setGiftPayShow } = props;
    const {
        memberCardShare,
        useMemberCard,
        balanceNotEnough,
        MemberBalanceNotEnough,
        giftCardNumber,
        giftCardInfo,
        memberNumber,
        memberInfo,
        giftCardShow,
        setGiftPayShow,
        setMemberInfo,
        getVipDetail,
    } = props;
    const history = useHistory();
    // const memberCardShare = false
    const cartStore = useSelector((state: any) => state.cart, shallowEqual);
    const [inputValue, setInputValue] = useState<string>(""); // 充值输入金额
    const [orderInfo, setOrderInfo] = useState<any>({});
    const [scanShow, setScanShow] = useState(false);
    const [scanRechargeShow, setScanRechargeShow] = useState(false);
    const [remark, setRemark] = useState("");
    const [cashShow, setCashShow] = useState(false);
    const [propsparams, setPropsParams] = useState(giftCardNumber || "");  // 所有礼品卡
    const memberPayMoney =
        Number(memberInfo?.balance) -
        Number(
            orderInfo?.order_discount?.filter((item: any) => {
                return item.type === 7;
            })[0]?.amount
        ) >
        0;

    const giftPayMoney =
        2 * Number(giftCardInfo?.balance) -
        Number(cartStore.previewData?.order_amount) >
        0;

    const memberPayEnough = memberPayMoney || giftPayMoney;

    const selfinfo =
        getStorageSync("self-info") !== "" ? getStorageSync("self-info") : {};
    const distributeinfo =
        getStorageSync("distribute-info") !== ""
            ? getStorageSync("distribute-info")
            : {};

    const init = (getparams: any) => {
        console.log('propsparams:', propsparams);
        
        getparams && setPropsParams(giftCardNumber + ',' + getparams)
        const params = {
            pay_method: memberNumber ? 1 : 0,
            pay_channel: 0,
            pay_type: 1,
            gift_card_id: getparams ? giftCardNumber + ',' + getparams : giftCardNumber,
            mobile: memberNumber,
            delivery_type: OrderTypeIndex[getStorageSync("orderType")],
            order_type: Ordertype[getStorageSync("orderType")],
            date: selfinfo?.selfTime,
            sku_items: cartStore.products.map((v: any) => {
                return {
                    sku_id: v?.sku.sku_id,
                    count: v?.count,
                };
            }),
        };
        previewOrder(params).then((res) => {
            if (res) {
                setOrderInfo(res);
            } else {
            }
        });
    };

    useEffect(() => {
        giftCardInfo && init();
    }, [props, giftCardInfo]);

    const add = (params: any) => {
        setMemberInfo({ ...memberInfo, price: params });
    };

    const getRechargeMoney = (value: any) => {
        setInputValue(value);
    };

    const handleMake = () => {
        const selfinfo =
            getStorageSync("self-info") !== "" ? getStorageSync("self-info") : {};
        const distributeinfo =
            getStorageSync("distribute-info") !== ""
                ? getStorageSync("distribute-info")
                : {};

        const params = {
            // TODO  共享时候增加会员手机号
            number: "",
            pay_method: memberNumber ? 1 : 0,
            pay_channel: 0,
            pay_type: 1,
            gift_card_id: propsparams/* giftCardNumber */,
            // gift_card_id: giftCardNumber,
            mobile: memberNumber,
            delivery_type: OrderTypeIndex[getStorageSync("orderType")],
            order_type: Ordertype[getStorageSync("orderType")],
            // 自提信息
            name: "",
            phone:
                OrderTypeIndex[getStorageSync("orderType")] === 0
                    ? ""
                    : selfinfo.selfNumber || distributeinfo.disNumber,
            pre_pick_up_at: selfinfo?.selfTime || "",
            pre_make_at:
                OrderTypeIndex[getStorageSync("orderType")] === 0
                    ? 0
                    : OrderTypeIndex[getStorageSync("orderType")] === 1
                        ? selfinfo.selfTime - 1800
                        : distributeinfo.disTime - 1800,
            // 配送信息
            pre_delivery_at: distributeinfo.disTime,
            remark: distributeinfo.disGoodsRemark || "",
            delivery_remark: distributeinfo.disRemark || "",
            contact_name: distributeinfo.disName || "",
            address: distributeinfo.disDetailAddress || "",
            location: 0,
            province_id: distributeinfo.disAddressInfo?.province_id,
            city_id: distributeinfo.disAddressInfo?.city_id,
            area_id: distributeinfo.disAddressInfo?.area_id,

            sku_items: cartStore.products.map((item: any) => {
                return {
                    sku_id: item?.sku.sku_id,
                    count: item?.count,
                };
            }),
        };
        makeOrder(params).then((res) => {
            if (res) {
                message.success("会员收款成功");
                history.push(
                    `/orderdetail?type=${getStorageSync("orderType")}&id=${res.order_id}`
                );
            } else if (Number(res.code) !== 0) {
                message.error(res.msg);
            }
        });
    };

    const cashPayOK = () => {
        if (money?.remain < 0) {
            message.error("请输入大于应收的金额");
            return;
        }
        if (!getStorageSync("OFFLINE_PAY")) {
            message.error("请选择收款方式");
            return;
        }
        const params = {
            number: "",
            pay_method: 51,
            pay_channel: 0,
            pay_type: 1,
            gift_card_id: giftCardNumber,
            mobile: memberNumber,
            delivery_type:
                OrderTypeIndex[
                JSON.parse(localStorage.getItem("orderType") as string).data
                ],
            order_type:
                Ordertype[JSON.parse(localStorage.getItem("orderType") as string).data],
            // 自提信息
            name: "",
            phone: selfinfo?.selfNumber || distributeinfo?.disNumber,
            // 配送信息
            pre_delivery_at: distributeinfo.disTime, // 配送时间
            pre_make_at:
                OrderTypeIndex[getStorageSync("orderType")] === 0
                    ? 0
                    : OrderTypeIndex[getStorageSync("orderType")] === 1
                        ? selfinfo.selfTime - 1800
                        : distributeinfo.disTime - 1800, // 出品时间
            pre_pick_up_at: selfinfo?.selfTime || "",
            remark: distributeinfo.disGoodsRemark,
            delivery_remark: distributeinfo.disRemark,
            contact_name: distributeinfo.disName,
            address: distributeinfo.disDetailAddress,
            location: 0,
            province_id: distributeinfo.disAddressInfo?.province_id,
            city_id: distributeinfo.disAddressInfo?.city_id,
            area_id: distributeinfo.disAddressInfo?.area_id,

            sku_items: cartStore.products.map((v: any) => {
                return {
                    sku_id: v?.sku.sku_id,
                    count: v?.count,
                };
            }),
        };
        makeOrder(params).then((res) => {
            if (res) {
                message.success("收款成功");
                history.push(
                    `/orderdetail?type=${getStorageSync("orderType")}&id=${res?.order_id}`
                );
            }
        });
    };

    const offlinePay = () => {
        if (money?.remain < 0 || money.actualPay === "") {
            message.error("请输入大于应收的金额");
            return;
        } else if (!getStorageSync("OFFLINE_PAY")) {
            message.error("请选择收款方式");
            return;
        } else {
            const params = {
                number: "",
                pay_method: getStorageSync("OFFLINE_PAY_METHOD").pay_method,
                pay_channel: getStorageSync("OFFLINE_PAY_METHOD").pay_channel,
                pay_type: getStorageSync("OFFLINE_PAY_METHOD").pay_type,
                // gift_card_id: giftCardNumber,
                gift_card_id: propsparams,
                mobile: memberNumber,
                custom_item_id: getStorageSync("OFFLINE_PAY_METHOD")?.custom_item_id,
                delivery_type:
                    OrderTypeIndex[
                    JSON.parse(localStorage.getItem("orderType") as string).data
                    ],
                order_type:
                    Ordertype[
                    JSON.parse(localStorage.getItem("orderType") as string).data
                    ],
                // 自提信息
                name: "",
                phone: selfinfo?.selfNumber || distributeinfo?.disNumber,
                pre_pick_up_at: selfinfo?.selfTime || "",
                // 配送信息
                pre_delivery_at: distributeinfo.disTime, // 配送时间
                pre_make_at:
                    OrderTypeIndex[getStorageSync("orderType")] === 0
                        ? 0
                        : OrderTypeIndex[getStorageSync("orderType")] === 1
                            ? selfinfo.selfTime - 1800
                            : distributeinfo.disTime - 1800, // 出品时间
                remark: distributeinfo.disGoodsRemark,
                delivery_remark: distributeinfo.disRemark,
                contact_name: distributeinfo.disName,
                address: distributeinfo.disDetailAddress,
                location: 0,
                province_id: distributeinfo.disAddressInfo?.province_id,
                city_id: distributeinfo.disAddressInfo?.city_id,
                area_id: distributeinfo.disAddressInfo?.area_id,

                sku_items: cartStore.products.map((v: any) => {
                    return {
                        sku_id: v?.sku.sku_id,
                        count: v?.count,
                    };
                }),
            };
            makeOrder(params).then((res) => {
                if (res) {
                    message.success("收款成功");
                    history.push(
                        `/orderdetail?type=${getStorageSync("orderType")
                        }&id=${res?.order_id}`
                    );
                }
            });
        }
    };

    // const cashHandler = () => {
    //     if (money?.remain < 0 || money.actualPay === "") {
    //         message.error("请输入大于应收的金额");
    //         return;
    //     }
    //     const params = {
    //         user_id: memberInfo?.user_id || "0",
    //         price: memberInfo.price,
    //         user_member_card_id: memberInfo?.user_member_card_id,
    //         mobile: memberNumber,
    //         pay_type: 1,
    //         pay_method: 51,
    //         pay_channel: 0,
    //         remark: remark.toString(),
    //     };
    //     rechargeVipBalance(params).then((res) => {
    //         if (res) {
    //             message.success(res.pay_status_msg);
    //             setGiftPayShow(true);
    //             getVipDetail();
    //             setInputValue("");
    //             // setCashShow(true)
    //         } else {
    //             message.error("充值失败");
    //             setGiftPayShow(true);
    //             getVipDetail();
    //         }
    //     });
    // };

    const cancelOrder = () => {
        const data = {
            order_id: getStorageSync("ORDER_ID"),
            action: EOrderHandle.CANCEL,
        };
        handleOrder(data).then((res) => {
            console.log(res, "订单关闭");
        });
    };

    const offlineRecharge = () => {
        if (money?.remain < 0 || money.actualPay === "") {
            message.error("请输入大于应收的金额");
            return;
        }
        if (!getStorageSync("OFFLINE_PAY")) {
            message.error("请选择收款方式");
            return;
        }
        const params = {
            user_id: memberInfo?.user_id || "0",
            price: memberInfo.price,
            user_member_card_id: memberInfo?.user_member_card_id,
            mobile: memberNumber,
            pay_method: getStorageSync("OFFLINE_PAY_METHOD").pay_method,
            pay_channel: getStorageSync("OFFLINE_PAY_METHOD").pay_channel,
            pay_type: getStorageSync("OFFLINE_PAY_METHOD").pay_type,
            custom_item_id: getStorageSync("OFFLINE_PAY_METHOD")?.custom_item_id,
            remark: remark.toString(),
        };
        rechargeVipBalance(params).then((res) => {
            if (res) {
                message.success(res.pay_status_msg);
                setGiftPayShow(true);
                getVipDetail();
                setInputValue("");
            } else {
                message.error("充值失败");
                setGiftPayShow(true);
                getVipDetail();
            }
        });
    };

    const giftCardDiscount = orderInfo?.order_discount?.filter((item: any) => {
        return item.type === 6;
    })[0]?.amount;
    console.log('orderInfo:', orderInfo);
    
    return (
        <div className="giftCartPay">
            <Fragment>
                <div
                    style={{
                        maxHeight: "calc(100vh - 30rem)",
                        overflowY: "auto",
                    }}
                >
                    <GiftCardInfo
                        balanceNotEnough={balanceNotEnough}
                        giftCardInfo={giftCardInfo}
                        orderInfo={orderInfo}
                        init={init}
                    />
                    {useMemberCard && (
                        <MemberInfo
                            memberInfo={memberInfo}
                            balanceNotEnough={MemberBalanceNotEnough}
                            setMember={add}
                            inputValue={inputValue}
                            getRechargeMoney={getRechargeMoney}
                        />
                    )}
                    <OrderInfo
                        vipOrderInfo={orderInfo}
                        disCountArray={orderInfo.order_discount}
                    />
                </div>

                {balanceNotEnough ? (
                    <div className="shouldPay flex">
                        还需支付:<span>¥{orderInfo?.order_amount}</span>
                    </div>
                ) : memberInfo ? (
                    <div className="shouldPay flex">
                        会员卡抵扣:<span>¥{giftCardDiscount}</span>
                    </div>
                ) : (
                    <div className="shouldPay flex">
                        礼品卡抵扣:<span>¥{giftCardDiscount}</span>
                    </div>
                )}

                <div className="payMethod flex">
                    {/* {!balanceNotEnough && !MemberBalanceNotEnough && !memberInfo && (
                        <div
                            className="balancePay"
                            onClick={() => {
                                handleMake();
                            }}
                        >
                            <div className="giftPay fc">
                                <span className="icon iconfont iconshouyintai-yuekoukuan"></span>
                                <span className="type">礼品卡扣款</span>
                            </div>
                        </div>
                    )} */}
                    {memberInfo && (
                        <>
                            <div
                                className="balancePay"
                                onClick={() => {
                                    if (!Boolean(inputValue)) {
                                        message.error("请输入充值金额");
                                    } else {
                                        setScanRechargeShow(true);
                                    }
                                }}
                            >
                                <span className="icon iconfont iconshouyintai-saomajiezhang" />
                                <span className="type">扫码充值</span>
                            </div>
                            <Modal
                                style={{
                                    width: "70rem",
                                }}
                                onCancel={() => {
                                    setScanRechargeShow(false);
                                    clearKeyTime("SEARCH_ORDER");
                                    clearKeyTime("GET_PAY_CODE");
                                    clearKeyTime("LISTEN_SCAN_BOX_CODE");
                                    clearKeyTime("USER_PAY_CODE");
                                }}
                                centered
                                title="请选择支付方式"
                                visible={scanRechargeShow}
                                footer={<div style={{ height: "2rem" }}></div>}
                            >
                                {
                                    <ScanningPayDialog
                                        OrderAmount={inputValue}
                                        giftCardNumber={giftCardNumber}
                                        memberNumber={memberNumber}
                                        setScanShow={setScanRechargeShow}
                                        type="vipRecharge"
                                        orderid=""
                                    />
                                }
                            </Modal>
                        </>
                    )}
                    {memberInfo &&
                        (!Boolean(Number(inputValue)) ? (
                            <div className="balancePay">
                                <span className="icon iconfont iconshouyintai-xianjinchongzhi"></span>
                                <span
                                    className="type"
                                    onClick={() => message.error("请输入不为0的充值金额")}
                                >
                                    线下充值
                                </span>
                            </div>
                        ) : (
                            <PopUp
                                width="60rem"
                                show={cashShow}
                                setShow={setCashShow}
                                title="线下充值"
                                okText="收款"
                                onOk={() => {
                                    offlineRecharge();
                                    setCashShow(false);
                                }}
                                open={
                                    <div
                                        className="balancePay"
                                        onClick={() => {
                                            setCashShow(true);
                                        }}
                                    >
                                        <span className="icon iconfont iconshouyintai-xianjinchongzhi"></span>
                                        <span className="type">线下充值</span>
                                    </div>
                                }
                                onClose={() => { }}
                            >
                                <PayMethodDialog
                                    getInputValue={(value: any) => {
                                        setRemark(Number(value).toFixed(2));
                                    }}
                                    inputValue={Number(inputValue)}
                                    onOutoutPrice={(price: any) => {
                                        money = price;
                                    }}
                                    memberInfo={memberInfo}
                                />
                            </PopUp>
                        ))}

                    {memberInfo &&
                        (!memberPayEnough ? (
                            <div
                                className="balancePay balanceNotEnough"
                                onClick={() => {
                                    message.error("请充值！");
                                }}
                            >
                                <div className="scanningPay fc   ">
                                    <span className="icon iconfont iconshouyintai-yuekoukuan"></span>
                                    <span className="type">会员卡扣款</span>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="balancePay"
                                onClick={() => {
                                    handleMake();
                                }}
                            >
                                <div className="scanningPay fc ">
                                    <span className="icon iconfont iconshouyintai-yuekoukuan"></span>
                                    <span className="type">会员卡扣款</span>
                                </div>
                            </div>
                        ))}

                    {orderInfo && parseFloat(orderInfo.order_amount) === 0  && (   
                        <div
                            className="balancePay"
                            onClick={() => {
                                handleMake();
                            }}
                        >
                            <div className="giftPay fc">
                                <span className="icon iconfont iconshouyintai-yuekoukuan"></span>
                                <span className="type">礼品卡扣款</span>
                            </div>
                        </div>
                    )}

                    {balanceNotEnough && !memberCardShare && (
                        <PopUp
                            title="会员结账"
                            open={
                                <div className="balancePay">
                                    <span className="icon iconfont iconshouyintai-huiyuanjiezhang"></span>
                                    <span className="type">会员结账</span>
                                </div>
                            }
                            onClose={() => { }}
                            footer={<div></div>}
                        >
                            {
                                <MemberPayDialog
                                    setGiftPayShow={setGiftPayShow}
                                    giftCardNumber={propsparams}
                                    giftAmount={giftCardInfo?.balance}
                                />
                            }
                        </PopUp>
                    )}
                    {balanceNotEnough && !memberInfo && (
                        <PopUp
                            width="60rem"
                            title="选择线下收款方式"
                            okText="收款"
                            onOk={offlinePay}
                            open={
                                <div className="balancePay">
                                    <span className="icon iconfont iconshouyintai-xianjinchongzhi"></span>
                                    <span className="type">线下结账</span>
                                </div>
                            }
                            onClose={() => { }}
                        >
                            <PayMethodDialog
                                inputValue={orderInfo?.order_amount}
                                getInputValue={(value: any) => {
                                    setRemark(Number(value).toFixed(2));
                                }}
                                onOutoutPrice={(price: any) => {
                                    money = price;
                                }}
                            />
                        </PopUp>
                    )}
                    {balanceNotEnough && !memberInfo && (
                        <>
                            <div className="balancePay" onClick={() => setScanShow(true)}>
                                <span className="icon iconfont iconshouyintai-saomajiezhang" />
                                <span className="type">扫码结账</span>
                            </div>
                            <Modal
                                style={{
                                    width: "70rem",
                                }}
                                onCancel={() => {
                                    setScanShow(false);
                                    if (
                                        getStorageSync("ORDER_ID") &&
                                        getStorageSync("ORDER_STATUS") === 100
                                    ) {
                                        cancelOrder();
                                    }
                                    clearKeyTime("SEARCH_ORDER");
                                    clearKeyTime("GET_PAY_CODE");
                                    clearKeyTime("LISTEN_SCAN_BOX_CODE");
                                    clearKeyTime("USER_PAY_CODE");
                                }}
                                centered
                                title="请选择支付方式"
                                visible={scanShow}
                                footer={<div style={{ height: "2rem" }}></div>}
                            >
                                {
                                    <ScanningPayDialog
                                        memberNumber={memberNumber}
                                        payInfo={orderInfo}
                                        setScanShow={setScanShow}
                                        giftCardNumber={propsparams}
                                        type="proOrder"
                                        orderid=""
                                    />
                                }
                            </Modal>
                        </>
                    )}
                </div>
            </Fragment>
        </div>
    );
};

export default GiftCardShow;
