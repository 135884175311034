/* eslint-disable react/jsx-indent-props */
import React, {
    Fragment,
    useEffect,
    useLayoutEffect,
    useState,
    memo,
    useMemo,
} from "react";
import HandleInput from "./handle-input/HandleInput";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import SkewText from "./skew-text/SkewText";
import useSku from "./useSku";
import { actions as cart } from "../../store/cart";
import { setStorageSync } from "../../utils/uitls";
import { showToast } from "../../utils/Toast";

import "./sku.scss";

const min_max_price_format = (max, min) => {
    if (Number(min || 0) == 0) return null;
    if (Number(max) - Number(min) == 0) {
        return (
            <span>
                <span className="_money">¥</span>
                {min}
            </span>
        );
    } else {
        return (
            <span>
                <span className="_money">¥</span>
                {min}
                <span className="_money">起</span>
            </span>
        );
    }
};

const Skuhooks = memo(
    ({
        show = 1, // 1加入购物车 2 购买 3 all
        setShow = Function.prototype,
        onChange = Function.prototype,
        onOk = Function.prototype,
        initNumber,
        default_sku,
        // { id: 101, name: '4.7寸', parent_name: '尺寸' },
        // { id: 201, name: '16G', parent_name: '内存' },
        // { id: 302, name: '红色', parent_name: '颜色' }
        product,
    }) => {
        const dispatch = useDispatch();
        const history = useHistory();
        const no_show = useMemo(() => {
            // 当只有一个默认sku的时候不展示弹框
            if (Object.keys(product?.skuList || {}).length == 1) {
                return false; // true不展示， false展示
            } else if (!product?.skuList) {
                return null;
            }
            return false; // 展示
        }, [product]);
        const [option, load, { sku, desc }, specList, setSku, specListData] =
            useSku(product, show, default_sku);
        const [num, setNum] = useState(1); // 商品数量
        const [hasSku, setHasSku] = useState(true) // 是否有库存
        const cartStore = useSelector((state) => state.cart, shallowEqual);

        useEffect(() => {
            if (
                cartStore.products.filter((item) => {
                    return item.sku.sku_id === sku.sku_id;
                })[0]?.count +
                num >
                sku?.stock
            ) {
                setHasSku(false)
            }
        }, [sku]);

        useEffect(() => {
            onChange({ sku, desc });
        }, [sku, desc]);


        useEffect(() => {
            if (initNumber) setNum(initNumber);
            else setNum(1);
        }, [product]);

        useLayoutEffect(() => {
            if (no_show && show) {
                setShow(false);
                // CartService.add('1', product.product_id, `${product.skuList[Object.keys(product.skuList)[0]].sku_id}`, 1).then(res => {
                //     if (res) {
                //         dispatch(actions.upcart_price());
                //         setTimeout(() => {
                //             showToast({ title: `加入成功`, icon: 'none' });
                //         }, 300);
                //     }
                // });
            }
        }, [no_show, show]);
        // 预下单
        const preOrder = () => {
            if (!sku?.sku) return;
            let pre = {
                shop_id: "1",
                sku_items: [
                    {
                        sku_id: sku?.sku.sku_id,
                        count: sku?.sku.count,
                    },
                ],
            };
            setStorageSync("pre-data", pre);
            // navLinkTo('order-comfirm/index', {});
        };

        const addCart = async () => {
            if (sku) {
                // const res = await CartService.add('1', product.product_id, `${sku.sku_id}`, num);
                // if (res) {
                //     dispatch(actions.upcart_price());
                //     setTimeout(() => {
                //         setShow(false)
                //         showToast({ title: `加入成功`, icon: 'none' })
                //     }, 200);
                // }

                dispatch(
                    cart.add({
                        ...product,
                        sku,
                        count: num,
                        spec_detail: desc.str,
                        sku_price: desc.discount_price,
                        stock:
                            product.product_skus[product?.skuSpec[0]?.specAttrList[0].id]
                                ?.stock,
                    })
                );

                setTimeout(() => {
                    setShow(null);
                    // showToast.message("加入成功");
                    history.push("/cartorder");
                }, 200);
            } else {
                console.log({ title: `请选择${desc ? desc.str : ""}`, icon: "none" });
            }
        };

        return (
            <>
                {load ? (
                    <div className="sku">
                        {/* <div
                            className="iconfont icon-roundclose"
                            onClick={() => {
                                setShow(false);
                            }}
                        ></div> */}
                        <div className="title flex">
                            <img
                                className="img"
                                src={sku ? sku.cover : product?.cover}
                                alt="err"
                            />
                            <div className="content fd">
                                <div className="name-box">{product?.product_name}</div>
                                <div className="price-box">
                                    <div className="price">
                                        <span className="new price-color">
                                            {!isNaN(desc?.price) ? (
                                                <span>
                                                    <span className="_money">¥</span>
                                                    {desc?.discount_price}
                                                </span>
                                            ) : (
                                                <span>
                                                    {min_max_price_format(
                                                        product?.max_discount_price,
                                                        product?.discount_price
                                                    )}
                                                </span>
                                            )}
                                        </span>
                                        <span className="old">
                                            {!isNaN(desc?.price) ? (
                                                Number(desc?.price) ? (
                                                    <span>
                                                        <span className="_money">¥</span>
                                                        {Number(desc?.price)}
                                                    </span>
                                                ) : null
                                            ) : (
                                                min_max_price_format(
                                                    product?.max_market_price,
                                                    product?.market_price
                                                )
                                            )}
                                        </span>
                                    </div>
                                    <div className="extra-price fb">
                                        <div className="flex price-l">
                                            {min_max_price_format(
                                                product?.max_member_price,
                                                product?.member_price
                                            ) !== null && (
                                                    <SkewText
                                                        text={[
                                                            "会员",
                                                            min_max_price_format(
                                                                product?.max_member_price,
                                                                product?.member_price
                                                            ),
                                                        ]}
                                                    />
                                                )}
                                            {/* <div className='vip-price fc'>￥{!isNaN(desc?.price) ? desc?.member_price : min_max_price_format(product?.max_member_price, product?.member_price) + '起'}</div> */}
                                            {/* <div className='p-item2 fc'>20元券</div> */}
                                        </div>
                                        {/* <div className='sale fc'>月售 {product?.sale}</div> */}
                                    </div>
                                </div>
                                {/* {
                                    desc?.filterStr && <div className='select'>已选：{desc?.filterStr}</div>
                                } */}
                            </div>
                        </div>
                        <div style={{ maxHeight: "10vh", overflowY: "auto" }}>
                            <div className="spec-box">
                                {specList &&
                                    specList.map((item, index) => (
                                        <Fragment key={item.id}>
                                            <div className="select_title flex">{item.specName}</div>
                                            <div className="select_list flex" key={item.id}>
                                                {item.specAttrList.map((attrItem) => {
                                                    const { disabled, active } =
                                                        option.checkSpecAttrDisabled(attrItem.id, index);
                                                    return (
                                                        <div
                                                            key={attrItem.id}
                                                            onClick={() => {
                                                                return (
                                                                    !disabled &&
                                                                    option.handleSpecAttr(
                                                                        { ...attrItem, parent_name: item.specName },
                                                                        index
                                                                    )
                                                                );
                                                            }}
                                                            className={`${disabled && " disabled"} ${active && " act_item"
                                                                } item`}
                                                        >
                                                            {attrItem.name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </Fragment>
                                    ))}
                            </div>
                        </div>

                        <div className="buy_number fb">
                            <div className="number_title flex">
                                <div>购买数量</div>
                                {sku?.stock && <div className="stock">库存 {sku?.stock}件</div>}
                            </div>
                            <HandleInput
                                num={num}
                                isSku
                                onChange={(value: any) => {
                                    if (!sku) return showToast.message("请选择规格");
                                    if (value > sku?.stock) {
                                        setNum(sku?.stock);
                                        return message.error("超过库存上限");
                                    } else if (
                                        cartStore.products.filter((item) => {
                                            return item.sku.sku_id === sku.sku_id;
                                        })[0]?.count +
                                        value >
                                        sku?.stock
                                    ) {
                                        return message.error("超过库存上限");
                                    }
                                    setNum(value);
                                }}
                            />
                        </div>
                        <div className="btn_wrap flex">
                            {show == 1 && (
                                hasSku ? <div
                                    className="btn fc cart-btn normal"
                                    onClick={() => {
                                        addCart();
                                    }}
                                >
                                    确认选择
                                </div>
                                    :
                                    <div
                                        className="btn fc cart-btn normal"
                                        onClick={() => {
                                            setShow(null);
                                            history.push("/cartorder");
                                            return message.error("超过库存上限");
                                        }}
                                    >
                                        确认选择
                                    </div>
                            )}
                            {show == 2 && (
                                <div
                                    className="btn fc buy-btn normal"
                                    onClick={() => {
                                        preOrder();
                                    }}
                                >
                                    立即购买
                                </div>
                            )}
                            {show == 3 && (
                                <>
                                    <div
                                        className="btn fc cart-btn"
                                        onClick={() => {
                                            addCart();
                                        }}
                                    >
                                        确认选择
                                    </div>
                                    <div
                                        className="btn fc buy-btn"
                                        onClick={() => {
                                            preOrder();
                                        }}
                                    >
                                        立即购买
                                    </div>
                                </>
                            )}
                            {show == 4 && (
                                <div
                                    className="btn fc cart-btn normal"
                                    onClick={() => {
                                        if (sku) {
                                            onOk({
                                                ...sku,
                                                ...desc,
                                                product_count: num,
                                                specListData,
                                            });
                                        } else {
                                            showToast.message(`请选择${desc ? desc.str : ""}`);
                                        }
                                    }}
                                >
                                    确定
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
);

export default Skuhooks;
