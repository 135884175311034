import React, { FC, useState, useEffect } from "react";
import { Button } from "zarm";
import { Select, Input, message } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions as global } from "../store/global";
import { getSetting, login } from "../services/login";
import sha1 from "sha1";
import { domainApi } from "../services/login";
import "./index.scss";
import { LoginBanner, LOGO } from "../constants/imagePath";
import { IShopAccount } from "../interfaces/common";

const { Option } = Select;

interface loginInterface {
  shop_account?: string;
  account: string;
  password: string;
}

const LoginIndex: FC<loginInterface> = (props: loginInterface) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [accountValue, setAccountValue] = useState<string>("");
  const [pwdvalue, setpwdvalue] = useState<string>("");
  const [list, setList] = useState([]);
  const [shopName, setShopName] = useState(undefined)

  useEffect(() => {
    dispatch(global.changeIsLogin(Boolean(localStorage.getItem('Authorization'))));
  }, []);

  useEffect(() => {
    handlerShop();
  }, []);

  const handlerShop = () => {
    domainApi().then((res) => {
      setList(res.list);
    });
  };

  const handlerConfig = () => {
    getSetting({}).then((res) => {
      if (res) {
        dispatch(global.getConfig(res));
        localStorage.setItem('config', JSON.stringify(res))
      }
    });
  }

  return (
    <div className="loginindex flex">
      <div className="left-box">
        <div className="image-box">
          <img src={LOGO} />
        </div>
        <div className="project-name">森森收银系统</div>

        <div className='operate-box'>
          <div className="select-box fc">
            <Select
              value={shopName}
              placeholder="请选择店铺账号"
              onSelect={(value, opt) => {
                localStorage.setItem("url", value + "/api/v1");
                setShopName(opt.selectRow.shop_name)
              }}
            >
              {list?.map((item: IShopAccount) => {
                return <Option value={item.url} selectRow={item} >{item.shop_name}</Option>;
              })}
            </Select>
          </div>

          <Input
            type="text"
            placeholder="账号"
            value={accountValue}
            onChange={(e: any) => {
              setAccountValue(e.target.value);
            }}
          />

          <Input
            type="password"
            placeholder="密码"
            value={pwdvalue}
            className="root"
            onChange={(e: any) => {
              setpwdvalue(e.target.value);
            }}
          />

          <Button
            block
            theme="primary"
            onClick={() => {
              localStorage.setItem("account", accountValue);
              if (!Boolean(localStorage.getItem("url"))) {
                message.error("请选择商店");
                return false;
              }
              const payload = {
                account: accountValue,
                password: sha1(pwdvalue),
              };
              login(payload).then((res) => {
                localStorage.setItem("Authorization", "Bearer " + res.token);
                dispatch(global.getToken("Bearer " + res.token));
                dispatch(global.getAccount(accountValue));
                if (res) {
                  dispatch(global.changeIsLogin(true));
                  history.push("/menu");
                } else {
                  dispatch(global.changeIsLogin(false));
                  let url = localStorage.getItem('url') || '';
                  localStorage.clear();
                  localStorage.setItem('url', url)
                }
              });
            }}
          >
            登录
          </Button>

        </div>

      </div>
      <div className="right-box">
        <img src={LoginBanner} />
      </div>
    </div>
  );
};

export default LoginIndex;
