import dayjs from "dayjs";
import React, {
    useState,
    useContext,
    useMemo,
    useEffect,
    Fragment,
    useImperativeHandle,
} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { Popup } from "zarm";
import { actions } from "../../store/global";
import "./pop.scss";

function PopUpCus({
    w,
    children,
    show,
    setShow,
    open,
    footer,
    onOpen,
    onClose,
    position,
    top,
    right,
}) {
    const [keyBoard, setkeyBoard] = useState(false);
    const dispatch = useDispatch()
    const globalStore = useSelector((state) => state.global, shallowEqual);


    const toggle = (bool) => {
        setShow(bool);
    };

    const pullBoxStyle = {
        width: w || "50vw",
        position: position || "relative",
        top: top,
        right: right,
    };

    const onKeyClick = (key) => {
        console.log(key);
        if (["close", "ok"].indexOf(key) > -1) {
            setkeyBoard(!keyBoard);
            return;
        }
        // do something...
    };
    return (
        <Fragment>
            <div className="open" onClick={() => toggle(true)}>
                {open}
            </div>
            <Popup
                visible={show}
                direction="center"
                afterClose={() => { }}
                onMaskClick={() => toggle(false)}
            >
                <div className="popup-box" style={pullBoxStyle}>
                    <div className="close-box" onClick={() => {
                        toggle(false)
                        dispatch(actions.closeModal(globalStore.curModal))
                        dispatch(actions.curModal(''))
                    }}>
                        <div className="popup-close" />
                    </div>
                    <div>{children}</div>
                    {/* <div onClick={() => setkeyBoard(true)}>虚拟键盘</div> */}
                </div>
            </Popup>

            {/* <MemberRecharge /> */}

            {/* <KeyboardPicker visible={keyBoard} type='price' onKeyClick={onKeyClick} /> */}
        </Fragment>
    );
}

export default PopUpCus;
