import { FC, useState } from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { handleOrder } from "../../../services/order";
import { formatUrl } from "../../../utils/format";
import { NOTPRODUCED_STATUS } from "../../../constants/imagePath";
import {
    EOrderHandle,
    OrderStatus,
    OrderTypeNumber,
    PAY_METHOD,
    WAIT,
} from "../../../constants/order";
import { IOrderListInfo } from "../../../interfaces/order";

import PopUp from "../../../global-component/pop-up/PopUp";
import SecondSureDialog from "../../../dialog/secondSureDialog";
import SetProTimeDialog from "../../../dialog/setProTimeDialog";

import "./index.scss";

interface IOrderCardProps {
    flag: boolean;
    number: number;
    orderInfo: IOrderListInfo;
    deliveryType: string;
    searchValue: string;
    handleFlag(): void;
    handleCurrent(id: string): void;
}

const OrderCard: FC<IOrderCardProps> = (props: IOrderCardProps) => {
    const { orderInfo, handleFlag, handleCurrent } = props;
    const { delivery_type } = orderInfo;
    const orderType = formatUrl()?.type;
    const history = useHistory();
    const orderStatus = OrderStatus[delivery_type][orderInfo.status];
    const [proTime, setProTime] = useState(dayjs().unix() * 1000);

    const payMethod = (method: any) => {
        if (method === 2) {
            return orderInfo.pay_channel_msg;
        } else if (method !== 0 && method !== 2) {
            return orderInfo.pay_method_msg;
        } else {
            return false;
        }
    };
    const changePreTime = (value: any) => {
        setProTime(value);
    };

    const handleTimeOk = () => {
        handleFlag();
        handleCurrent(orderInfo?.order_id);
        history.push(`/orderdetail?type=${orderType}&id=${orderInfo?.order_id}`);
    };


    const showTime = (type: number) => {
        switch (type) {
            case 0:
                return orderInfo.pre_make_at
            case 1:
                return orderInfo.pre_pick_up_at
            case 2:
                return orderInfo.pre_delivery_at
            default:
                return 0;
        }

    }

    const handleOrderStatus = () => {
        const data = {
            order_id: orderInfo.order_id,
            action: orderStatus?.action,
        };
        handleOrder(data).then((res) => {
            handleFlag();
            handleCurrent(orderInfo?.order_id);
            history.push(`/orderdetail?type=${orderType}&id=${orderInfo?.order_id}`);
        });
    };

    const cancelOrder = () => {
        const data = {
            order_id: orderInfo?.order_id,
            action: EOrderHandle.CANCEL,
        };
        handleOrder(data).then((res) => {
            handleFlag();
            handleCurrent(orderInfo?.order_id);
            history.push(`/orderdetail?type=${orderType}&id=${orderInfo?.order_id}`);
        });
    }

    return (
        <div
            className="order-card"
            onClick={() => {
                handleCurrent(orderInfo?.order_id);
                history.push(
                    `/orderdetail?type=${orderType}&id=${orderInfo?.order_id}`
                );
            }}
        >
            <img src={orderStatus?.image ?? NOTPRODUCED_STATUS} alt="" />

            <div className="order-title">
                <div className="number">
                    <span className="order-number">{orderInfo?.sequence}号</span>

                    {payMethod(orderInfo.pay_method) && (
                        <div
                            className="payMethod fc"
                            style={{
                                background:
                                    PAY_METHOD[payMethod(orderInfo.pay_method) as any]?.color ||
                                    "#f5d6ff",
                                padding: "0rem .6rem",
                            }}
                        >
                            {payMethod(orderInfo.pay_method)}
                        </div>
                    )}
                    {orderInfo?.use_gift_card === 1 && (
                        <div className="order-gift fc">礼品卡</div>
                    )}
                </div>
                <div className="order-status flex">
                    <span className="order-type ">{OrderTypeNumber[delivery_type]}</span>
                    <span className="order-time ">
                        {dayjs(showTime(delivery_type) * 1000).format("HH: mm")}
                    </span>
                </div>
            </div>

            <div className="order-info fb">
                <div
                    className="order-state fc"
                    style={{ color: orderStatus?.color || "#FD7E24" }}
                >
                    {orderInfo?.status_msg}
                </div>

                {delivery_type !== 0 &&
                    (orderInfo?.status === WAIT ? (
                        <PopUp
                            width="50rem"
                            title="设置出品时间"
                            centered={false}
                            onOk={handleTimeOk}
                            open={<div className="preTime">设置出品时间</div>}
                            onClose={() => { }}
                        >
                            {
                                <SetProTimeDialog
                                    proTime={proTime}
                                    changePreTime={changePreTime}
                                />
                            }
                        </PopUp>
                    ) : (
                        <PopUp
                            width="40rem"
                            onOk={handleOrderStatus}
                            open={
                                <div
                                    className={orderStatus?.handle ? "order-operation fc" : ""}
                                    style={{
                                        background: orderStatus?.handle
                                            ? orderStatus.bg
                                            : "#037AEE",
                                    }}
                                >
                                    {orderStatus?.handle}
                                </div>
                            }
                            onClose={() => { }}
                        >
                            {<SecondSureDialog handleStatus={orderStatus?.handle} />}
                        </PopUp>
                    ))}

                {(delivery_type === 0 || orderInfo?.status === 100) && < div className="amount fc">
                    {delivery_type === 0 && <div className="order-amount">￥{orderInfo?.order_amount}</div>}
                    {orderInfo?.status === 100 &&
                        <PopUp
                            width="40rem"
                            onOk={cancelOrder}
                            open={
                                <div className="order-handle">关闭</div>
                            }
                            onClose={() => { }}
                        >
                            {<SecondSureDialog handleStatus={'取消订单'} />}
                        </PopUp>

                    }
                </div>}




            </div>
        </div >
    );
};

export default OrderCard;
