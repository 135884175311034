import React, { FC, useMemo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { formatUrl } from "../../../utils/format";
import { actions as cart } from "../../../store/cart";
import { actions as global } from "../../../store/global";

import { setStorageSync } from "../../../utils/uitls";

import "./index.scss";
import { getOrderStatus } from "../../../services/order";
import { IOrderTab } from "../../../interfaces/cate";
import { ALL, ORDER_TYPE } from "../../../constants/order";

interface itemValue {
  name: string;
  value: string;
  icon: string;
  source?: number;
  key?: string;
}

const Btabs: FC = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const curOrderType = formatUrl()?.type;


  useEffect(() => {
    getOrderStatus().then((res) => {
      dispatch(global.getOrderStatusList(res?.filter((v) => {
        return ORDER_TYPE[v.text]?.value === curOrderType;
      })[0]?.item))
    });
    dispatch(global.getCurOrderStatus(ALL));
  }, [curOrderType]);



  let array: itemValue[] = [
    {
      name: "菜单",
      value: "",
      icon: "iconshouyintai-caidan",
    },
    {
      name: "门店打包单",
      value: "shop",
      icon: "iconshouyintai-dabaodingdan",
      key: 'cashier_pick_up_order',
      source: 3
    },
    {
      name: "自提订单",
      value: "self",
      icon: "iconshouyintai-zitidingdan",
      key: 'cashier_self_mention_order',
      source: 3
    },
    {
      name: "配送订单",
      value: "distribute",
      icon: "iconshouyintai-chupinpeisongdan",
      key: 'cashier_delivery_order',
      source: 3
    },
    {
      name: "充值订单",
      value: "pay",
      icon: "iconshouyintai-chongzhidingdan",
    },
  ];

  const menu = useMemo(() => {
    return formatUrl()?.type || "";
  }, [history?.location.search]);

  const changeStatus = (obj: any) => {
    switch (obj.value) {
      case "pay":
        history.push(`/payorder?type=${obj.value}`);
        break;
      case "":
        history.push({ pathname: "/menu" });
        break;
      case "self":
        history.push(`/makeorder?type=${obj.value}`);
        break;
      case "distribute":
        history.push(`/makeorder?type=${obj.value}`);
        break;
      case "shop":
        history.push(`/makeorder?type=${obj.value}`);
        break;
      default:
        break;
    }
  };

  return (
    <div className="btabs fb">
      {array.map((item) => {
        return (
          <div
            key={item.value}
            className={`item-box fc ${menu == item.value ? "active-item" : ""}`}
            onClick={() => {
              changeStatus(item);
              setStorageSync("OrderTabStatus", "");
              dispatch(global.getCurOrderType(item.value));
              dispatch(global.getTabItem(item))
              dispatch(global.getCurOrderStatus(ALL));
              if (item.value === "") {
                setStorageSync("distribute-info", "");
                setStorageSync("self-info", "");
                dispatch(cart.clear());
              }
            }}
          >
            <div className="flex">
              <div className={`icon iconfont  ${item.icon}`}></div>
              <div>{item.name}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Btabs;
