import { hideLoading, showLoading } from '../utils/Toast'
import axios from 'axios'
import { baseURL, timeout } from './config'
import { message } from 'antd';

// let pending = []; //声明一个数组用于存储每个请求的取消函数和axios标识
// let removePending = (config) => {
//     // console.log(config);
//     for (let i in pending) {
//         if (pending[i].url === axios.defaults.baseURL + config.url) { //在当前请求在数组中存在时执行取消函数
//             pending[i].f(); //执行取消操作
//             console.log(pending[i].url);
//         }
//     }
// }

function request(config, isthrowMsg) {
    // showLoading();
    const instance = axios.create({
        baseURL: localStorage.getItem('url') || baseURL,
        timeout,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': config.url === 'url: /cashier/login' ? '' : localStorage.getItem('Authorization'),
        }
    })

    //! 数据过滤
    instance.interceptors.response.use(res => {
        return res.data
    })

    //请求拦截
    instance.interceptors.request.use(function (res) {
        // removePending(config); //在一个axios发送前执行一下判定操作，在removePending中执行取消操作
        /**
         * @addToken
         */
        return res
    }, function (error) {

        return Promise.reject(error)
    })

    // 此处封装一层捕捉错误
    return new Promise((resolve, reject) => {
        instance(config).then(res => {
            hideLoading()
            if (res?.code !== 0) {
                // showToast.message(res.msg, 'error')
                message.error(res.msg)
                if (res.msg.indexOf('登录') !== -1) {
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = window.location.origin + '/login'
                    }, 1000);
                }
                isthrowMsg ? resolve(res) : resolve(false)
            } else {
                resolve(res.result || res.data)
            }

        }).catch(err => {
            hideLoading();
            if (err.response) {
            }
            resolve(false);
        })
    })
}
export default request;
