/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
// import * as actionType from './contants'
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { OrderTypeIndex, Ordertype } from "../constants/order";
import { previewOrder } from "../services/payment";
import { formatUrl } from "../utils/format";
import { showToast } from "../utils/Toast";
import { setStorageSync } from "../utils/uitls";
/**
 * 初始化数据
 */
const initialState = {
    products: [],
    previewData: {},
};
/**
 * reducers
 */
const reducers = {
    clearItem: (state, action) => {
        state.products = state.products.filter((item) => {
            return item.product_id !== action.payload;
        });
    },
    clear: (state) => {
        state.products = [];
    },
};

// get预下单数据
const getPro = createAsyncThunk("pro/cartAsync", async (data, thunkAPI) => {
    // data 微信获取到的信息
    const state = JSON.parse(JSON.stringify(thunkAPI.getState()["cart"]));

    /**
     * 获取预下单数据
     */
    const params = {
        pay_method: 51,
        pay_channel: 0,
        pay_type: 1,
        order_type: Ordertype[JSON.parse(localStorage.getItem("orderType")).data],
        delivery_type:
            OrderTypeIndex[JSON.parse(localStorage.getItem("orderType")).data],
        sku_items: state.products.map((v) => {
            return {
                sku_id: v?.sku?.sku_id,
                count: v?.count,
            };
        }),
    };
    const previewData = await previewOrder(params);
    /** */
    return { products: state?.products || [], previewData };
});

// 删除某条
const deletePro = createAsyncThunk("pro/cartAsync", async (data, thunkAPI) => {
    // data 微信获取到的信息
    const state = JSON.parse(JSON.stringify(thunkAPI.getState()["cart"]));
    let products = JSON.parse(JSON.stringify(state.products));
    state.products = products.filter((item, index) => {
        return item.product_id !== data.id && index !== data.index;
    });

    /**
     * 获取预下单数据
     */
    const params = {
        pay_method: 51,
        pay_channel: 0,
        pay_type: 1,
        order_type: Ordertype[JSON.parse(localStorage.getItem("orderType")).data],
        delivery_type:
            OrderTypeIndex[JSON.parse(localStorage.getItem("orderType")).data],
        sku_items: state.products.map((v) => {
            return {
                sku_id: v?.sku?.sku_id,
                count: v?.count,
            };
        }),
    };
    const previewData = await previewOrder(params);
    /** */
    return { products: state?.products || [], previewData };
});

// 加入
const add = createAsyncThunk("user/cartAsync", async (data, thunkAPI) => {
    // data 微信获取到的信息
    const state = JSON.parse(JSON.stringify(thunkAPI.getState()["cart"]));
    const { count } = data;
    const index = state.products.findIndex(
        (e) =>
            e.product_id === data.product_id && e.spec_detail === data.spec_detail
    );
    const isPre = data.type === "preOrder";
    let products = JSON.parse(JSON.stringify(state.products));


    if (index !== -1) {
        if (!isNaN(count) && count > 0) {
            if (!isPre) {
                products[index].count = products[index].count + count;
            } else {
                if (count > products[index].sku?.stock) {
                    message.error("达到库存上限");
                    products[index].count = products[index].sku?.stock;
                } else {
                    products[index].count = count;
                }
            }
        } else {
            if (products[index].count + 1 > products[index].sku?.stock) {
                products[index].count = products[index].sku?.stock;
            } else {
                products[index].count += 1;
            }


        }
    } else {
        const initItem = {
            count: 1,
            ...data,
        };
        products = [...state.products, initItem];
    }

    state.products = products;
    /**
     * 获取预下单数据
     */
    const params = {
        pay_method: 51,
        pay_channel: 0,
        pay_type: 1,
        order_type: Ordertype[JSON.parse(localStorage.getItem("orderType")).data],
        delivery_type:
            OrderTypeIndex[JSON.parse(localStorage.getItem("orderType")).data],
        sku_items: products.map((v) => {
            return {
                sku_id: v?.sku?.sku_id,
                count: v?.count,
            };
        }),
    };
    const previewData = await previewOrder(params);
    /** */
    return { products: state?.products || [], previewData };
});

/**
 * @param {*} builder
 * 监听异步完成处理state
 */
const extraReducers = {
    // 两种写法
    [add.fulfilled](state, action) {
        state.products = action.payload?.products || [];
        state.previewData = action.payload?.previewData || [];
    },
    [deletePro.fulfilled](state, action) {
        state.products = action.payload?.products || [];
        state.previewData = action.payload?.previewData || [];
    },
    [getPro.fulfilled](state, action) {
        state.products = action.payload?.products || [];
        state.previewData = action.payload?.previewData || [];
    },
};

// const extraReducers = builder => {
//     builder.addCase(cartAsync.fulfilled, (state, action) => {
//         console.log(action.payload, 'payload');
//         state.userInfo = action.payload
//     });
// }

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers,
    extraReducers,
});

export const actions = {
    ...cartSlice.actions,
    add,
    deletePro,
    getPro,
};
export default cartSlice.reducer;
