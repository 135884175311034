import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import FootMenu from './component/foot-menu';
import OrderList from './order/order-list';
import GoodsList from './goods/goodsList';
import PayOrder from './payorder/payorder';
import './index.scss';


function RightIndex(props) {
    const [flag, setFlag] = useState('')

    useEffect(() => {
        setFlag(window.location.pathname)
    }, [props])

    return (
        <div className="right-pages">
            <div className="content-box">
                <Switch>
                    <Route path='/menu' exact component={GoodsList} />
                    <Route path='/makeorder' component={OrderList} />
                    <Route path='/orderdetail' component={OrderList} />
                    <Route path='/payorder' component={PayOrder} />
                    <Route path='/orderlist' exact component={GoodsList} />
                    <Route path='/cartorder' exact component={GoodsList} />
                    {/* <Redirect to="/404" /> */}
                </Switch>
            </div>
            {flag !== '/login' && <footer>
                <Route path='/' component={FootMenu} />
            </footer>}
        </div>
    );
}

export default RightIndex;
