import { FC } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { actions } from "../../store/global";
import { ModalArr } from "../../constants/comment";

import "./index.scss";

interface IMemberManageProps {
}

// 会员管理弹窗
const MemberManageDialog: FC<IMemberManageProps> = (props: IMemberManageProps) => {
    const history = useHistory();
    const dispatch = useDispatch()

    return (
        <div className="member-manage">
            <div className="title">会员管理</div>
            <div className="content fb">
                <div
                    className="member-add"
                    onClick={() => {
                        dispatch(actions.closeModal(ModalArr['memberManage'].key))
                        dispatch(actions.openModal(ModalArr['memberRecharge'].key))
                        dispatch(actions.curModal(ModalArr['memberRecharge'].key))
                    }}
                >
                    <div className="logo fc iconfont iconshouyintai-huiyuanchongzhi"></div>
                    <span>会员充值</span>
                </div>
                <div
                    onClick={() => {
                        dispatch(actions.closeModal(ModalArr['memberManage'].key))
                        dispatch(actions.openModal(ModalArr['memberAdd'].key))
                        dispatch(actions.curModal(ModalArr['memberAdd'].key))

                    }}
                    className="member-add"
                >
                    <div className="logo fc iconfont iconshouyintai-xinzenghuiyuan"></div>
                    <span>新增会员</span>
                </div>
                <div
                    className="recharge-order"
                    onClick={() => {
                        dispatch(actions.closeModal(ModalArr['memberManage'].key))
                        history.push(`/payorder?type=pay`)
                    }}
                >
                    <div className="logo fc iconfont iconshouyintai-chongzhidingdan"></div>
                    <span>充值订单</span>
                </div>
            </div>
        </div>
    );
};

export default MemberManageDialog;
