import dayjs from 'dayjs';
import React, { useState, useContext, useMemo, useEffect } from 'react';

import "./time.scss";


function Times() {
    const [time, setTime] = useState({
        week: dayjs().format('dddd'),
        month: dayjs().format('MM-DD'),
        date: dayjs().format('HH: mm'),
    })
    useEffect(() => {
        function run() {
            setTime({
                week: dayjs().format('dddd'),
                month: dayjs().format('MM-DD'),
                date: dayjs().format('HH: mm'),
            })
            setTimeout(() => {
                run()
            }, 5000);
        }
        run()
    }, [])
    return (
        <div className="times flex" style={{ whiteSpace: 'nowrap' }}>
            <div className='date' style={{ fontSize: '2.6rem', marginRight: '1.5rem' }}>
                {time.date}
            </div>
            <div className='fdc'>
                <div>{time.month}</div>
                <div>{time.week}</div>
            </div>
        </div>
    );
}

export default Times;
