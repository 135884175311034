import { FC, useState, useEffect, Fragment } from "react";
import { Input, Modal } from "@douyinfe/semi-ui";
import { Switch, message } from "antd";
import { shallowEqual, useSelector } from "react-redux";

import { getGiftCardDetail, getVipCardDetail } from "../../../services/vipcard";
import { getNumber } from "../../../utils/interval";
import GiftCardShow from "./giftCardShow";

import "./index.scss";

interface IGiftCardPayDialogProps {
    setGiftPayShow(handle: boolean): void;
    memberCardShare?: boolean;
}

const GiftCardPayDialog: FC<IGiftCardPayDialogProps> = (
    props: IGiftCardPayDialogProps
) => {
    const { setGiftPayShow, memberCardShare } = props;
    const [useMemberCard, setUseMemberCard] = useState(false);
    const cartStore = useSelector((state: any) => state.cart, shallowEqual);
    const [giftCardNumber, setGiftCardNumber] = useState<string>("");
    const [giftCardInfo, setGiftCardInfo] = useState<any>(null);
    const [memberNumber, setMemberNumber] = useState<string>("");
    const [memberInfo, setMemberInfo] = useState<any>(null);
    const [giftCardShow, setGiftCardShow] = useState<boolean>(false);
    const [placeholderGift, setPlaceholderGift] = useState("请输入礼品卡号")
    const [placeholderMember, setPlaceholderMember] = useState("请输入手机号")

    const balanceNotEnough =
        giftCardInfo?.balance - cartStore.previewData?.order_amount < 0; // 礼品卡是否余额不足

    const MemberBalanceNotEnough =
        memberInfo?.balance - cartStore.previewData?.order_amount < 0; // 会员卡是否余额不足


    //是否使用会员卡
    const memberUse = () => {
        setUseMemberCard(!useMemberCard);
        if (!useMemberCard) {
            setMemberNumber("");
        }
    };

    useEffect(() => {
        setMemberInfo(null);
        setGiftCardInfo(null);
    }, [useMemberCard]);

    const getGiftCartDetail = () => {
        if (!giftCardNumber) {
            message.error('请输入礼品卡卡号')
            return false;
        } else {
            const params = {
                gift_card_id: giftCardNumber,
            };
            getGiftCardDetail(params).then((res) => {
                if (Object.keys(res).length) {
                    setGiftCardInfo(res);
                    if (!memberCardShare || !useMemberCard) {
                        setGiftCardShow(true)
                    }
                } else {
                    message.error("请输入正确的礼品卡卡号");
                    setGiftCardShow(false)
                    return false
                }
            });
        }
    };
    const getVipDetail = () => {
        if (!memberNumber) {
            message.error('请输入手机号')
            return false
        } else if (!giftCardNumber) {
            return false;
        }
        else {
            const params = {
                mobile: memberNumber,
            };
            getVipCardDetail(params).then((res) => {
                if (res) {
                    setMemberInfo(res);
                    if (memberCardShare && useMemberCard) {
                        setGiftCardShow(true)
                    }
                } else {
                    message.error('请输入正确的手机号')
                    setGiftCardShow(false)
                    return false
                }
            });

        }

    };

    const getGiftMemberDetail = () => {
        getGiftCartDetail();
        getVipDetail();
    };

    return (
        <div className="giftCartPay">
            <div className="giftCard flex">
                <div className="inputNumber">输入礼品卡号</div>
                <Input
                    showClear
                    value={giftCardNumber}
                    placeholder={placeholderGift}
                    prefix={<span className="iconfont icona-shouyintaisousuo" />}
                    onFocus={() => {
                        setPlaceholderGift('')
                    }}
                    onChange={(value: any) => {
                        setGiftCardNumber(getNumber(value));
                    }}
                    onEnterPress={(e) => {
                        getGiftCartDetail()
                    }}
                />
                {!useMemberCard && (
                    <div className="search" onClick={getGiftCartDetail}>
                        查找
                    </div>
                )}
            </div>

            {memberCardShare && (
                <div className="useMember flex">
                    <div className="inputNumber">使用会员</div>
                    <Switch checked={useMemberCard} onChange={memberUse} />
                    {useMemberCard && (
                        <Input
                            showClear
                            value={memberNumber}
                            maxLength={11}
                            placeholder={placeholderMember}
                            prefix={<span className="iconfont icona-shouyintaisousuo" />}
                            onFocus={() => {
                                setPlaceholderMember('')
                            }}
                            onChange={(value: any) => {
                                setMemberNumber(getNumber(value));
                            }}
                            onEnterPress={(e) => {
                                getGiftMemberDetail()
                            }}
                        />
                    )}
                    {useMemberCard && (
                        <div className="search" onClick={getGiftMemberDetail}>
                            查找
                        </div>
                    )}
                </div>
            )}

            <Modal
                style={{
                    width: "56rem",
                }}
                centered
                visible={giftCardShow}
                title="礼品卡结账"
                onCancel={() => {
                    setGiftCardShow(false)
                    setGiftPayShow(true)
                }}
                footer={<div style={{ height: "2rem" }}></div>}
            >
                <GiftCardShow
                    giftCardShow={giftCardShow}
                    memberCardShare={memberCardShare}
                    useMemberCard={useMemberCard}
                    giftCardNumber={giftCardNumber}
                    giftCardInfo={giftCardInfo}
                    memberNumber={memberNumber}
                    memberInfo={memberInfo}
                    balanceNotEnough={balanceNotEnough}
                    MemberBalanceNotEnough={MemberBalanceNotEnough}
                    setMemberInfo={setMemberInfo}
                    setGiftPayShow={setGiftPayShow}
                    getVipDetail={getVipDetail}
                />
            </Modal>
        </div>
    );
};

export default GiftCardPayDialog;
