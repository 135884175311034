import { FC } from 'react'

import './index.scss'

interface ISecondSureDialogProps {
    handleStatus?: string

}

const SecondSureDialog: FC<ISecondSureDialogProps> = (props: ISecondSureDialogProps) => {
    const { handleStatus } = props

    return (
        <div className='second-sure'>
            <div className="title">提示</div>
            <div className="content">是否确认<span>{handleStatus}</span></div>

        </div>
    )
}


export default SecondSureDialog