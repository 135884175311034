import { FC, useState } from 'react';

import './index.scss';

interface ProHeaderProps {
  text?: Array<string>
}

const ProHeader: FC<ProHeaderProps> = (props: ProHeaderProps) => {

  const [text, setText] = useState([
    '排号', '品名', '规格', '份数', '金额'
  ])

  return (
    <div className="proHeader fb">
      {text.map((item, index) => {
        return <div key={index} className='text-box' style={{ width: index === 1 ? '30%' : '20%', textAlign: index === 4 ? 'right' : index === 3 ? 'center' : 'left' }}>{item}</div>
      })}
    </div>
  );
}

export default ProHeader;
