import React, { FC, useState, useEffect, useMemo } from "react";
import { Input, message } from "antd";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DatePicker } from "@douyinfe/semi-ui";

import { getPayOrder } from "../../services/order";
import { getPayOrderDetail } from "../../services/order";
import { actions as global } from "../../store/global";
import { isTel } from "../../utils/uitls";
import { getNumber } from "../../utils/interval";
import { getCurrentTime } from "../../utils/format";

import PullBox from "../../global-component/pullBox/pull";

import "./index.scss";

interface itemValue {
  create_at: string;
  order_id: string;
  mobile: string;
  recharge_amount: number;
}

const PayList = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [flag, setFlag] = useState(true);
  const [orderValue, setOrderValue] = useState<string>("");
  const [dateValue, setDateValue] = useState(dayjs(new Date(new Date())).toString());
  const searchDate = dayjs(dateValue).format("YYYYMMDD");
  const [mobileValue, setMobileValue] = useState<string>("");
  const [active, setActive] = useState<string>(
    Boolean(localStorage.getItem("payobj"))
      ? JSON.parse(localStorage.getItem("payobj")).order_id
      : ""
  );
  const [list, setList] = useState<itemValue[]>([]);
  const [timeOpen, setTimeOpen] = useState(false)



  useEffect(() => {
    if (list.length !== 0) {
      setActive(list[0]?.order_id)
      getPayOrderDetail({ order_id: list[0]?.order_id }).then(
        (res) => {
          localStorage.setItem("payobj", JSON.stringify(res));
          history.push(`/payorder?type=pay&id=${res.order_id}`)
          dispatch(global.changePayObj(res));
        }
      );
    }
    else {
      history.push('/payorder?type=pay')
      dispatch(global.changePayObj({}));

    }
  }, [list])

  return (
    <div className="paylist">
      <header className="flex">
        <div className="order-search">
          {<span className="icon iconfont iconshouyintai-rili1" onClick={() => {
            setTimeOpen(!timeOpen)
          }} />}
          <DatePicker
            autoAdjustOverflow
            showClear
            open={timeOpen}
            onBlur={() => { setTimeOpen(false) }}
            onFocus={() => { setTimeOpen(true) }}
            value={dateValue}
            position="bottomRight"
            hideDisabledOptions={false}
            onChange={(date: any) => {
              setDateValue(date);
              setTimeOpen(false)
              setFlag(!flag);
            }}
          // disabledDate={(date) => {
          //     return (dayjs(date).unix() > dayjs().unix())
          // }}
          />
          {/* <Input
            value={orderValue}
            placeholder="请输入订单号"
            maxLength={18}
            prefix={<span className='iconfont icona-shouyintaisousuo' />}
            onChange={(e: any) => {
              setOrderValue(getNumber(e.target?.value));
            }}
            onPressEnter={() => {
              if (orderValue.length === 18 || orderValue.length === 0) {
                setFlag(!flag);
              } else {
                message.error("请输入正确的订单号");
              }
              // console.log(orderValue.length, 'lengrth');
            }}
          /> */}
        </div>
        <div className="member-search">
          <Input
            type='phone'
            value={mobileValue}
            placeholder="请输入会员手机号"
            maxLength={11}
            prefix={<span className='iconfont icona-shouyintaisousuo' />}
            onChange={(e: any) => {
              setMobileValue(getNumber(e.target?.value));
            }}
            onPressEnter={() => {
              if (isTel(mobileValue) || !mobileValue) {
                setFlag(!flag);
              } else {
                message.error("请输入正确手机号");
              }
            }}
          />
        </div>
        {/*  <div className="member-search">
            <Button theme="primary" onClick={()=>{setFlag(!flag)}}>搜索</Button>
          </div> */}
      </header>
      <div className="content-box">
        <div className="table-title fb">
          <div className="item-title">交易时间</div>
          <div className="item-title">手机号</div>
          <div className="item-title">到账金额</div>
        </div>
        <div /* className='pullbox' */>
          <PullBox
            isTopBtn
            isWindowBox={false}
            init={flag}
            noRequest={false}
            maxHeight={"calc(100vh - 21.5rem)"}
            request={{
              params: {
                page: 1,
                order_id: orderValue,
                mobile: mobileValue,
                date: {
                  start: dayjs(getCurrentTime(dateValue, "begin")).unix(),
                  end: dayjs(getCurrentTime(dateValue, "end")).unix()
                },
              },
              http: getPayOrder,
            }}
            onScrollBottom={(result: any) => {

              if (result.page > 1) {
                setList([...list, ...result.list]);
              } else {
                setList(result.list);
              }
            }}
          >
            {list.length > 0 &&
              list.map((item, index) => {
                return (
                  <div
                    className={`item-row fb ${item.order_id === active ? "active" : ""
                      }`}
                    key={item.order_id}
                    onClick={() => {

                      getPayOrderDetail({ order_id: item.order_id }).then(
                        (res) => {
                          localStorage.setItem("payobj", JSON.stringify(res));
                          history.push(`/payorder?type=pay&id=${res.order_id}`)
                          dispatch(global.changePayObj(res));
                        }
                      );
                      setActive(item.order_id);
                    }}
                  >
                    <div className="item-row-one">
                      {dayjs(Number(item.create_at) * 1000).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </div>
                    <div className="item-row-one">{item.mobile}</div>
                    <div className="item-row-one">¥{item.recharge_amount}</div>
                  </div>
                );
              })}
          </PullBox>
        </div>
      </div>
    </div>
  );
};

export default PayList;
