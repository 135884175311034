import { FC, useState } from "react";
import dayjs from "dayjs";

import "./index.scss";
import { INIT, CANCEL } from "../../../constants/order";
import orderInfo from "../../../dialog/payDialog/memberPayDialog/orderInfo";
import SecondSureDialog from "../../../dialog/secondSureDialog";
import PopUp from "../../../global-component/pop-up/PopUp";
import { IOrderListInfo } from "../../../interfaces/order";
import { handleRefundAction } from "../../../services/order";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import { formatUrl } from "../../../utils/format";
import { actions } from '../../../store/global'
import { useDispatch } from "react-redux";

interface OrderDetailProps {
  info?: any;
  type?: string;
}

const OrderDetail: FC<OrderDetailProps> = (props: OrderDetailProps) => {
  const { info } = props;
  const [flag, setFlag] = useState(props.type);
  const history = useHistory();
  const dispatch = useDispatch();

  const address = (info: any) => {
    if (Boolean(info?.area?.id)) {
      return (
        info?.province.name + "-" + info?.city.name + "-" + info?.area.name
      );
    } else if (Boolean(info?.city?.id))
      return info?.province.name + "-" + info?.city.name;
    else {
      return info?.province.name;
    }
  };


  const disObj = [
    {
      key: "配送地区",
      value:
        address(info?.order_address?.area_info)
    },
    {
      key: "配送时间",
      value:
        dayjs(Number(info?.pre_delivery_at) * 1000).format(
          "YYYY-MM-DD HH:mm"
        ) || "无",
    },
    {
      key: "姓名",
      value: info?.order_address?.contact_name || "无",
    },
    {
      key: "手机号",
      value: info?.order_address?.mobile || "无",
    },
    {
      key: "详细地址",
      value: info?.order_address?.address + info?.order_address?.number || "无",
    },
    {
      key: "配送备注",
      value: info?.delivery_remark || "无",
    },
    {
      key: "商品备注",
      value: info?.remark || "无",
    },
  ];

  const detailObj = [
    {
      key: "订单号",
      value: info?.order_id,
      show: true
    },
    {
      key: "付款类型",
      value: info?.pay_type_msg,
      show: true
    },
    {
      key: "下单时间",
      value: dayjs(Number(info?.create_at) * 1000).format("YYYY-MM-DD HH:mm"),
      show: true
    },
    {
      key: "交易时间",
      value: dayjs(Number(info?.pay_at) * 1000).format("YYYY-MM-DD HH:mm"),
      show: info?.pay_at !== 0 ? true : false
    },
  ];

  const payMethod = (method: any) => {
    if (method === 2) {
      return info?.pay_channel_msg;
    } else if (method !== 0 && method !== 2) {
      return info?.pay_method_msg;
    } else {
      return false;
    }
  };

  const tokenObj = [
    {
      key: "礼品卡",
      show: info?.use_gift_card === 1 ? true : false,
      value: info?.order_discount?.filter((item) => {
        return item.type === 6;
      })
    },
    {
      key: "付款方式",
      show: payMethod(info?.pay_method) ? true : false,
      value: payMethod(info?.pay_method),
    },
    {
      key: "付款金额",
      show: info?.use_gift_card === 1 && info?.pay_method !== 0 ? true : false,
      value:
        info?.pay_method === 1
          ? info?.order_discount?.filter((item: any) => {
            return item.type === 7;
          })[0]?.amount
          : info?.order_amount,
    },
    {
      key: "微信支付单号",
      show: (info?.pay_channel === 1 && info?.pay_order?.transaction_id !== '') ? true : false,
      value: info?.pay_order?.transaction_id,
    },
    {
      key: "微信商户订单号",
      show: info?.pay_channel === 1 ? true : false,
      value: info?.pay_order?.pay_order_id,
    },
    {
      key: "支付宝支付单号",
      show: info?.pay_channel === 2 ? true : false,
      value: info?.pay_order?.transaction_id,
    },
    {
      key: "支付宝商户订单号",
      show: info?.pay_channel === 2 ? true : false,
      value: info?.pay_order?.pay_order_id,
    },
    {
      key: "会员手机号",
      show: info?.pay_method === 1 ? true : false,
      value: info?.user_card?.mobile,
    },
    {
      key: "支付订单号",
      show:
        info?.pay_method !== 0 &&
          info?.user_card?.record?.user_member_card_record_id
          ? true
          : false,
      value: info?.user_card?.record?.user_member_card_record_id,
    },
  ];

  const selfObj = [
    {
      key: "取货时间",
      value: dayjs(Number(info?.self_mention?.date) * 1000).format(
        "YYYY-MM-DD HH:mm"
      ),
    },
    {
      key: "手机号",
      value: info?.self_mention?.mobile || "无",
    },
    {
      key: "商品备注",
      value: info?.remark || "无",
    },
  ];

  // 退款
  const handleRefund = async (order_id: string) => {
    const { code, msg } = await handleRefundAction(order_id);
    if(code) return message.error(msg)
    // handleFlag();
    message.success('操作成功');
    const orderType = formatUrl()?.type;
    await dispatch(actions.changeRightListRefresh({}));
    history.push(`/orderdetail?type=${orderType}&id=${info?.order_id}`);

  }

  // 获取当天时间
  const getCurrentTiem = () => new Date().toLocaleDateString();
  
  const isShowRefund = (orderInfo:IOrderListInfo) =>{
    const getOrderTime = (time: number) => new Date(time * 1000).toLocaleDateString()
    
    switch(orderInfo.delivery_type){
        // 打包
        case 0:
            return getCurrentTiem() === getOrderTime(orderInfo?.pre_make_at);

        // 自提
        case 1:
            return getCurrentTiem() === getOrderTime(orderInfo?.pre_pick_up_at);

        // 配送
        case 2:
            return getCurrentTiem() === getOrderTime(orderInfo?.pre_delivery_at);
    }
}
  
  const switchShow = (type) => {
    switch (type) {
      case "distribute":
        return (
          <div className="distribute-info">
            <div className="title flex">
              <div className="vertical-line"></div>
              <div className="title-text">配送信息</div>
            </div>
            {disObj.map((item, index) => {
              return (
                <div className="fb item-box" key={index}>
                  <div>{item.key}:</div>
                  <div>{item.value}</div>
                </div>
              );
            })}
          </div>
        );
      case "detail":
        return (
          <div className="detail-info">
            <div className="title flex">
              <div className="vertical-line"></div>
              <div className="title-text order-detail-wrap">
                <div>订单详情</div>
                {
                    // 待支付和已关闭支持退款
                    (info && 'delivery_type' in info &&  isShowRefund(info) && info?.status !== INIT && info?.status !== CANCEL) && (<PopUp
                        width="40rem"
                        onOk={()=> handleRefund(info.order_id)}
                        open={<div className="refund">退款</div>}
                        onClose={() => { }}
                    >
                        {<SecondSureDialog handleStatus="退款" />}
                    </PopUp>)
                }
             
              </div>
            </div>
            {detailObj.map((item, index) => {
              return (
                <div
                  className="fb item-box"
                  key={index}
                  style={{ display: `${item.show ? "flex" : "none"}` }}
                >
                  <div>{item.key}:</div>
                  <div>{item.value}</div>
                </div>
              );
            })}
          </div>
        );
      case "token":
        return (
          <div className="token-info">
            <div className="title flex">
              <div className="vertical-line"></div>
              <div className="title-text flex">付款凭证</div>
            </div>
            {tokenObj.map((item, index) => {
              console.log('item,', item)
              return (<div>
                {!(item.key === '礼品卡') && <div
                  style={{ display: `${item.show ? "flex" : "none"}` }}
                  className="fb item-box"
                  key={index}
                >
                  <div>{item.key}:</div>
                  <div>{item.value}</div>
                </div>}
                {(item.key === '礼品卡') &&
                  <div
                    style={{width: '100%' }}>
                    <div>
                      {item.value && item.value.length > 0 && item.value.map((v, i) => {
                        return <div>
                          <div
                            className="fb item-box"
                            key={i}>
                            <div>礼品卡支付卡号:</div>
                            <div>{v.relation_id}</div>
                          </div>

                          <div
                            className="fb item-box"
                            key={i}>
                            <div>礼品卡支付金额:</div>
                            <div>{v.amount}</div>
                          </div>
                        </div>
                      })}
                    </div>
                  </div>}
              </div>
              );
            })}
          </div>
        );
      case "self":
        return (
          <div className="self-info">
            <div className="title flex">
              <div className="vertical-line"></div>
              <div className="title-text flex">自提信息</div>
            </div>
            {selfObj.map((item, index) => {
              return (
                <div className="fb item-box" key={index}>
                  <div>{item.key}:</div>
                  <div>{item.value}</div>
                </div>
              );
            })}
          </div>
        );
      default:
        break;
    }
  };

  return <div className="orderdetail">{switchShow(flag)}</div>;
};

export default OrderDetail;
