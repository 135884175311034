import { FC, useState } from "react";
import { itemValue } from "../../../interfaces/common";

import "./index.scss";

interface IPayMethodProps {
  payArray: itemValue[];
}

const PayMethod: FC<IPayMethodProps> = (props: IPayMethodProps) => {
  const { payArray } = props;
  const [payType, setPayType] = useState("");
  return (
    <div className="paymethod fa">
      {payArray.map((item, index) => {
        return (
          <div
            key={index}
            className="pay-item fdc"
            onClick={() => {
              setPayType(item.value);
            }}
          >
            <item.component />
          </div>
        );
      })}
    </div>
  );
};

export default PayMethod;
