import React, { useState, useContext, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { message } from "antd";
import { Modal } from "@douyinfe/semi-ui";

import { getCurrentTime } from "../../utils/format";
import { actions, actions as global } from "../../store/global";
import { makeOrder } from "../../services/payment";
import {
  EOrderHandle,
  Finished,
  Ordertype,
  OrderTypeIndex,
} from "../../constants/order";
import { actions as cart } from "../../store/cart";

import { getStorageSync, isTel, setStorageSync } from "../../utils/uitls";
import { getOrderTotal, handleOrder } from "../../services/order";
import { WillDeliver } from "../../constants/order";
import { clearKeyTime } from "../../utils/interval";
import { getSetting, getShopSetting } from "../../services/login";

import ProHeader from "../component/proheader";
import MakeProduct from "../component/MakeProduct";
import ShowCount from "../component/showcount";
import PayMethod from "../component/paymethod";
import PopUp from "../../global-component/pop-up/PopUp";
import PopUpCus from "../../global-component/pop-up/popup_custom";
import PullBox from "../../global-component/pullBox/pull";
import LoadingDialog from "../../dialog/loadingDialog";
import PickUpInfoDialog from "../../dialog/packageDialog/pickUpInfoDialog";
import DistributionInfoDialog from "../../dialog/packageDialog/distributionInfoDialog";
import CashPayDialog from "../../dialog/payDialog/cashPayDialog";
import MemberPayDialog from "../../dialog/payDialog/memberPayDialog";
import GiftCardPayDialog from "../../dialog/payDialog/giftCardPayDialog";
import ScanningPayDialog from "../../dialog/payDialog/scaningPayDialog";
import PayMethodDialog from "../../dialog/payDialog/payMethodDialog";

import "./index.scss";
import order from "../../store/order";

let money = {} as any;
const OrderList = (props: any) => {
  const current = getStorageSync("orderType"); //当前路径对应的订单类型
  const cartStore = useSelector((state: any) => state.cart, shallowEqual);
  const globalStore = useSelector((state: any) => state.global, shallowEqual);
  const history = useHistory();
  const dispatch = useDispatch();

  const [dateValue, setDateValue] = useState(
    dayjs(new Date(new Date())).toString()
  );
  const [warnVisible, setWarnVisible] = useState(true);
  const [disInfo, setDisInfo] = useState<any>({});
  const [selfInfo, setSelfInfo] = useState<any>({});
  const [memberPayShow, setMemberPayShow] = useState(false);
  const [loadingShow, setLoadingShow] = useState(false);
  const [count, setCount] = useState(0);
  const [scanShow, setScanShow] = useState(false);
  const [giftPayShow, setGiftPayShow] = useState(false);
  const style = { overflowY: "auto", maxHeight: "calc(100vh - 33rem)" };
  const [memberCardShare, setMemberCardShare] = useState(false);
  const [disShow, setDisShow] = useState(false);
  const [pickShow, setPickShow] = useState(false);
  const [remark, setRemark] = useState("");
  const [autoTime, setAutoTime] = useState(false);
  const [showDelivery, setShowDelivery] = useState(true);
  const [intervalTime, setIntervalTime] = useState(0);

  useEffect(() => {
    handleSetting();
  }, []);

  useEffect(() => {
    getSetting({}).then((res) => {
      if (res) {
        setMemberCardShare(
          Number(
            res.list.filter((item: any) => {
              return item.key === "GIFT_CARD_PAYMENT_USE_MEMBER_DISCOUNT";
            })[0].value
          ) === 1
        );
      }
    });
  }, []);

  const selfinfo =
    getStorageSync("self-info") !== "" ? getStorageSync("self-info") : {};
  const distributeinfo =
    getStorageSync("distribute-info") !== ""
      ? getStorageSync("distribute-info")
      : {};
  const hasProduct = Boolean(cartStore?.previewData?.sku_count);

  const hasSelfInfo = Boolean(
    OrderTypeIndex[getStorageSync("orderType")] === 0 || selfinfo.selfNumber
  );

  const hasDisInfo = Boolean(
    OrderTypeIndex[getStorageSync("orderType")] === 0 ||
    (distributeinfo.disNumber && Boolean(disInfo.disAddressInfo))
  );

  const switchOffLinePay = (props: any) => { };

  const handleSetting = () => {
    getShopSetting().then((res) => {
      setAutoTime(Boolean(res.auto_enter_order_time?.enabled));
      setShowDelivery(
        res.auto_enter_order_time?.type === "delivery_and_pick_pu_time"
      );
      setIntervalTime(res.auto_enter_order_time?.value);
    });
  };

  const justInfo = () => {
    if (!hasProduct) {
      message.error("请添加商品");
    } else {
      if (
        Boolean(
          !getStorageSync("self-info").disNumber &&
          OrderTypeIndex[getStorageSync("orderType")] === 1
        )
      ) {
        message.error("请填写自提信息");
      } else {
        message.error("请填写配送信息");
      }
    }
  };

  useEffect(() => {
    const params = {
      order_type:
        Ordertype[JSON.parse(localStorage.getItem("orderType") as string).data],
      status:
        OrderTypeIndex[
          JSON.parse(localStorage.getItem("orderType") as string).data
        ] === 0
          ? Finished
          : WillDeliver,
      delivery_type:
        OrderTypeIndex[
        JSON.parse(localStorage.getItem("orderType") as string).data
        ],
      pre_delivery_at: {
        start:
          dateValue &&
            OrderTypeIndex[
            JSON.parse(localStorage.getItem("orderType") as string).data
            ] === 2
            ? dayjs(getCurrentTime(dateValue, "begin") as any).unix()
            : "",
        end:
          dateValue &&
            OrderTypeIndex[
            JSON.parse(localStorage.getItem("orderType") as string).data
            ] === 2
            ? dayjs(getCurrentTime(dateValue, "end") as any).unix()
            : "",
      },
      pre_pick_up_at: {
        start:
          dateValue &&
            OrderTypeIndex[
            JSON.parse(localStorage.getItem("orderType") as string).data
            ] === 1
            ? dayjs(getCurrentTime(dateValue, "begin") as any).unix()
            : "",
        end:
          dateValue &&
            OrderTypeIndex[
            JSON.parse(localStorage.getItem("orderType") as string).data
            ] === 1
            ? dayjs(getCurrentTime(dateValue, "end") as any).unix()
            : "",
      },
      pre_make_at: {
        start:
          (dateValue &&
            OrderTypeIndex[
            JSON.parse(localStorage.getItem("orderType") as string).data
            ] === "") ||
            (dateValue &&
              OrderTypeIndex[
              JSON.parse(localStorage.getItem("orderType") as string).data
              ] === 0)
            ? dayjs(getCurrentTime(dateValue, "begin") as any).unix()
            : "",
        end:
          (dateValue &&
            OrderTypeIndex[
            JSON.parse(localStorage.getItem("orderType") as string).data
            ] === "") ||
            (dateValue &&
              OrderTypeIndex[
              JSON.parse(localStorage.getItem("orderType") as string).data
              ] === 0)
            ? dayjs(getCurrentTime(dateValue, "end") as any).unix()
            : "",
      },
    };
    getOrderTotal(params).then((res) => {
      setCount(res.order_count);
    });
  }, [props]);

  useEffect(() => {
    setStorageSync("distribute-info", "");
    setStorageSync("self-info", "");
    setStorageSync("OFFLINE_PAY_METHOD", "");
    dispatch(global.getDeliveryCheck(false));
    dispatch(global.getSelfCheck(false));
    dispatch(cart.getPro());
  }, []);

  const getDisInfo = (info: any) => {
    setDisInfo(info);
  };

  const getSelfInfo = (info: any) => {
    setSelfInfo(info);
  };

  const pickUpInfoConfirm = () => {
    if (selfInfo.selfNumber && isTel(selfInfo.selfNumber) === false) {
      message.error("请输入正确的手机号");
      dispatch(actions.getSelfCheck(false));
      setPickShow(true);
    } else {
      if (!selfInfo.selfNumber || selfInfo.selfTime === "Invalid Date") {
        setStorageSync("self-info", { ...selfInfo });
        message.error("自提信息未完善");
        dispatch(actions.getSelfCheck(false));
        setPickShow(true);
        return false;
      } else {
        message.success("自提信息已填写");
        setStorageSync("self-info", { ...selfInfo });
        dispatch(actions.getSelfCheck(true));
        setPickShow(false);
      }
    }
  };

  const disInfoConfirm = () => {
    if (disInfo.disNumber && isTel(disInfo.disNumber) === false) {
      message.error("请输入正确的手机号");
      dispatch(actions.getDeliveryCheck(false));
      setDisShow(true);
    } else {
      if (
        !Boolean(disInfo.disAddressInfo) ||
        !disInfo.disNumber ||
        disInfo.disTime === "Invalid Date"
      ) {
        setStorageSync("distribute-info", { ...disInfo });
        message.error("配送信息未完善");
        dispatch(actions.getDeliveryCheck(false));
        setDisShow(true);
        return false;
      } else {
        message.success("配送信息已填写");
        setStorageSync("distribute-info", { ...disInfo });
        dispatch(actions.getDeliveryCheck(true));
        setDisShow(false);
      }
    }
  };

  const switchTitle = (flag: string) => {
    switch (flag) {
      case "shop":
        return "门店打包单";
      case "self":
        return "自提单";
      case "distribute":
        return "配送单";
      default:
        break;
    }
  };

  const handleGiftCard = () => {
    setGiftPayShow(true);
  };

  // const CashPayOK = () => {
  //   if (money?.remain < 0 || money.actualPay === "") {
  //     message.error("请输入大于应收的金额");
  //     return;
  //   } else {
  //     const params = {
  //       number: "",
  //       pay_method: 51,
  //       pay_channel: 0,
  //       pay_type: 1,
  //       delivery_type:
  //         OrderTypeIndex[JSON.parse(localStorage.getItem("orderType") as string).data],
  //       order_type:
  //         Ordertype[JSON.parse(localStorage.getItem("orderType") as string).data],
  //       // 自提信息
  //       name: "",
  //       phone: selfinfo?.selfNumber || distributeinfo?.disNumber,
  //       pre_pick_up_at: selfinfo?.selfTime || "",
  //       // 配送信息
  //       pre_delivery_at: distributeinfo.disTime, // 配送时间
  //       pre_make_at:
  //         OrderTypeIndex[getStorageSync("orderType")] === 0
  //           ? 0
  //           : OrderTypeIndex[getStorageSync("orderType")] === 1
  //             ? selfinfo.selfTime - 1800
  //             : distributeinfo.disTime - 1800, // 出品时间
  //       remark: distributeinfo.disGoodsRemark,
  //       delivery_remark: distributeinfo.disRemark,
  //       contact_name: distributeinfo.disName,
  //       address: distributeinfo.disDetailAddress,
  //       location: 0,
  //       province_id: distributeinfo.disAddressInfo?.province_id,
  //       city_id: distributeinfo.disAddressInfo?.city_id,
  //       area_id: distributeinfo.disAddressInfo?.area_id,

  //       sku_items: cartStore.products.map((v: any) => {
  //         return {
  //           sku_id: v?.sku.sku_id,
  //           count: v?.count,
  //         };
  //       }),
  //     };
  //     makeOrder(params).then((res) => {
  //       if (res) {
  //         message.success("收款成功");
  //         history.push(
  //           `/orderdetail?type=${getStorageSync("orderType")
  //           }&id=${res?.order_id}`
  //         );
  //       }
  //     });
  //   }
  // };

  const offlinePay = () => {
    if (money?.remain < 0 || money.actualPay === "") {
      message.error("请输入大于应收的金额");
      return;
    }
    if (!getStorageSync('OFFLINE_PAY')) {
      message.error("请选择收款方式");
      return;
    }
    const params = {
      number: "",
      pay_method: getStorageSync("OFFLINE_PAY_METHOD").pay_method,
      pay_channel: getStorageSync("OFFLINE_PAY_METHOD").pay_channel,
      pay_type: getStorageSync("OFFLINE_PAY_METHOD").pay_type,
      custom_item_id: getStorageSync("OFFLINE_PAY_METHOD")?.custom_item_id,
      delivery_type:
        OrderTypeIndex[
        JSON.parse(localStorage.getItem("orderType") as string).data
        ],
      order_type:
        Ordertype[
        JSON.parse(localStorage.getItem("orderType") as string).data
        ],
      // 自提信息
      name: "",
      phone: selfinfo?.selfNumber || distributeinfo?.disNumber,
      pre_pick_up_at: selfinfo?.selfTime || "",
      // 配送信息
      pre_delivery_at: distributeinfo.disTime, // 配送时间
      pre_make_at:
        OrderTypeIndex[getStorageSync("orderType")] === 0
          ? 0
          : OrderTypeIndex[getStorageSync("orderType")] === 1
            ? selfinfo.makeTime
            : distributeinfo.makeTime, // 出品时间
      remark: distributeinfo.disGoodsRemark,
      delivery_remark: distributeinfo.disRemark,
      contact_name: distributeinfo.disName,
      address: distributeinfo.disDetailAddress,
      location: 0,
      province_id: distributeinfo.disAddressInfo?.province_id,
      city_id: distributeinfo.disAddressInfo?.city_id,
      area_id: distributeinfo.disAddressInfo?.area_id,

      sku_items: cartStore.products.map((v: any) => {
        return {
          sku_id: v?.sku.sku_id,
          count: v?.count,
        };
      }),
    };
    makeOrder(params).then((res) => {
      if (res) {
        message.success("收款成功");
        history.push(
          `/orderdetail?type=${getStorageSync("orderType")
          }&id=${res?.order_id}`
        );
      }
    });
  };

  const cancelOrder = () => {
    const data = {
      order_id: getStorageSync("ORDER_ID"),
      action: EOrderHandle.CANCEL,
    };
    handleOrder(data).then((res) => {
      console.log(res, "订单关闭");
    });
  };

  const payArray = [
    // {
    //   key: "现金结账",
    //   icon: "iconfont iconshouyintai-xianjinjiezhang",
    //   value: "coin",
    //   component: () => {
    //     return !hasProduct || (!hasDisInfo && !hasSelfInfo) ? (
    //       <div className="fdc" onClick={justInfo}>
    //         <div className="icon-box iconfont iconshouyintai-xianjinjiezhang"></div>
    //         <div>现金结账</div>
    //       </div>
    //     ) : (
    //       <PopUp
    //         width="45rem"
    //         title="现金结账"
    //         okText="收款"
    //         onOk={CashPayOK}
    //         open={
    //           <div className="fdc">
    //             <div className="icon-box iconfont iconshouyintai-xianjinjiezhang"></div>
    //             <div>现金结账</div>
    //           </div>
    //         }
    //         onClose={() => { }}
    //       >
    //         <CashPayDialog
    //           getInputValue={(value: any) => {
    //             setRemark(Number(value).toFixed(2));
    //           }}
    //           onOutoutPrice={(price: any) => {
    //             money = price;
    //           }}
    //         />
    //       </PopUp>
    //     );
    //   },
    // },
    {
      key: "线下结账",
      icon: "iconfont iconshouyintai-xianjinjiezhang",
      value: "coin",
      component: () => {
        return !hasProduct || (!hasDisInfo && !hasSelfInfo) ? (
          <div className="fdc" onClick={justInfo}>
            <div className="icon-box iconfont iconshouyintai-xianjinjiezhang"></div>
            <div>线下结账</div>
          </div>
        ) : (
          <PopUp
            width="60rem"
            title="选择线下收款方式"
            okText="收款"
            onOk={offlinePay}
            open={
              <div className="fdc">
                <div className="icon-box iconfont iconshouyintai-xianjinjiezhang"></div>
                <div>线下结账</div>
              </div>
            }
            onClose={() => {
            }}
          >
            <PayMethodDialog
              getInputValue={(value: any) => {
                setRemark(Number(value).toFixed(2));
              }}
              onOutoutPrice={(price: any) => {
                money = price;
              }}
            />
          </PopUp>
        );
      },
    },
    {
      key: "扫码结账",
      icon: "iconfont iconshouyintai-saomajiezhang",
      value: "code",
      component: () => {
        return !hasProduct || (!hasDisInfo && !hasSelfInfo) ? (
          <div className="fdc" onClick={justInfo}>
            <div className="icon-box iconfont iconshouyintai-saomajiezhang"></div>
            <div>扫码结账</div>
          </div>
        ) : (
          <>
            <div className="fdc" onClick={() => setScanShow(true)}>
              <div className="icon-box iconfont iconshouyintai-saomajiezhang"></div>
              <div>扫码结账</div>
            </div>
            <Modal
              centered
              style={{
                width: "70rem",
              }}
              onCancel={() => {
                setScanShow(false);
                if (
                  getStorageSync("ORDER_ID") &&
                  getStorageSync("ORDER_STATUS") === 100
                ) {
                  cancelOrder();
                }
                setStorageSync("ORDER_ID", "");
                setStorageSync("ORDER_STATUS", "");
                clearKeyTime("SEARCH_ORDER");
                clearKeyTime("GET_PAY_CODE");
                clearKeyTime("LISTEN_SCAN_BOX_CODE");
                clearKeyTime("USER_PAY_CODE");
              }}
              title="请选择支付方式"
              visible={scanShow}
              footer={<div style={{ height: "2rem" }}></div>}
            >
              <ScanningPayDialog
                OrderAmount={cartStore?.previewData?.order_amount}
                setScanShow={setScanShow}
                type="proOrder"
                orderid=''
              />
            </Modal>
          </>
        );
      },
    },
    {
      key: "会员结账",
      icon: "iconfont iconshouyintai-huiyuanjiezhang",
      value: "vip",
      component: () => {
        return !hasProduct || (!hasDisInfo && !hasSelfInfo) ? (
          <div className="fdc" onClick={justInfo}>
            <div className="icon-box iconfont iconshouyintai-huiyuanjiezhang"></div>
            <div>会员结账</div>
          </div>
        ) : (
          <>
            <div className="fdc" onClick={() => setMemberPayShow(true)}>
              <div className="icon-box iconfont iconshouyintai-huiyuanjiezhang"></div>
              <div>会员结账</div>
            </div>
            <Modal
              style={{
                width: "80rem",
              }}
              centered
              visible={memberPayShow}
              title="会员结账"
              onCancel={() => {
                setMemberPayShow(false);
              }}
              footer={<div style={{ height: "2rem" }}></div>}
            >
              <MemberPayDialog
                setMemberPayShow={setMemberPayShow}
                setLoadingShow={setLoadingShow}
              />
            </Modal>
          </>
        );
      },
    },
    {
      key: "礼品卡结账",
      icon: "iconfont iconshouyintai-xianjinjiezhang",
      value: "coin",
      component: () => {
        return !hasProduct || (!hasDisInfo && !hasSelfInfo) ? (
          <div className="fdc" onClick={justInfo}>
            <div className="icon-box iconfont iconshouyintai-xianjinjiezhang"></div>
            <div>礼品卡结账</div>
          </div>
        ) : (
          <>
            <div className="fdc" onClick={handleGiftCard}>
              <div className="icon-box iconfont iconlipinkajiezhang"></div>
              <div>礼品卡结账</div>
            </div>
            <Modal
              centered
              title="礼品卡结账"
              style={{
                width: "56rem",
              }}
              visible={giftPayShow}
              onCancel={() => {
                setGiftPayShow(false);
              }}
              footer={<div style={{ height: "2rem" }}></div>}
            >
              <GiftCardPayDialog
                setGiftPayShow={setGiftPayShow}
                memberCardShare={memberCardShare}
              />
            </Modal>
          </>
        );
      },
    },
  ];
  <PopUpCus
    w="60rem"
    show={loadingShow}
    setShow={setLoadingShow}
    onClose={() => { }}
    onOpen={() => { }}
  >
    <LoadingDialog />
  </PopUpCus>;

  return (
    <div className="orderList">
      <header className="fb">
        <div className="left-box flex">
          <div className="header-title flex">
            {switchTitle(current)}
            <div>
              <span>(</span>
              <span className="purple">{count}单</span>
              <span>)</span>
            </div>
          </div>
        </div>
        <div className="right-box fc">
          {warnVisible && current !== "shop" && current === "distribute" && (
            <PopUp
              title="填写配送信息"
              show={disShow}
              setShow={setDisShow}
              open={
                <div
                  className={`flex 
                    ${globalStore.selfCheck && current !== "distribute"
                      ? "green"
                      : "warning-info"
                    } 
                      ${globalStore.deliveryCheck && current === "distribute"
                      ? "green"
                      : "warning-info"
                    }`}
                >
                  <div
                    className={`logo iconfont ${globalStore.selfCheck && current !== "distribute"
                      ? "iconshouyintai-yitianxie"
                      : "iconshouyintai-weitianxie"
                      } 
                      ${globalStore.deliveryCheck && current === "distribute"
                        ? "iconshouyintai-yitianxie"
                        : "iconshouyintai-weitianxie"
                      }`}
                  ></div>
                  <div
                    className="flex"
                    style={{ whiteSpace: "nowrap", fontSize: "1.4rem" }}
                  >
                    配送信息
                    {globalStore.deliveryCheck === false && <div>(未填写)</div>}
                    {globalStore.deliveryCheck === true && <div>(已填写)</div>}
                  </div>
                </div>
              }
              onOk={() => {
                disInfoConfirm();
              }}
            >
              <div style={{ width: "60rem" }}>
                <DistributionInfoDialog
                  getDisInfo={getDisInfo}
                  // setShow={setDisShow}
                  showDelivery={showDelivery}
                  autoTime={autoTime}
                  intervalTime={intervalTime}
                />
              </div>
            </PopUp>
          )}

          {warnVisible && current !== "shop" && current === "self" && (
            <PopUp
              title="填写取货信息"
              show={pickShow}
              setShow={setPickShow}
              open={
                <div
                  className={`flex 
              ${globalStore.selfCheck && current !== "distribute"
                      ? "green"
                      : "warning-info"
                    } 
                ${globalStore.deliveryCheck && current === "distribute"
                      ? "green"
                      : "warning-info"
                    }`}
                >
                  <div
                    className={`logo iconfont ${globalStore.selfCheck && current !== "distribute"
                      ? "iconshouyintai-yitianxie"
                      : "iconshouyintai-weitianxie"
                      } 
                ${globalStore.deliveryCheck && current === "distribute"
                        ? "iconshouyintai-yitianxie"
                        : "iconshouyintai-weitianxie"
                      }`}
                  ></div>
                  <div
                    className="flex"
                    style={{ whiteSpace: "nowrap", fontSize: "1.4rem" }}
                  >
                    自提信息
                    {globalStore.selfCheck === false && <div>(未填写)</div>}
                    {globalStore.selfCheck === true && <div>(已填写)</div>}
                  </div>
                </div>
              }
              onOk={() => {
                pickUpInfoConfirm();
              }}
              onClose={() => { }}
            >
              <div style={{ width: "60rem" }}>
                <PickUpInfoDialog
                  getSelfInfo={getSelfInfo}
                  showDelivery={showDelivery}
                  autoTime={autoTime}
                  intervalTime={intervalTime}
                />
              </div>
            </PopUp>
          )}
        </div>
      </header>
      <PullBox maxHeight={style.maxHeight} noRequest={true}>
        <div className="content-box" style={{ paddingBottom: "8rem" }}>
          <ProHeader />
          <MakeProduct />
          <div className="detail-box"></div>
        </div>
      </PullBox>
      <footer>
        <ShowCount />
        <PayMethod payArray={payArray} />
      </footer>
    </div>
  );
};

export default OrderList;
