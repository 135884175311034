import { FC } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import NewMemberInfo from "./newMemberInfo";

import "./index.scss";
import { actions } from "../../../store/global";
import { ModalArr } from "../../../constants/comment";

interface IMemberAddTipsProps {
    show: boolean;
    memberInfo: any;
    addMemberSuccess: boolean; // 开通会员是否成功
    inputValue: string;
    add(value: any): void;
    setScanShow(type: boolean): void; //扫码弹窗
    setCashShow(type: boolean): void; //现金弹窗
    setAddMemberTipsShow(type: boolean): void;
    setRechargeMoney(handle: any): void;
    tipsobj: object;
}

const MemberAddTips: FC<IMemberAddTipsProps> = (props: IMemberAddTipsProps) => {
    const {
        memberInfo,
        addMemberSuccess,
        tipsobj,
        inputValue,
        add,
        setAddMemberTipsShow,
        setScanShow,
        setCashShow,
        setRechargeMoney,
    } = props;
    const dispatch = useDispatch()

    return (
        <div className="member-add-tips">
            <div className="title">会员开通</div>

            <div className="content">
                {addMemberSuccess ? (
                    <div className="success">
                        <div className="logo iconfont iconshouyintai-kaitongchenggong" />
                        <span>开通成功</span>
                    </div>
                ) : (
                    <div className="fair">
                        <div className="logo iconfont iconshouyintai-kaitongshibai" />
                        <span>开通失败</span>
                        <span className="problem">{tipsobj?.code !== 0 ? tipsobj?.msg : ""}</span>
                    </div>
                )}

                {!addMemberSuccess && (
                    <div
                        className="return fb"
                        onClick={() => {
                            dispatch(actions.openModal(ModalArr['memberAdd'].key))
                            setAddMemberTipsShow(false);
                        }}
                    >
                        点击返回
                    </div>
                )}

                {addMemberSuccess && (
                    <NewMemberInfo
                        setMember={add}
                        memberInfo={memberInfo}
                        inputValue={inputValue}
                        setScanShow={setScanShow}
                        setCashShow={setCashShow}
                        setRechargeMoney={setRechargeMoney}
                    />
                )}
            </div>
        </div>
    );
};
export default MemberAddTips;
