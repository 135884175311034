import React, { FC } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import "./index.scss";

interface ShowCountProps {
  info?: any;
}

const ShowCount: FC<ShowCountProps> = (props: ShowCountProps) => {
  const cartStore = useSelector((state) => state.cart, shallowEqual);
  const dispatch = useDispatch();

  const { info } = props;
  const previewData = cartStore?.previewData;

  return (
    <div className="showcount text-3">
      <div className="item-row fb">
        <div className="flex">
          <div>小计</div>
          {/* <div className='count-color'>
            {info ? Number(info.order_amount).toFixed(2) : Number(previewData.order_amount).toFixed(2)}
          </div> */}
        </div>
        <div className="flex">
          <div>总计：</div>
          <div className="count-color">
            {info ? info.sku_count : previewData.product_number}件
            {info?.order_discount_amount ? (
              <span style={{ marginLeft: "2rem" }}>
                ¥
                {(
                  Number(info?.order_amount) +
                  Number(info?.order_discount_amount)
                ).toFixed(2)}
              </span>
            ) : (
              <span />
            )}
          </div>
        </div>
      </div>
      <div className='item-row fb'>
        <div />
        <div className="flex">
          <div>应收：</div>
          <div className="price-color" style={{ color: "#FF3200" }}>
            ¥
            {info
              ? Number(info?.order_amount).toFixed(2)
              : Number(previewData.order_amount).toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowCount;
