/**
 *  订单等API
 */

import request from "../http/index";

// 获取订单列表
export const getOrderList = async (params) => {
    const result = await request({
        method: "post",
        url: "/cashier/order/list",
        data: {
            condition: {
                with_order_detail: params?.with_order_detail || "0",
                with_order_address: params?.with_order_address || "1",
                with_order_discount: params?.with_order_discount || "0",
                with_order_code: params?.with_order_code || "0",
                with_order_fee: params?.with_order_fee || "0",
                with_user: "",
                with_order_self_mention: "",
                with_sequence: "",
                with_image: "",
                date: params.date || "",
            },
            search: {
                source: params.source || '',
                multi_source: params.multi_source,
                status: params?.status || "",
                delivery_type: params?.delivery_type,
                order_type: params?.order_type,
                mobile:
                    params.searchValue !== "" && params.searchValue.length === 11
                        ? params.searchValue
                        : "",
                sequence:
                    params.searchValue !== "" && params.searchValue.length < 5
                        ? params.searchValue
                        : "",
                multi_status: [],
                code: params.code || "",
                order_id: params.order_id || "",
                // area: {
                //     province_id: params?.area?.province_id || "",
                //     city_id: params?.area?.city_id || "",
                //     area_id: params?.area?.area_id || "",
                // },
                pre_delivery_at: {
                    start: params.pre_delivery_at?.start || "",
                    end: params.pre_delivery_at?.end || "",
                },
                pre_pick_up_at: {
                    start: params.pre_pick_up_at?.start || "",
                    end: params.pre_pick_up_at?.end || "",
                },
                pre_make_at: {
                    start: params.pre_make_at?.start || "",
                    end: params.pre_make_at?.end || "",
                },
                // date: {
                //     start: params.date?.start || "",
                //     end: params.date?.end || "",
                // },

                remark: "",
            },
            sort: {
                create_at: params?.create_at || "desc",
                // sequence: "",
                // pre_delivery_at: "",
                // pre_pick_up_at: "",
                // pre_make_at: "",
            },
            page: {
                all: 0,
                total: 1,
                page: params.page || 1,
                page_size: 20,
            },
        },
    });
    return result;
};

// 获取订单详情
export const getOrderDetail = async (params) => {
    const result = await request({
        method: "post",
        url: "/cashier/order/detail",
        data: {
            order_id: params.order_id,
            condition: {
                with_order_detail: "1",
                with_member_card: "1",
                with_order_discount: "1",
                with_order_fee: "1",
                with_order_address: "1",
                with_order_code: "1",
                with_order_self_mention: "1",
                with_shop: "1",
                with_pay_order: "1",
            },
        },
    });
    return result;
};

// 处理订单
export const handleOrder = async (value) => {
    const result = await request({
        method: "post",
        url: "/cashier/order/handle",
        data: {
            order_id: value.order_id,
            action: value.action,
            params: value.params,
        },
    });
    return result;
};

// 获取充值订单列表
export const getPayOrder = async (params) => {
    const result = await request({
        method: "post",
        url: "/cashier/order/recharge/list",
        data: {
            search: {
                order_id: params.order_id.length === 18 ? params.order_id : "",
                mobile: params.mobile.length === 11 ? params.mobile : "",
                date: {
                    start: params.date.start || "",
                    end: params.date.end || "",
                },
            },
            page: {
                all: 0,
                total: 1,
                page: params.page || 1,
                page_size: 20,
            },
        },
    });
    return result;
};

// 获取充值订单详情
export const getPayOrderDetail = async (params) => {
    const result = await request({
        method: "post",
        url: "/cashier/order/recharge/detail",
        data: {
            ...params,
            condition: {
                with_member_card: 1,
                with_pay_order: 1,
                with_user_member_card: 1,
                with_user_member_card_record: 1,
            },
        },
    });
    return result;
};

// 获取订单状态
export const getOrderStatus = async (data) => {
    const result = await request({
        method: "post",
        url: "/cashier/order/status",
        data,
    });
    return result;
};

// 获取订单数量
export const getOrderTotal = async (params) => {
    const result = await request({
        method: "post",
        url: "cashier/order/count",
        data: {
            condition: {
                date: "",
            },
            search: {
                status: params.status,
                order_type: params.order_type || 1,
                delivery_type: params.delivery_type,
                pre_delivery_at: {
                    // 配送
                    start: params.pre_delivery_at?.start || "",
                    end: params.pre_delivery_at?.end || "",
                },
                pre_pick_up_at: {
                    // 自提
                    start: params.pre_pick_up_at?.start || "",
                    end: params.pre_pick_up_at?.end || "",
                },
                pre_make_at: {
                    // 出品
                    start: params.pre_make_at?.start || "",
                    end: params.pre_make_at?.end || "",
                },
                date: {
                    start: "",
                    end: "",
                },
            },
        },
    });
    return result;
};

// 获取礼品卡详情
export const getGiftDetail = async (data) => {
    const result = await request({
        method: "post",
        url: "/cashier/shop/gift-card/detail",
        data,
    });
    return result;
};

// 获取订单统计
export const getOrderSum = async (params) => {
    const result = await request({
        method: "post",
        url: "/cashier/order/statistics",
        data: {
            // "date": params.date || '',
            "key": params.key || ''
          },
    });
    return result;
};
// 退款
export const handleRefundAction = async (order_id) => request({
    method: "post",
    url: "/cashier/order/refund",
    data: { "order_id": order_id },
})
