import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Button } from "zarm";
import { getOrderSum } from '../../services/order';
import { LOGO } from "../../constants/imagePath";
import { setStorageSync } from "../../utils/uitls";
import { formatUrl } from "../../utils/format";
import { OrderTypeString } from "../../constants/order";
import "./index.scss";
import PullBox from "../../global-component/pullBox/pull";

const SumPage = (props: any) => {
    const style = { overflowY: "auto", maxHeight: 'calc(100vh - 15rem)' };
    const globalStore = useSelector((state: any) => state.global, shallowEqual);
    const history = useHistory();
    const orderType = formatUrl()?.type;
    const showOrderType = Boolean(formatUrl()?.type);
    const [current, setCurrent] = useState('shop')
    setStorageSync('orderType', current)

    const [sumList, setSumList] = useState([])

    const [list, setList] = useState([
        {
            title: '线下入账',
            value: '',
            properties: [{
                title: '支付方式1',
                value: ''
            },
            {
                title: '支付方式2',
                value: ''
            },]
        },
        {
            title: '线上入账',
            value: '',
            properties: [{
                title: '支付方式1',
                value: ''
            },
            {
                title: '支付方式2',
                value: ''
            },]
        },
        {
            title: '会员卡抵扣',
            value: ''
        },
        {
            title: '礼品卡抵扣',
            value: ''
        },
    ])

    useEffect(() => {
        const params = {
            date: '',
            key: globalStore.tabItem?.key
        }
        formatUrl()?.type && getOrderSum(params).then((res) => {
            console.log(9090909, res.offline?.list.map((v) => {
                return v.items.map(e => {
                    return {
                        title: v.custom_name + e.custom_item_name,
                        value: '¥' + e.amount
                    }
                })
            }))
            setSumList([{
                title: '总订单数',
                value: res.order_count
            },
            {
                title: '订单流水',
                value: '¥' + res.total_order_amount
            },
            {
                title: '实际入账',
                value: '¥' + res.order_amount
            },
            {
                title: '全部抵扣',
                value: '¥' + res.order_discount_amount
            }])
            setList([
                {
                    title: '线下入账',
                    value: res.offline?.total,
                    properties: res.offline?.list.map((v) => {
                        return v.items.map(e => {
                            return {
                                title: v.text + '-' + e.text,
                                value: '¥' + e.amount
                            }
                        })
                    }).flat()
                },
                {
                    title: '线上入账',
                    value: res.online?.amount,
                    properties: res.online?.items.map((i) => {
                        return {
                            title: i.text,
                            value: '¥' + i.amount
                        }
                    })
                },
                {
                    title: '会员卡抵扣',
                    value: res.membercard_order_amount
                },
                {
                    title: '礼品卡抵扣',
                    value: res.gift_card_order_amount
                },
            ])
        })
    }, [formatUrl()?.type])

    return (
        <PullBox
            maxHeight={style.maxHeight}
            noRequest={true}
        >
            <div className="sumPage">
                {
                    showOrderType &&
                    <div className="sum-section">
                        <div className="order-type text-3">{OrderTypeString[orderType]}</div>
                    </div>
                }
                {
                    showOrderType && <div className="sum-section">
                        {sumList && sumList.map((v) => {
                            return <div className="fb order-type-1">
                                <div className="title">{v.title}</div>
                                <div className="value">{v.value}</div>
                            </div>
                        })}
                    </div>
                }
                {
                    showOrderType && <div className="sum-section">
                        {list && list.map((v, index) => {
                            return <div className="order-type-0">
                                <div className="order-type-2">
                                    <div className="fb first-type">
                                        <div className="title text-3">{v.title}</div>
                                        <div className="value flex">
                                            <div>总计</div>
                                            <div className="text-red">¥{v.value}</div>
                                        </div>
                                    </div>
                                    {v.properties && v.properties.map((i) => {
                                        return <div className="fb order-type-3 text-6">
                                            <div className="title">{i.title}</div>
                                            <div className="value">{i.value}</div>
                                        </div>
                                    })}
                                </div>
                                {index !== 3 && <div className="line-row"></div>}
                            </div>
                        })}
                    </div>
                }
            </div>
        </PullBox>
    );
}

export default SumPage;
