/**
 *  支付，付款API
 */

import request from "../http/index";

// 线下支付
export const payMethodList = async (data) => {
  const result = await request({
    method: "post",
    url: "/cashier/trade/pay-method",
    data,
  });
  return result;
};



// 充值余额
export const payVip = async (data) => {
  const result = await request({
    method: "post",
    url: "/cashier/trade/recharge/make",
    data,
  });
  return result;
};

// 预下单
export const previewOrder = async (params) => {
  const result = await request({
    method: "post",
    url: "/cashier/trade/preview",
    data: {
      shop_id: "1",
      config: {
        delivery_type: params.delivery_type,
        pay_type: params.pay_type,
        pay_method: params.pay_method,
        pay_channel: params.pay_channel,
        order_type: params.order_type,
        mobile: params.mobile,
        gift_card_id: params.gift_card_id,
      },
      order: {
        pre_make_at: "0",
        pre_delivery_at: "",
        remark: "",
        delivery_remark: "",
        order_amount: 0,
      },
      self_mention: {
        name: "",
        mobile: "",
        date: params.date || "",
      },
      address: {
        mobile: "",
        contact_name: "",
        address: "",
        number: "",
        location: "",
      },
      sku_items: params.sku_items,
    },
  });
  return result;
};

// 下单
export const makeOrder = async (params) => {
  let paramscopy = {
    shop_id: "1",
    // 支付配置
    config: {
      order_type: params.order_type,
      delivery_type: params.delivery_type,
      pay_type: params.pay_type || "",
      pay_method: params.pay_method,
      pay_channel: params.pay_channel,
      mobile: params.mobile || "",
      custom_item_id: params.custom_item_id || '',
      gift_card_id: params.gift_card_id,
    },
    // 商品信息
    sku_items: params.sku_items,
    // {
    //   "sku_id": "string",
    //   "count": "integer",
    //   "product_name": "string"
    // }
    //订单信息
    order: {
      pre_make_at: params.pre_make_at || 0,
      pre_delivery_at: params.pre_delivery_at || "",
      pre_pick_up_at: params.pre_pick_up_at || "",
      remark: params.remark || "",
      delivery_remark: params.delivery_remark || "",
    },
    // 自提信息
    self_mention: {
      name: params.name || "",
      mobile: params.phone || "",
      date: params.pre_pick_up_at || "",
    },
    // 配送地址联系人信息
    address: {
      mobile: params.phone || "",
      contact_name: params.contact_name || "",
      address: params.address || "",
      number: params.number,
      location: params.location || "",
      province_id: params.province_id || "",
      city_id: params.city_id || "",
      area_id: params.area_id || "",
    },
  };
  // if(getStorageSync('orderType') === 'shop') {
  //   // delete paramscopy.order
  //   // delete paramscopy.self_mention
  //   // delete paramscopy.address
  //   // paramscopy = {...paramscopy, order: {"pre_make_at": params.pre_make_at || '',}}
  // }
  // else if (getStorageSync('orderType') === 'distribute') {
  //   delete paramscopy.order
  // }
  // else if (getStorageSync('orderType') === 'self') {
  //   delete paramscopy.self_mention
  // }
  const result = await request({
    method: "post",
    url: "/cashier/trade/make",
    data: paramscopy,
  });
  return result;
};

// 获取地址Api
export const getDeliveryAreaList = async (data) => {
  const result = await request({
    method: "post",
    url: "cashier/delivery-area/list",
    data: {
      ...data,
      select_type: 1
    },
  });
  return result;
};

// 根据id获取下面的市区
export const getDeliveryAreaById = async (params) => {
  const result = await request({
    method: "post",
    url: "cashier/delivery-area/children-list",
    data: {
      search: {
        province_id: params?.province_id || "",
        city_id: params?.city_id || ""
      }
    }
  });
  return result;
};

// 过滤店铺设置的配送时区
export const filterDeliveryAreaList = async (data) => {
  const result = await request({
    method: "post",
    url: "cashier/delivery-area/filter",
    data,
  });
  return result;
};

// 交易方式
export const getPayMethod = async (data) => {
  const result = await request({
    method: "post",
    url: "cashier/trade/pay-method",
    data,
  });
  return result;
};
