import { FC, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { getOrderStatus } from "../../../services/order";
import { formatUrl } from "../../../utils/format";
import { IOrderTab, IOrderTab_cate } from "../../../interfaces/cate";
import { ALL, ORDER_TYPE } from "../../../constants/order";
import { getSetting } from "../../../services/login";
import { getStorageSync, setStorageSync } from "../../../utils/uitls";
import { actions as global } from "../../../store/global";

import "./index.scss";


interface IOderTabProps {
  handleFlag(): void
  changeOrderType(orderType: any): void;
  changeOrderStatus(orderType: any): void;
  handleCurrent(id: string): void;
}

const OrderTab: FC<IOderTabProps> = (props: IOderTabProps) => {
  const { changeOrderType, changeOrderStatus, handleFlag, handleCurrent } = props
  const history = useHistory();
  const dispatch = useDispatch();
  const orderStatus = useSelector(
    (state: any) => state.global?.curOrderStatus,
    shallowEqual
  );
  const [array, setArray] = useState<IOrderTab[]>([]);
  const arrayTwo = useSelector(
    (state: any) => state.global?.orderStatusList,
    shallowEqual
  );
  const [current, setCurrent] = useState<any>(formatUrl()?.type);

  useEffect(() => {
    setCurrent(formatUrl()?.type)
    handleFlag()
  }, [formatUrl()?.type])

  useEffect(() => {
    getOrderStatus().then(res => {
      setArray(res)
    })
  }, [])

  const changeType = (value: any) => {
    console.log('getTabItem', value)
    changeOrderType(ORDER_TYPE[value.text].number);
    dispatch(global.getCurOrderType(ORDER_TYPE[value.text].value))
    dispatch(global.getTabItem(value))
    dispatch(global.getOrderStatusList(value.item))
    setCurrent(ORDER_TYPE[value.text].value)
    dispatch(global.getCurOrderStatus(ALL));
    handleCurrent('')
  };

  const changeStatus = (value: IOrderTab_cate) => {
    setStorageSync('OrderTabStatus', value.status)
    dispatch(global.getCurOrderStatus(value.text));
    changeOrderStatus(value.status);
  };



  return (
    <div className="order-tabs fb">
      <div>
        <div className="left-section flex" >
          {array?.map((item, index) => {
            return (
              <div
                key={`${index} parent`}
                className={`item-box fc ${current === ORDER_TYPE[item.text]?.value ? "active-item" : ""
                  }`}
                onClick={() => {
                  changeType(item);
                  history.push(`/makeorder?type=${ORDER_TYPE[item.text]?.value}`);
                }}
              >
                <div>{item.text}</div>
              </div>
            );
          })}
        </div>
        <div className="left-section flex" style={{ marginTop: '1rem' }}>
          {arrayTwo?.map((item: any, index: number) => {
            return (
              <div
                key={`${index} child`}
                className={`item-box fc ${orderStatus === item.text ? "active-item" : ""
                  }`}
                onClick={() => {
                  changeStatus(item);
                }}
              >
                <div>{item.text}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OrderTab;
