import React, { useState, useEffect, memo } from "react";
import { Modal } from "@douyinfe/semi-ui";
import { message } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  getVipCardDetail,
  rechargeVipBalance,
} from "../../../services/vipcard";
import { clearKeyTime } from "../../../utils/interval";
import { getStorageSync, setStorageSync } from "../../../utils/uitls";
import { actions } from "../../../store/global";
import { ModalArr } from "../../../constants/comment";

import PopUpCus from "../../../global-component/pop-up/popup_custom";
import MemberManageDialog from "../../../dialog/memberManage";
import MemberRecharge from "../../../dialog/memberManage/memberRecharge";
import MemberAdd from "../../../dialog/memberManage/member_add";
import MemberAddTips from "../../../dialog/memberManage/memberAddTips";
import ScanningPayDialog from "../../../dialog/payDialog/scaningPayDialog";
import PayMethodDialog from "../../../dialog/payDialog/payMethodDialog";

import "./index.scss";

let money = {};

const memberManage = memo(() => {
  const [scanShow, setScanShow] = useState(false);
  const [cashShow, setCashShow] = useState(false);
  const [addMemberTipsShow, setAddMemberTipsShow] = useState(false);
  const [addMemberSuccess, setAddMemberSuccess] = useState(false);
  const [memberNumber, setMemberNumber] = useState<string>("");
  const [inputValue, setInputValue] = useState("");
  const [sendValue, setSendValue] = useState("");
  const [memberInfo, setMemberInfo] = useState<any>({});
  const [showMemberInfo, setShowMemberInfo] = useState<boolean>(false);
  const [tips, setTips] = useState({});
  const [remark, setRemark] = useState("");
  const dispatch = useDispatch();
  const globalStore = useSelector((state) => state.global, shallowEqual);


  useEffect(() => {
    money = {};
    setMemberNumber("");
    setInputValue("");
    setMemberInfo({});
    setShowMemberInfo(false);
  }, [globalStore.modal]);

  const getInputValue = (value: any) => {
    setRemark(Number(value).toFixed(2));
  };
  const onOutoutPrice = (price: string) => {
    money = price;
  };

  const handleAddMember = (res: boolean) => {
    setAddMemberSuccess(res);
  };
  const getMemberNumber = (phone: string) => {
    setMemberNumber(phone);
  };

  const getRechargeMoney = (value: any) => {
    setInputValue(value);
  };

  const getMemberDetail = () => {
    const params = {
      mobile: memberNumber || memberInfo?.mobile,
    };
    getVipCardDetail(params).then((res) => {
      localStorage.setItem("vip-data", JSON.stringify(res));
      if (res) {
        setMemberInfo(res);
        setShowMemberInfo(true);
      } else {
        setShowMemberInfo(false);
      }
    });
  };

  const add = (params: any) => {
    setMemberInfo({ ...memberInfo, price: inputValue });
  };

  // const cashHandler = () => {
  //   if (money?.remain < 0 || money.actualPay === "") {
  //     message.error("请输入大于充值的金额");
  //     return false;
  //   }
  //   const params = {
  //     user_id: memberInfo?.user_id || "0",
  //     price: inputValue,
  //     user_member_card_id: memberInfo?.user_member_card_id,
  //     mobile: memberInfo?.mobile,
  //     pay_type: 1,
  //     pay_method: 51,
  //     pay_channel: 0,
  //     remark: remark.toString(),
  //   };
  //   rechargeVipBalance(params).then((res) => {
  //     setSendValue("");
  //     getRechargeMoney("");
  //     getMemberDetail();
  //     setCashShow(false);
  //     if (res) {
  //       message.success(res.pay_status_msg);
  //       setAddMemberTipsShow(false);
  //     } else {
  //       message.error("支付失败");
  //       setCashShow(false);
  //     }
  //   });
  // };

  const offlineRecharge = () => {
    if (money?.remain < 0 || money.actualPay === "") {
      message.error("请输入大于充值的金额");
      return false;
    }
    if (!getStorageSync('OFFLINE_PAY')) {
      message.error("请选择收款方式");
      return;
    }
    const params = {
      user_id: memberInfo?.user_id || "0",
      price: inputValue,
      user_member_card_id: memberInfo?.user_member_card_id,
      mobile: memberInfo?.mobile,
      pay_method: getStorageSync('OFFLINE_PAY_METHOD').pay_method,
      pay_channel: getStorageSync('OFFLINE_PAY_METHOD').pay_channel,
      pay_type: getStorageSync('OFFLINE_PAY_METHOD').pay_type,
      custom_item_id: getStorageSync("OFFLINE_PAY_METHOD")?.custom_item_id,
      remark: remark.toString(),
    };
    rechargeVipBalance(params).then((res) => {
      setSendValue("");
      getRechargeMoney("");
      getMemberDetail();
      setCashShow(false);
      if (res) {
        message.success(res.pay_status_msg);
        setAddMemberTipsShow(false);
      } else {
        message.error("支付失败");
        setCashShow(false);
      }
    });
  };


  return (
    <div className="memberManage flex">
      <header className="fb">
        <div className="info flex">
          <PopUpCus
            w="50rem"
            position="absolute"
            top="8rem"
            right="2rem"
            show={
              globalStore.modal.findIndex((e: any) => {
                return e === "memberManage";
              }) > -1
            }
            setShow={() => {
              dispatch(actions.openModal(ModalArr["memberManage"].key));
              dispatch(actions.curModal(ModalArr["memberManage"].key));
            }}
            open={
              <div
                className="member-logo fc"
              >
                <div className="logo iconfont iconshouyintai-huiyuan"></div>
                <div className="vip">会员</div>
              </div>
            }
            onClose={() => { }}
            onOpen={() => { }}
          >
            <MemberManageDialog

            />
          </PopUpCus>

          <PopUpCus
            w="56rem"
            show={globalStore.modal.findIndex((e: any) => {
              return e === "memberRecharge";
            }) > -1}
            setShow={() => {
              dispatch(actions.openModal(ModalArr["memberRecharge"].key));
              dispatch(actions.curModal(ModalArr["memberRecharge"].key));
            }}
            onClose={() => { }}
            onOpen={() => { }}
          >
            <MemberRecharge
              memberInfo={memberInfo}
              memberNumber={memberNumber}
              inputValue={inputValue}
              sendValue={sendValue}
              showMemberInfo={showMemberInfo}
              add={add}
              getMemberNumber={getMemberNumber}
              setScanShow={setScanShow}
              setCashShow={setCashShow}
              getRechargeMoney={getRechargeMoney}
              getMemberDetail={getMemberDetail}
            />
          </PopUpCus>

          <PopUpCus
            w="60rem"
            show={globalStore.modal.findIndex((e: any) => {
              return e === "memberAdd";
            }) > -1}
            setShow={() => {
              dispatch(actions.openModal(ModalArr["memberAdd"].key));
              dispatch(actions.curModal(ModalArr["memberAdd"].key));
            }}
            onClose={() => { }}
            onOpen={() => { }}
          >
            <MemberAdd
              memberNumber={memberNumber}
              setAddMemberTipsShow={setAddMemberTipsShow}
              handleAddMember={handleAddMember}
              getMemberNumber={getMemberNumber}
              getMemberDetail={getMemberDetail}
              getMemberMsg={(value: any) => {
                setTips(value);
              }}
            />
          </PopUpCus>

          <PopUpCus
            w="56rem"
            show={addMemberTipsShow}
            setShow={setAddMemberTipsShow}
            onClose={() => { }}
            onOpen={() => { }}
          >
            <MemberAddTips
              add={add}
              tipsobj={tips}
              show={addMemberTipsShow}
              memberInfo={memberInfo}
              addMemberSuccess={addMemberSuccess}
              inputValue={inputValue}
              setRechargeMoney={getRechargeMoney}
              setAddMemberTipsShow={setAddMemberTipsShow}
              setScanShow={setScanShow}
              setCashShow={setCashShow}
            />
          </PopUpCus>



          <Modal
            centered
            visible={scanShow}
            style={{
              width: "70rem",
            }}
            onCancel={() => {
              setScanShow(false);
              getMemberDetail();
              clearKeyTime("SEARCH_ORDER");
              clearKeyTime("GET_PAY_CODE");
              clearKeyTime("LISTEN_SCAN_BOX_CODE");
              clearKeyTime("USER_PAY_CODE");
            }}
            title="请点击按钮后扫码支付"
            footer={<div style={{ height: "2rem" }}></div>}
          >
            <ScanningPayDialog
              setScanShow={setScanShow}
              memberInfo={memberInfo}
              OrderAmount={inputValue}
              getMemberDetail={getMemberDetail}
              orderid=''
              type="vipRecharge"
            />
          </Modal>

          <Modal
            centered
            visible={cashShow}
            style={{
              width: "60rem",
            }}
            title="线下充值"
            okText="收款"
            onOk={() => {
              offlineRecharge()
            }}
            onCancel={() => {
              setCashShow(false);
              getMemberDetail();
            }}
          >
            <PayMethodDialog
              memberInfo={memberInfo}
              inputValue={inputValue}
              getInputValue={(value: any) => {
                setRemark(Number(value).toFixed(2));
              }}
              onOutoutPrice={(price: any) => {
                money = price;
              }}
            />
          </Modal>
        </div>
      </header>
    </div>
  );
});
export default memberManage;
