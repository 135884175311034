import { FC } from "react";

import "./index.scss";

interface OrderNotesProps {
  info: any;
}

const OrderNotes: FC<OrderNotesProps> = (props: OrderNotesProps) => {
  const { info } = props;

  const getCount = (arr: any, keyName: any) => {
    let total = 0;
    total = arr?.reduce(function (pre: any, cur: any) {
      return cur[keyName] ? (pre + cur[keyName]) : pre;
    }, 0);
    return total;
  }

  const getRefundMsg = (status: number): string => {
    switch(status){
      case 1: 
      
      case 2: return '已收款项正在退款中';
      
      case 3: return '已收款项已原路返回';
      
      case 4: return '订单退款失败，请确认收款账户余额后再次提交';

      default: return '';
    }
  }


  return (
    <div className="orderNotes">
      <div className="title flex">
        <div className="vertical-line"></div>
        <div className="title-text flex">小计</div>
      </div>

      <div className="notePadding fb item-row">
        <div>商品数量：</div>
        <div>{getCount(info?.order_detail, 'sku_count')}件</div>
      </div>
      <div className="notePadding fb item-row">
        <div>订单金额：</div>
        {info?.order_discount_amount ? (
          <div style={{ marginLeft: "2rem" }}>
            ¥
            {(
              Number(info?.order_amount) + Number(info?.order_discount_amount)
            ).toFixed(2)}
          </div>
        ) : (
          <div />
        )}
      </div>
      <div className="borderTop" />
      {Boolean(Number(info?.order_discount_amount)) && (
        <div className="notePadding fb item-row ">
          <div>累计优惠：</div>
          <div>¥{info?.order_discount_amount}</div>
        </div>
      )}
      {info?.order_discount?.map((item: any) => {
        return (
          <div key={item.order_discount_id} className="notePadding fb item-row">
            <div>{item.detail}:</div>
            <div>¥{item.amount}</div>
          </div>
        );
      })}
      <div className="borderTop" />
      <div className="notePadding fb item-row ">
        <div className="actualPay">实收：</div>
        <div className="price-color">¥{ info.refund_status !== 0 ? '0.00' : info?.order_amount}</div>
      </div>
      {
        info.refund_status !== 0 && <div className="refund-msg">{getRefundMsg(info.refund_process_status)}</div>
      }
      
    </div>
  );
};

export default OrderNotes;
