import request from '../http/index'

// 登录API
export const login = async (data) => {
    const result = await request({
        method: 'post',
        url: '/cashier/login',
        data,
    })
    return result;
}

// 注销登录
export const loginOut = async (data) => {
    const result = await request({
        method: 'post',
        url: '/cashier/logout',
        data,
    })
    return result;
}

// 刷新token
export const refreshToken = async (data) => {
    const result = await request({
        method: 'post',
        url: '/cashier/refreshToken',
        data,
    })
    return result;
}

// 域名API
export const domainApi = async (data) => {
    const result = await request({
        method: 'post',
        url: '/cashier/domain',
        data,
    })
    return result;
}

// 获取配置

export const getSetting = async (data) => {
    const result = await request({
        method: 'post',
        url: '/cashier/shop/setting/list',
        data,
    })
    return result;
}

// 获取店铺配置

export const getShopSetting = async (data) => {
    const result = await request({
        method: 'post',
        url: '/cashier/shop/detail',
        data,
    })
    return result;
}

// api/v1/cashier/shop/detail



