/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
// import * as actionType from './contants'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { login } from '../services/login';
import { getStorageSync } from '../utils/uitls';
/**
 * 初始化数据
 */
const initialState = {
  account: '',
  token: '',
  isLogin: Boolean(localStorage.getItem('Authorization')),
  payObj: JSON.parse(localStorage.getItem('payobj')) || {},
  deliveryCheck: Boolean(getStorageSync('distribute-info')),
  selfCheck: Boolean(getStorageSync('self-info')),
  config: [],
  modal: [],
  curModal: '',
  curOrderType: '',
  tabItem: {},
  curOrderStatus: '',
  orderStatusList: [],
  rightListRefresh: '', // 右侧列表刷新 每次需要更新时 left_refresh_1 避免第一次刷新两次
}
/**
 * reducers
 */

const reducers = {
  getConfig: (state, action) => {
    state.config = action.payload;
  },
  getTabItem: (state, action) => {
    state.tabItem = action.payload;
  },
  getAccount: (state, action) => {
    state.account = action.payload;
  },
  getToken: (state, action) => {
    state.token = action.payload;
  },
  changeIsLogin: (state, action) => {
    state.isLogin = action.payload;
  },
  changePayObj: (state, action) => {
    state.payObj = action.payload;
  },
  getDeliveryCheck: (state, action) => {
    state.deliveryCheck = action.payload;
  },
  getSelfCheck: (state, action) => {
    state.selfCheck = action.payload;
  },
  closeModal: (state, action) => {
    const index = state.modal.findIndex(e => e === action.payload);
    const newModalArr = JSON.parse(JSON.stringify(state.modal))
    if (index > -1) {
      newModalArr.splice(index, 1);
      state.modal = newModalArr
    }
  },
  openModal: (state, action) => {
    const index = action.payload;
    let newModalArr = [...state.modal]
    if (newModalArr.includes(index) === false) {
      newModalArr.push(index)
      state.modal = newModalArr
    }
  },
  curModal: (state, action) => {
    const index = action.payload
    state.curModal = index
  },
  getCurOrderType: (state, action) => {
    const curOrderType = action.payload
    state.curOrderType = curOrderType
  },
  getCurOrderStatus: (state, action) => {
    const curOrderStatus = action.payload
    state.curOrderStatus = curOrderStatus
  },
  getOrderStatusList: (state, action) => {
    const orderStatusList = action.payload
    state.orderStatusList = orderStatusList

  },
  changeRightListRefresh:(state, action) => {
    console.log('触发 changeRightListRefresh');
    state.rightListRefresh = `left_refresh_${new Date().getTime()}`;
  }
}

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers,
})

export const actions = {
  ...globalSlice.actions,
};

export default globalSlice.reducer;