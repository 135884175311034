import { FC, useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";

import "./index.scss";

interface itemValue {
  key: string;
  value: string | number;
}
interface PayCardProps {
  obj?: any;
}

const PayCard: FC<PayCardProps> = (props: PayCardProps) => {
  const globalStore = useSelector((state: any) => state.global, shallowEqual);

  const [objarray, setObjarray] = useState<itemValue[]>([]);

  useEffect(() => {
    const { obj } = props;
    setObjarray(obj);
  }, [props]);

  return (
    <div className="paycard fd">
      <div className="title-box fb">
        <div className="row-one flex text-3">
          <div className="icon iconfont iconshouyintai-kaitongmingxi"></div>
          <div>开通明细</div>
        </div>
        {/* <div className="flex">
          <div className='flex total-one'>
            <div className='text-3'>
              总计：
            </div>
            <div className='color-red'>
              ¥900
            </div>
          </div>
          <div className='flex total-two'>
            <div className='text-3'>
              实收：
            </div>
            <div className='color-red'>
              ¥1000
            </div>
          </div>
        </div> */}
      </div>
      <div className="fb" style={{ padding: "1.5rem" }}>
        {objarray.map((item, index) => {
          return (
            <div className="item-row  text-3 fd">
              <div className="title-key">{item.key}</div>
              <div
                className={`title-value ${(item.key === "实际到账" || item.key === "实付") &&
                  (globalStore.payObj.pay_status === 5 ||
                    globalStore.payObj.pay_status === 2)
                  ? "text-line"
                  : ""
                  }`}
              >
                {item.value}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PayCard;
