import { FC, useState, useEffect } from "react";
import { Input } from "@douyinfe/semi-ui";
import { Select } from "antd";
import { DatePicker } from "@douyinfe/semi-ui";
import dayjs from "dayjs";
import { hideLoading, showLoading } from "../../../utils/Toast";
import {
    getDeliveryAreaById,
    getDeliveryAreaList,
} from "../../../services/payment";
import { getStorageSync } from "../../../utils/uitls";
import { getNumber } from "../../../utils/interval";

import "./index.scss";

const { Option } = Select;
interface IDistributionInfoDialogProps {
    showDelivery: boolean;
    autoTime: boolean;
    intervalTime: number;
    getDisInfo(info: any): void;
}

// 配送信息弹窗
const DistributionInfoDialog: FC<IDistributionInfoDialogProps> = (
    props: IDistributionInfoDialogProps
) => {
    const { getDisInfo, showDelivery, autoTime, intervalTime } =
        props;
    const defaultDisInfo = getStorageSync("distribute-info") || {};
    const [addressList, setAddressList] = useState([]); // 列表信息
    const [disName, setDisName] = useState(defaultDisInfo?.disName); //配送信息收货人
    const [disNumber, setDisNumber] = useState(defaultDisInfo?.disNumber); //配送信息收货人电话
    const [disDetailAddress, setDisDetailAddress] = useState(
        defaultDisInfo?.disDetailAddress
    ); //配送详细地址
    const [disGoodsRemark, setDisGoodsRemark] = useState(
        defaultDisInfo?.disGoodsRemark
    ); //配送商品备注
    const [disRemark, setDisRemark] = useState(defaultDisInfo?.disRemark); //配送备注
    // 设置选择列表
    const [provinceList, setProvinceList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [areaList, setAreaList] = useState([]);

    const [provinceId, setProvinceId] = useState(
        defaultDisInfo?.disAddressInfo?.province_id || undefined
    );
    const [cityId, setCityId] = useState(
        defaultDisInfo?.disAddressInfo?.city_id || undefined
    );
    const [areaId, setAreaId] = useState(
        defaultDisInfo?.disAddressInfo?.area_id || undefined
    );
    const [id, setId] = useState(
        defaultDisInfo?.disAddressInfo?.preobj?.shop_delivery_area_id || undefined
    );
    const [select, setSelect] = useState(
        defaultDisInfo?.disAddressInfo?.preobj || {}
    );
    const [timeOpen, setTimeOpen] = useState(false);
    const [makeOpen, setMakeOpen] = useState(false);
    const [isChange, setIsChange] = useState(false);

    const [disTime, setDisTime] = useState(
        new Date()
        /*  Boolean(defaultDisInfo?.disTime)
             ? new Date(defaultDisInfo?.disTime * 1000) 
             : new Date() */
    ); // 配送信息时间
    const [makeTime, setMakeTime] = useState(
        new Date()
        /*  Boolean(defaultDisInfo?.makeTime)
             ? new Date(defaultDisInfo?.disTime * 1000)
             : new Date() */
    )

    const disAddressInfo = {
        area_id: areaId || select.area_id,
        city_id: cityId || select.city_id,
        province_id: provinceId || select.province_id,
        name: select.name,
        preobj: select,
    };

    useEffect(() => {
        if (Boolean(defaultDisInfo?.disTime) && Boolean(defaultDisInfo?.makeTime)) {
            setDisTime(new Date(defaultDisInfo?.disTime * 1000))
            setMakeTime(new Date(defaultDisInfo?.makeTime * 1000))
        }
        else {
            if (showDelivery) {
                setMakeTime((Number(disTime) - (intervalTime * 1000)))
                setDisTime(disTime)
            }
            else if (!showDelivery) {
                setMakeTime(makeTime)
                setDisTime((Number(makeTime) - (intervalTime * 1000)))
            }
        }
    }, [])

    useEffect(() => {
        getDeliveryAreaList({}).then((res: any) => {
            if (res.list.length !== 0) {
                setAddressList(res?.list);
            } else {
                getList("", "");
            }
        });
    }, []);

    const getList = (province_id: any, city_id: any) => {
        const params = {
            province_id,
            city_id,
        };
        getDeliveryAreaById(params).then((res) => {
            if (Number(params.province_id) === 0 && Number(params.city_id) === 0) {
                /* 设置省 */
                setProvinceList(
                    res.list.map((item: any) => {
                        return { id: item.id.toString(), name: item.name };
                    })
                );
            } else if (
                Number(params.province_id) !== 0 &&
                Number(params.city_id) === 0
            ) {
                /* 设置市 */
                setCityList(
                    res.list.map((item: any) => {
                        return { id: item.id.toString(), name: item.name };
                    })
                );
            } else if (
                Number(params.province_id) !== 0 &&
                Number(params.city_id) !== 0
            ) {
                /* 设置区 */
                setAreaList(
                    res.list.map((item: any) => {
                        return { id: item.id.toString(), name: item.name };
                    })
                );
            }
        });
    };

    useEffect(async () => {

        showLoading();
        clearHandler();
        if (defaultDisInfo?.disAddressInfo?.preobj) {
            getList(defaultDisInfo?.disAddressInfo?.province_id, "");
            getList(
                defaultDisInfo?.disAddressInfo?.province_id,
                defaultDisInfo?.disAddressInfo?.city_id
            );
            const data1 = await getDeliveryAreaById(
                defaultDisInfo?.disAddressInfo?.province_id,
                ""
            );
            const data2 = await getDeliveryAreaById(
                defaultDisInfo?.disAddressInfo?.province_id,
                defaultDisInfo?.disAddressInfo?.city_id
            );
            console.log("data1", data1, "data2", data2);
            if (data1.length !== 0 && data2.length !== 0) {
                hideLoading();
                if (!isChange) {
                    setAreaId(defaultDisInfo?.disAddressInfo?.area_id);
                    setProvinceId(defaultDisInfo?.disAddressInfo?.province_id);
                    setCityId(defaultDisInfo?.disAddressInfo?.city_id);
                }
            }
        }
    }, [select]);

    const clearHandler = () => {
        setProvinceList([]);
        setCityList([]);
        setAreaList([]);
        setProvinceId(undefined);
        setCityId(undefined);
        setAreaId(undefined);
    };

    const cleanByType = (type) => {
        if (type === "city") {
            setCityId(undefined);
            setCityList([]);
        } else if (type === "area") {
            setAreaId(undefined);
            setAreaList([]);
        }
    };

    // 点击选择第一层全地区
    const switchAddress = async (value: any, opt: any) => {
        if (opt.selectRow.shop_delivery_area_id === select.shop_delivery_area_id) return;
        await clearHandler();
        setIsChange(true)
        setTimeout(() => {
            setProvinceId(undefined);
            setCityId(undefined);
            setAreaId(undefined);
            setProvinceList([]);
            setCityList([]);
            setAreaList([]);
            setSelect(opt.selectRow);
            setId(opt.selectRow.shop_delivery_area_id);
            setProvinceId(opt.selectRow?.province_id?.toString());
            setAreaId(
                opt.selectRow.area_id !== 0 ? opt.selectRow.area_id?.toString() : undefined
            );
            setCityId(
                opt.selectRow.area_id !== 0 ? opt.selectRow.city_id?.toString() : undefined
            );

            if (opt.selectRow.area_id === 0) {
                /* 市区都未选， 区未选 */
                if (opt.selectRow.city_id === 0) {
                    /* 市区都未选 */
                    getList(opt.selectRow.province_id, "");
                } else {
                    /* 区未选 */
                    getList(
                        opt.selectRow.province_id,
                        opt.selectRow.city_id
                    ); /* 获取区 */
                }
            } else {
                /* 省市区都已经选择 */
                // getList(opt.selectRow.province_id, '') /* 获取区 */
                // getList(opt.selectRow.province_id, opt.selectRow.city_id) /* 获取市 */
            }
        });
    };

    // 点击选择第二层地区
    const switchProvince = (value: any, opt: any) => {
        if (opt === "reverse") {
            cleanByType("city");
            cleanByType("area");
            setProvinceId(value);
            getList(value, "");
        } else {
            setProvinceId(opt.key);
        }
    };

    // 点击选择第三层地区
    const switchCity = (value: any, opt: any, selectrow: any) => {
        console.log(
            "selectselectselectselect9999",
            select,
            "selectrowselectrow",
            selectrow
        );
        if (opt === "reverse") {
            cleanByType("area");
            setCityId(value);
            getList(provinceId, value);
        } else {
            setAreaList([]);
            setAreaId(undefined);
            getList(select?.province_id, selectrow?.selectRow?.id);
            setCityId(selectrow?.selectRow.id);
        }
    };

    // 点击选择第四层地区
    const switchArea = (value: any, opt: any, selectrow: any) => {
        console.log("selectrow", selectrow);
        if (opt === "reverse") {
            setAreaId(value);
        } else {
            setAreaId(selectrow?.selectRow.id);
        }
    };

    useEffect(() => {
        // console.log('disTimedisTimedisTime', disTime)
        // console.log('makeTimemakeTimemakeTimemakeTime', makeTime)
        const info = {
            disAddressInfo,
            // disTime: dayjs(disTime).unix(),
            // makeTime: dayjs(makeTime).unix(),
            disTime: dayjs(!showDelivery ? Number(makeTime) + intervalTime * 1000 : disTime).unix(),
            makeTime: dayjs(
                !showDelivery ? makeTime : Number(disTime) - intervalTime * 1000 
            ).unix(),
            disName,
            disNumber,
            disDetailAddress,
            disGoodsRemark,
            disRemark,
        };
        // debugger;
        getDisInfo(info);
    }, [
        provinceId,
        cityId,
        areaId,
        disTime,
        makeTime,
        disName,
        disNumber,
        disDetailAddress,
        disGoodsRemark,
        disRemark,
    ]);

    return (
        <div className="distributionInfo">
            <div className="disAddress">
                <div className="title">
                    配送地区<span className="mustInput">*</span>
                </div>
                <div className="dis-addressAll flex">
                    {addressList.length !== 0 && (
                        <div
                            className="dis-address"
                            style={{ width: "40%" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Select
                                value={id}
                                placeholder="配送地区"
                                disabled={false}
                                bordered={false}
                                onSelect={switchAddress}
                            >
                                {addressList.map((item: any, index) => {
                                    return (
                                        <Option
                                            value={item.shop_delivery_area_id}
                                            key={index}
                                            selectRow={item}
                                        >
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    )}
                    {!(Number(select.province_id) !== 0) && addressList.length !== 0 && (
                        <div
                            className="dis-address"
                            style={{ width: "30%" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Select
                                disabled={Number(select.province_id) !== 0} // TODO 判断是否可以修改
                                value={provinceId}
                                onSelect={(value) => {
                                    switchProvince(value, "first");
                                }}
                                placeholder="请选择省"
                                bordered={false}
                            >
                                {provinceList.map((item: any) => {
                                    return (
                                        <Option value={item.id} key={item.id} selectRow={item}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    )}
                    {!(Number(select.city_id) !== 0) && addressList.length !== 0 && (
                        <div
                            className="dis-address"
                            style={{ width: "30%" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Select
                                // disabled={Number(select.city_id) !== 0} // TODO 判断是否可以修改
                                value={cityId}
                                onSelect={(value, opt) => {
                                    switchCity(value, "first", opt);
                                }}
                                placeholder="请选择市"
                                bordered={false}
                            >
                                {cityList.map((item: any) => {
                                    return (
                                        <Option value={item.id} key={item.id} selectRow={item}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    )}
                    {!(Number(select.area_id) !== 0) && addressList.length !== 0 && (
                        <div
                            className="dis-address"
                            style={{ width: "40%" }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Select
                                // disabled={Number(select.area_id) !== 0} // TODO 判断是否可以修改
                                value={areaId}
                                onSelect={(value, opt) => {
                                    switchArea(value, "first", opt);
                                }}
                                placeholder="请选择区"
                                bordered={false}
                            >
                                {areaList.map((item: any) => {
                                    return (
                                        <Option value={item.id} key={item.id} selectRow={item}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    )}

                    {/* 反选没有地址可以选择的时候 */}

                    {addressList.length === 0 && (
                        <div
                            className="dis-address"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Select
                                value={provinceId}
                                onSelect={(value) => {
                                    switchProvince(value, "reverse");
                                }}
                                placeholder="请选择省"
                                bordered={false}
                            >
                                {provinceList.map((item: any) => {
                                    return (
                                        <Option value={item.id} key={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    )}
                    {addressList.length === 0 && (
                        <div
                            className="dis-address"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Select
                                value={cityId}
                                onSelect={(value) => {
                                    switchCity(value, "reverse");
                                }}
                                placeholder="请选择市"
                                bordered={false}
                            >
                                {cityList.map((item: any) => {
                                    return (
                                        <Option value={item.id} key={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    )}
                    {addressList.length === 0 && (
                        <div
                            className="dis-address"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Select
                                value={areaId}
                                onSelect={(value) => {
                                    switchArea(value, "reverse");
                                }}
                                placeholder="请选择区"
                                bordered={false}
                            >
                                {areaList.map((item: any) => {
                                    return (
                                        <Option value={item.id} key={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    )}
                </div>
                {autoTime && showDelivery && (
                    <div
                        className="dis-time"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <div className="title">
                            配送时间<span className="mustInput">*</span>
                        </div>
                        <div className="DisTime">
                            <span
                                className="icon iconfont iconshouyintai-rili1"
                                onClick={() => {
                                    setTimeOpen(true);
                                }}
                            />
                            <DatePicker
                                value={disTime}
                                open={timeOpen}
                                type="dateTime"
                                placeholder="选择配送时间"
                                format="yyyy年MM月dd日 hh:mm a"
                                hideDisabledOptions={false}
                                onBlur={() => {
                                    setTimeOpen(false);
                                }}
                                onFocus={() => {
                                    setTimeOpen(true);
                                }}
                                onChange={(date) => {
                                    console.log("datedate2333344444", date);
                                    setDisTime(dayjs(date as any).unix() * 1000 as any);
                                }}
                                disabledDate={(date) => {
                                    return dayjs(date).unix() < dayjs().unix() - 86400;
                                }}
                                timePickerOpts={{
                                    // use12Hours: true,
                                    minuteStep: 10,
                                    hideDisabledOptions: true,
                                    // disabledHours: () => [0, 1, 2, 3, 4, 5, 6],
                                    // disabledMinutes: () => [0, 1, 2, 3, 4, 5],
                                    // secondStep: 10,
                                }}
                            // defaultValue={new Date(new Date().valueOf())}
                            />
                        </div>
                    </div>
                )}

               {autoTime && !showDelivery && <div
                    className="dis-time"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <div className="title">
                        出品时间<span className="mustInput">*</span>
                    </div>
                    <div className="DisTime">
                        <span
                            className="icon iconfont iconshouyintai-rili1"
                            onClick={() => {
                                setMakeOpen(true);
                            }}
                        />
                        <DatePicker
                            value={makeTime}
                            // value={ !showDelivery ? makeTime : (Number(disTime) - (intervalTime * 1000))} /* 出品时间 */
                            open={makeOpen}
                            type="dateTime"
                            placeholder="选择出品时间"
                            format="yyyy年MM月dd日 hh:mm a"
                            hideDisabledOptions={false}
                            onBlur={() => {
                                setMakeOpen(false);
                            }}
                            onFocus={() => {
                                setMakeOpen(true);
                            }}
                            onChange={(date) => {
                                console.log('datedatedate出品时间555', dayjs(date as any).unix() * 1000 as any)
                                setMakeTime(dayjs(date as any).unix() * 1000 as any);
                            }}
                            disabledDate={(date) => {
                                return dayjs(date).unix() < dayjs().unix() - 86400;
                            }}
                            timePickerOpts={{
                                minuteStep: 10,
                                hideDisabledOptions: true,
                            }}
                        />
                    </div>
                </div>}
            </div>
            <div className="customerInfo fb">
                <div className="customer-name">
                    <div className="title">姓名</div>
                    <Input
                        value={disName}
                        placeholder="姓名(非必填)"
                        onChange={(value: any) => {
                            setDisName(value);
                        }}
                    />
                </div>
                <div className="customer-phone">
                    <div className="title">
                        联系方式 <span className="mustInput">*</span>
                    </div>
                    <Input
                        value={disNumber}
                        maxLength={11}
                        placeholder="收货人手机号"
                        onChange={(value: any) => {
                            setDisNumber(getNumber(value));
                        }}
                    />
                </div>
            </div>
            <div className="address-detail">
                <div className="title">详细地址</div>
                <Input
                    value={disDetailAddress}
                    placeholder="详细地址(非必填)"
                    onChange={(value: any) => {
                        setDisDetailAddress(value);
                    }}
                />
            </div>
            <div className="remarks fb">
                <div className="goods-remark">
                    <div className="title">商品备注</div>
                    <Input
                        value={disGoodsRemark}
                        placeholder="商品备注(非必填)"
                        onChange={(value: any) => {
                            setDisGoodsRemark(value);
                        }}
                    />
                </div>
                <div className="dis-remark">
                    <div className="title">配送备注</div>
                    <Input
                        value={disRemark}
                        placeholder="配送备注(非必填)"
                        onChange={(value: any) => {
                            setDisRemark(value);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default DistributionInfoDialog;
