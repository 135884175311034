/* eslint-disable no-unused-expressions */
import ReactDOM from 'react-dom';
import App from './App';
import {
    BrowserRouter,
    useHistory
} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';
import 'dayjs/locale/zh-cn';
import zhCN from 'antd/lib/locale/zh_CN';
import 'antd/dist/antd.min.css';
import 'zarm/dist/zarm.min.css';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import './App.scss';

dayjs.locale('zh-cn');

// ReactDOM.createRoot(document.getElementById("root")).render(
//     <ConfigProvider locale={zhCN}>
//         <Provider store={store}>
//             < App />
//         </Provider>
//     </ConfigProvider>
// );
/*  */

ReactDOM.render(

    <ConfigProvider locale={zhCN}>
        <Provider store={store}>
            <BrowserRouter>
                < App />
            </BrowserRouter>
        </Provider>
    </ConfigProvider>,
    document.getElementById('root')
);
// reportWebVitals();
