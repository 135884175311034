import { message } from "antd";
import { handleOrder } from "../services/order";
import { HandlerVipBalance } from "../services/vipcard";
import { clearKeyTime, startInterval } from "./interval";
import { setStorageSync } from "./uitls";

const getPayResult = (flag, data, cb) => {

  const type1 = 'SEARCH_ORDER'
  const type2 = 'GET_PAY_CODE'
  const type3 = 'LISTEN_SCAN_BOX_CODE'
  const type4 = 'USER_PAY_CODE'
  var rule = 10
  return new Promise((r, j) => {
    /** 查询 */
    startInterval(type1, async () => {
      console.log('开始查询');
      rule--
      const result = await (flag !== 'vip' ? handleOrder(data) : HandlerVipBalance(data));
      setStorageSync('ORDER_STATUS', result?.result?.status)
      if (!result) {
        r(false)
        clearKeyTime(type1)
        clearKeyTime(type2)
        clearKeyTime(type3)
        clearKeyTime(type4)
        return false
      }
      if (result?.result?.pay_status === 3) { // 支付成功
        message.success(result?.result?.pay_status_msg)
        r(result)
        cb()
        clearKeyTime(type1)
        clearKeyTime(type2)
        clearKeyTime(type3)
        clearKeyTime(type4)
        return
      } else if (result?.result?.pay_status === 2) { // 支付中
        // showLoading('查询中...');
        if (rule < 0) {
          clearKeyTime(type2)
          clearKeyTime(type3)
          clearKeyTime(type4)
        }
      }
      else if (result?.result?.pay_status === 4) { // 支付失败
        clearKeyTime(type1)
        clearKeyTime(type2)
        clearKeyTime(type3)
        clearKeyTime(type4)
      }
      else if (result?.result?.status === 600) { // 订单关闭
        clearKeyTime(type1)
        clearKeyTime(type2)
        clearKeyTime(type3)
        clearKeyTime(type4)
      }

    }, 3000, false);
  })
}
export default getPayResult