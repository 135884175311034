import { Input } from '@douyinfe/semi-ui';
import { FC, useState, useEffect } from 'react'
import { clearKeyTime, getNumber } from "../../../../utils/interval";
import { getGiftCardDetail, getRechargeSend } from '../../../../services/vipcard'
import "./index.scss";
import { message } from 'antd';

interface IGiftCardInfo {
    giftCardInfo: any;
    orderInfo?: any;
    balanceNotEnough: boolean;
    init: Function;
}

const GiftCardInfo: FC<IGiftCardInfo> = (props: IGiftCardInfo) => {
    const { orderInfo, giftCardInfo, balanceNotEnough, init} = props;
    const [flag, setFlag] = useState(false)
    const [giftNotEnough, setGiftNotEnough] = useState(true)
    const [giftList, setGiftList] = useState([
        (giftCardInfo?.balance > (
            Number(orderInfo?.order_amount) +
            Number(orderInfo?.order_discount_amount)
        ).toFixed(2)) && {
            giftCardNumber: "",
            placeholderGift: "请输入礼品卡号",
            subValue: "",
            giftValue: ""
        }
    ].filter(Boolean));



    const getGiftCartDetail = (item, index) => {
        let gifttotal = 0
        // let hasRepeat = false; // 是否重复输入礼品卡号
        // debugger;

        const hasRepeat = giftList.some((v,i) => (i !== index && v.giftCardNumber === item.giftCardNumber))

        if(hasRepeat) return  message.error('礼品卡不可同一张添加');
        
        if (!item.giftCardNumber) {
            message.error('请输入礼品卡卡号')
            return false;
        } else {
            const params = {
                gift_card_id: item.giftCardNumber,
            };
            getGiftCardDetail(params).then((res) => {
                console.log('resresres', res)
               
                if (Object.keys(res).length) {

                    const newGiftList = giftList.map((v, i) => {
                        return i === index ? {
                            giftCardNumber: item.giftCardNumber,
                            placeholderGift: "请输入礼品卡号",
                            subValue: res.balance,
                            giftValue: res.amount
                        } : v
                    });
                    
                    setGiftList(newGiftList)
                    // debugger;
                    
                    const gifttotal = newGiftList.reduce((prev,next)=> prev + next.giftValue === '' ? 0 : parseFloat(next.giftValue), 0)
                    console.log(giftCardInfo?.balance , gifttotal , res.balance < 
                        Number(orderInfo?.order_amount),
                        Number(orderInfo?.order_discount_amount)
                        );
                    // 计算礼品卡抵扣的金额是否足够    
                    setGiftNotEnough(parseFloat(giftCardInfo?.balance) + gifttotal < (
                        Number(orderInfo?.order_amount) +
                        Number(orderInfo?.order_discount_amount)
                    ).toFixed(2)/* orderInfo?.order_amount */)
                    // setGiftCardInfo(res);
                    // if (!memberCardShare || !useMemberCard) {
                    //     setGiftCardShow(true)
                    // }
                } else {
                    setGiftNotEnough(false)
                    // message.error("请输入正确的礼品卡卡号");
                    // setGiftCardShow(false)
                    // return false
                }
                setFlag(true)
                
                const giftArrSet = new Set();;
                giftList.forEach((item)=>giftArrSet.add(item.giftCardNumber))
                const giftArr = Array.from(giftArrSet)
                const str = giftArr.join(',');
                init(str)
            });
        }
    }

    const giftNode = (item, index) => {
        return <div>
            <div>
                {
                    item?.giftValue ? null:  <div className='giftCard flex'>
                    <Input
                        showClear
                        value={item.giftCardNumber}
                        placeholder={item.placeholderGift}
                        prefix={<span className="iconfont icona-shouyintaisousuo" />}
                        onFocus={(value: any) => {
                            /* setGiftList(giftList.map((v, i) => {
                                return i === index ? {
                                    'giftCardNumber': value,
                                    'placeholderGift': "请输入礼品卡号"
                                } : v
                            })) */
                            // setPlaceholderGift('')
                        }}
                        onChange={(value: any) => {
                            if(Number(value) === giftCardInfo.gift_card_id) 
                            { 
                                message.error('礼品卡不可同一张添加')
                                return false
                            }
                            else
                            {
                                setGiftList(giftList.map((v, i) => {
                                    return i === index ? {
                                        'giftCardNumber': value,
                                        'placeholderGift': "请输入礼品卡号"
                                    } : v
                                }))
                            }
                        }}
                        // onEnterPress={(e) => {
                        //     getGiftCartDetail(item)
                        // }}
                    />
                    <div className="search" onClick={() => { getGiftCartDetail(item, index) }}>
                        查找
                    </div>
                </div>
                }
              
                {item?.giftValue && <div className="memberName">
                    礼品卡名称：<span>{Number(item?.giftValue).toFixed(2)}元礼品卡</span>
                </div>}
                {item?.giftValue && <div className="memberBalance">
                    当前余额：
                    <span>
                        <span className="balanceUnit">￥</span> {item?.subValue}
                    </span>
                </div>}
                {/* item?.subValue && <div className="memberBalance">
                    还需支付金额：
                    <span>
                        <span className="balanceUnit">￥</span> {item?.subValue}
                    </span>
                </div> */}
            </div>
        </div>
    }

    const handlerAdd = () => {
        // if(!giftList.some(item => item.giftCardNumber === '')) 
        
        setGiftList([...giftList, {
            giftCardNumber: "",
            placeholderGift: "请输入礼品卡号"
        }])
        setGiftNotEnough(false);
    }

    return (
        <div className="giftInfo">
            <div className="title flex">
                <div className="icon iconfont iconshouyintai-huiyuanxinxi"></div>
                <div className="text">礼品卡信息</div>
            </div>
            <div className="content">
                <div className="memberName">
                    礼品卡名称：<span>{Number(giftCardInfo?.amount).toFixed(2)}元礼品卡</span>
                </div>
                <div className="memberBalance">
                    当前余额：
                    <span>
                        <span className="balanceUnit">￥</span> {giftCardInfo?.balance}
                    </span>
                </div>
                {giftList.length > 0 && giftList.map((item, index)=>{
                    return  giftNode(item, index)
                })}
                {balanceNotEnough && giftNotEnough && (
                    <div className="balance-not-enough" onClick={() => { handlerAdd() }}>余额不足，请点击此处继续添加礼品卡</div>
                )}
                {/* {balanceNotEnough && (
                        <div className="balance-not-enough">余额不足，请选择其他支付方式</div>
                    )} */}
            </div>
            {/* {balanceNotEnough && (
                <div className="recharge fb">
                    还需支付<span>¥{orderInfo?.order_amount}</span>
                </div>
            )} */}

        </div>
    );
};

export default GiftCardInfo;
