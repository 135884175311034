import React, { FC, useState, useEffect } from 'react'
import { Input } from "@douyinfe/semi-ui";

import { getRechargeSend } from '../../../../services/vipcard'

import "./index.scss";

interface IMemberInfo {
    memberInfo: any;
    rechargeOrder?: boolean; // 是否为充值订单
    balanceNotEnough?: boolean;
    inputValue: any;
    sendValue?: any;
    setMember(params: any): void;
    getRechargeMoney?(value: any): void;

}

const MemberInfo: FC<IMemberInfo> = (props: IMemberInfo) => {
    const { memberInfo, rechargeOrder, inputValue, balanceNotEnough, setMember, getRechargeMoney } = props;
    const [sendPrice, setSendPrice] = useState('')

    useEffect(() => {
        setSendPrice('0')
    }, [rechargeOrder, inputValue])

    return (
        <div className="memberInfo">
            <div className="title flex">
                <div className="icon iconfont iconshouyintai-huiyuanxinxi"></div>
                <div className="text">会员信息</div>
            </div>
            <div className="content">
                <div className="memberName">
                    会员名称：<span>{memberInfo?.name}</span>
                </div>
                <div className="memberType">
                    会员类型：<span>{memberInfo?.card.card_name}</span>
                </div>
                <div className="memberNumber">
                    会员联系方式：<span>{memberInfo?.mobile}</span>
                </div>
                <div className="memberBalance">
                    会员当前余额：
                    <span>
                        <span className="balanceUnit">￥</span> {memberInfo?.balance}
                    </span>
                    {!rechargeOrder && balanceNotEnough && (
                        <div className="balance-not-enough">余额不足请充值</div>
                    )}
                </div>
            </div>
            {/* {balanceNotEnough && ( */}
            <div className="recharge">
                <div className='row-one flex'>
                    <span className="recharge-title">充值金额</span>
                    <Input
                        // type='number'
                        placeholder="输入充值金额"
                        value={inputValue}
                        onChange={(value) => {
                            getRechargeMoney(value.trim())
                            setMember(value)
                            const params = { price: (Number(value) * 100) }
                            getRechargeSend(params).then((res) => {
                                setSendPrice(res.gift_price)
                            })
                        }}
                    />
                </div>
                {Number(sendPrice) !== 0 && <div className='row-two'>
                    <span className="gift-amount">
                        <span>赠送金额：￥</span>
                        <span>{sendPrice}</span>
                    </span>
                </div>}
            </div>
            {/* )} */}
        </div>
    );
};

export default MemberInfo;
