import { FC, useState, useEffect } from "react";
import { Input } from "@douyinfe/semi-ui";
import { message } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import MemberInfo from "../../payDialog/memberPayDialog/memberInfo";
import { getNumber } from "../../../utils/interval";
import { actions } from "../../../store/global";
import { ModalArr } from "../../../constants/comment";

import "./index.scss";

interface IMemberRechargeProps {
  inputValue?: string;
  memberNumber: string;
  memberInfo: any;
  sendValue: any;
  showMemberInfo: boolean;
  add(handle: any): void;
  setScanShow(type: boolean): void; //扫码弹窗
  setCashShow(type: boolean): void; //现金弹窗
  getMemberNumber(type: any): void;
  getRechargeMoney?(type: any): void;
  getMemberDetail(): void;
}

// 会员充值弹窗
const MemberRecharge: FC<IMemberRechargeProps> = (
  props: IMemberRechargeProps
) => {
  const {
    memberInfo,
    inputValue,
    memberNumber,
    sendValue,
    showMemberInfo,
    add,
    getMemberNumber,
    setScanShow,
    setCashShow,
    getRechargeMoney,
    getMemberDetail,
  } = props;

  const [placeholder, setPlaceholder] = useState("请输入")
  const dispatch = useDispatch()

  return (
    <div className="memberRecharge">
      <div className="title">会员充值</div>
      <div className="content">
        <div className="memberPhone fb">
          <div>会员联系方式</div>
          <Input
            showClear
            value={memberNumber}
            maxLength={11}
            placeholder={placeholder}
            prefix={<span className="iconfont icona-shouyintaisousuo" />}
            onFocus={() => {
              setPlaceholder('')
            }}
            onBlur={() => {
              setPlaceholder("请输入");
            }}
            onChange={(value: any) => {
              getMemberNumber(getNumber(value));
            }}

            onEnterPress={(e) => {
              getMemberDetail()

            }}
          />
          <div
            className="search"
            onClick={() => {
              getMemberDetail();
            }}
          >
            查找
          </div>
        </div>
        {memberNumber && showMemberInfo && (
          <MemberInfo
            memberInfo={memberInfo}
            rechargeOrder={true}
            balanceNotEnough={true}
            setMember={add}
            inputValue={inputValue}
            sendValue={sendValue}
            getRechargeMoney={getRechargeMoney}
          />
        )}
        {memberNumber && showMemberInfo && (
          <div className="payMethod fc">
            <div
              className="scanningPay fc"
              onClick={() => {
                if (!Boolean(Number(inputValue))) {
                  message.error("请输入充值金额");
                } else {
                  setScanShow(true);
                }
              }}
            >
              <span className="icon iconfont iconshouyintai-saomajiezhang"></span>
              <span className="type">扫码充值</span>
            </div>

            <div
              className="balancePay fc"
              onClick={() => {
                if (!Boolean(Number(inputValue))) {
                  message.error("请输入充值金额");
                } else {
                  setCashShow(true);
                }
              }}
            >
              <div className="icon iconfont iconshouyintai-xianjinchongzhi"></div>
              <div className="type">线下充值</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MemberRecharge;
