import { FC, useState, useContext, useEffect } from "react";
import dayjs from "dayjs";
import { Modal } from "@douyinfe/semi-ui";
import { makeOrder } from "../../services/payment";
import { formatUrl, weekdeta } from "../../utils/format";
import { getOrderDetail } from "../../services/order";
import { EOrderHandle, OrderDeliveryType, OrderStatusColor, Ordertype, OrderTypeIndex } from "../../constants/order";
import { handleOrder } from "../../services/order";
import ProHeader from "../component/proheader";
import OrderDetail from "../component/orderdetail";
import MakeProduct from "../component/MakeProduct";
import OrderNotes from "../component/orderNotes";
import PullBox from "../../global-component/pullBox/pull";
import { INIT } from '../../constants/order';
import "./index.scss";
import PayMethod from "../component/paymethod";
import PayMethodDialog from "../../dialog/payDialog/payMethodDialog";
import PopUp from "../../global-component/pop-up/PopUp";
import { clearKeyTime } from "../../utils/interval";
import ScanningPayDialog from "../../dialog/payDialog/scaningPayDialog";
import MemberPayDialog from "../../dialog/payDialog/memberPayDialog";
import GiftCardPayDialog from "../../dialog/payDialog/giftCardPayDialog";
import { getStorageSync } from "../../utils/uitls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { useHistory } from "react-router-dom";

let money = {} as any;

const Detail: FC = (props) => {

  const order_id = formatUrl()?.id;
  const orderType = formatUrl()?.type;
  const cartStore = useSelector((state: any) => state.cart, shallowEqual);
  const [current, setCurrent] = useState(""); //当前所在路径对应的订单类型
  const [info, setInfo] = useState<any>({});
  const [disInfo, setDisInfo] = useState<any>({});
  const [scanShow, setScanShow] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState<string>('')

  const selfinfo =
    getStorageSync("self-info") !== "" ? getStorageSync("self-info") : {};
  const distributeinfo =
    getStorageSync("distribute-info") !== ""
      ? getStorageSync("distribute-info")
      : {};
  const hasProduct = Boolean(cartStore?.previewData?.sku_count);

  const hasSelfInfo = Boolean(
    OrderTypeIndex[getStorageSync("orderType")] === 0 || selfinfo.selfNumber
  );

  const hasDisInfo = Boolean(
    OrderTypeIndex[getStorageSync("orderType")] === 0 ||
    (distributeinfo.disNumber && Boolean(disInfo.disAddressInfo))
  );

  const offlinePay = async () => {
    if (money?.remain < 0 || money.actualPay === "") {
      message.error("请输入大于应收的金额");
      return;
    } else {
      // const params = {
      //   number: "",
      //   pay_method: getStorageSync('payMethod').pay_method,
      //   pay_channel: getStorageSync('payMethod').pay_channel,
      //   pay_type: getStorageSync('payMethod').pay_type,
      //   custom_item_id: getStorageSync('payMethod')?.custom_item_id,
      //   delivery_type:
      //     OrderTypeIndex[JSON.parse(localStorage.getItem("orderType") as string).data],
      //   order_type:
      //     Ordertype[JSON.parse(localStorage.getItem("orderType") as string).data],
      //   // 自提信息
      //   name: "",
      //   phone: selfinfo?.selfNumber || distributeinfo?.disNumber,
      //   pre_pick_up_at: selfinfo?.selfTime || "",
      //   // 配送信息
      //   pre_delivery_at: distributeinfo.disTime, // 配送时间
      //   pre_make_at:
      //     OrderTypeIndex[getStorageSync("orderType")] === 0
      //       ? 0
      //       : OrderTypeIndex[getStorageSync("orderType")] === 1
      //         ? selfinfo.selfTime - 1800
      //         : distributeinfo.disTime - 1800, // 出品时间
      //   remark: distributeinfo.disGoodsRemark,
      //   delivery_remark: distributeinfo.disRemark,
      //   contact_name: distributeinfo.disName,
      //   address: distributeinfo.disDetailAddress,
      //   location: 0,
      //   province_id: distributeinfo.disAddressInfo?.province_id,
      //   city_id: distributeinfo.disAddressInfo?.city_id,
      //   area_id: distributeinfo.disAddressInfo?.area_id,

      //   sku_items: cartStore.products.map((v: any) => {
      //     return {
      //       sku_id: v?.sku.sku_id,
      //       count: v?.count,
      //     };
      //   }),
      // };
      const params = {
        order_id: order_id,
        action: 1,
      }
      const res = await handleOrder(params)
      if (res) {
        history.push(`/orderdetail?type=${orderType}&id=${order_id}`);
      }
    }
  }

  const getOrderId = (id: string) => {
    setOrderId(id)
  }

  const cancelOrder = () => {
    const data = {
      order_id: getStorageSync('ORDER_ID'),
      action: EOrderHandle.CANCEL,
    };
    handleOrder(data).then((res) => {
      console.log(res, '订单关闭');

    });
  }

  const payArray = [
    {
      key: "扫码结账",
      icon: "iconfont iconshouyintai-saomajiezhang",
      value: "code",
      component: () => {
        return (
          <>
            <div className="fdc" onClick={() => setScanShow(true)}>
              <div className="icon-box iconfont iconshouyintai-saomajiezhang"></div>
              <div>扫码结账</div>
            </div>
            <Modal
              style={{
                width: "70rem",
              }}
              onCancel={() => {
                setScanShow(false);
                if (getStorageSync('ORDER_ID') && getStorageSync('ORDER_STATUS') === 100) {
                  cancelOrder()
                }
                console.log(12333, 'guabi');
                clearKeyTime("SEARCH_ORDER");
                clearKeyTime("GET_PAY_CODE");
                clearKeyTime("LISTEN_SCAN_BOX_CODE");
                clearKeyTime("USER_PAY_CODE");
              }}
              centered
              title="请选择支付方式"
              visible={scanShow}
              footer={<div style={{ height: "2rem" }}></div>}
            >
              <ScanningPayDialog
                OrderAmount={info?.order_id === '' ? cartStore?.previewData?.order_amount : info?.order_amount}
                paymethod={info?.pay_channel}
                orderid={info?.order_id}
                status={info.status}
                getOrderId={getOrderId}
                setScanShow={setScanShow}
                type="proOrder"
              />
            </Modal>
          </>
        );
      },
    },
  ];

  const style = { overflowY: "auto", maxHeight: 'calc(100vh - 15rem)' };

  useEffect(() => {
    const params = {
      order_id: order_id,
    };
    getOrderDetail(params).then((res) => {
      setInfo(res);
      setCurrent(OrderDeliveryType[res?.delivery_type]?.name);
    });
  }, [props]);

  const detaiTime = (type: any) => {
    switch (type) {
      case 0:
        return dayjs(Number(info?.create_at) * 1000).format("YYYY-MM-DD HH:mm");
      case 1:
        return dayjs(Number(info?.pre_pick_up_at) * 1000).format(
          "YYYY-MM-DD HH:mm"
        );
      case 2:
        return dayjs(Number(info?.pre_delivery_at) * 1000).format(
          "YYYY-MM-DD HH:mm"
        );
      default:
        break;
    }
  };

  return (
    <div className="OrderDetail">
      <header className="fb">
        <div className="left-box flex">
          <div className="header-title flex">
            <div>
              <span className="purple">{info?.sequence}号</span>
            </div>
          </div>
        </div>
        <div className="right-box flex">
          <div className="one-box">
            <div className="type-box">{current}</div>
            <div className="time-box">
              <span style={{ marginRight: "1rem" }}>
                {weekdeta(Number(info?.create_at) * 1000).str}单
              </span>
              {detaiTime(info?.delivery_type)}
            </div>
          </div>
          <div className="two-box">
            <div
              className="type-box fc"
              style={{
                backgroundColor:
                  OrderStatusColor[info?.status_msg]?.color || "#46cb81",
              }}
            >
              {info?.status_msg}
            </div>
          </div>
        </div>
      </header>
      <PullBox
        maxHeight={style.maxHeight}
        noRequest={true}
      >
        <div className="content-box" style={{ paddingBottom: '6rem' }}>
          <ProHeader />
          <MakeProduct info={info} />
          {/* <ShowCount info={info} /> */}

          <div className="detail-box">
            <OrderNotes info={info} />
            {current === "配送单" && (
              <OrderDetail type="distribute" info={info} />
            )}
            {current === "自提单" && <OrderDetail type="self" info={info} />}
            <OrderDetail type="detail" info={info} />
            <OrderDetail type="token" info={info} />
          </div>
        </div>
      </PullBox>
      {/* info.status === INIT && <div style={{ width: '40%', position: 'fixed', bottom: '0' }}>
        <PayMethod payArray={payArray} />
      </div> */}
    </div>
  );
};

export default Detail;
