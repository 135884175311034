import { combineReducers } from '@reduxjs/toolkit'
import cart from './cart';
import global from './global'
import order from './order';


/**
 * 合并reducers
 */
const reducers = {
    global,
    cart,
    // order,
}

const reducer = combineReducers(reducers)
export default reducer;