
let baseURL = '';


// process.env.NODE_ENV === 'development'
//     // ? 'http://47.108.88.248:9700/api/v1'
//     ? 'https://api-test-sensen.fosuss.com/dev/sensen/api/v1'
//     : 'https://api-malicake-mall.fosuss.com/api/v1' /* process.env.HOSTURL || 'http://localhost:8701'; */
// // //接口地址

// export const baseURL = 'https://api-test-sensen.fosuss.com/dev/sensen/api/v1'
console.log(process.env.zm, "------")
console.log(process, 123);

switch (process.env.zm) {
    case 'start':
    case 'dev':
        baseURL = 'https://api-test-sensen.fosuss.com/dev/sensen/api/v1';
        break;
    case 'test':
        baseURL = 'https://api-test-sensen.fosuss.com/test/sensen/api/v1';
        break;
    case 'prod':
        baseURL = 'https://api-sensen.fosuss.com/shz/api/v1';
        break;
    default:
        break;
}


export const timeout = 5000;                    //请求延迟

export { baseURL };