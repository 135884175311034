/**
 *  会员卡等API
 */

import request from '../http/index'

// 获取礼品卡详情
export const getGiftCardDetail = async (params) => {
    const result = await request({
        method: 'post',
        url: '/cashier/shop/gift-card/detail',
        data: {
            "gift_card_id": params.gift_card_id,
        },
    })
    return result;
}


// 获取会员订单列表
export const addVipCard = async (data) => {
    const result = await request({
        method: 'post',
        url: '/cashier/user/card/create',
        data,
    })
    return result;
}

// 会员卡类型id

export const getVipIDs = async (params) => {
    const result = await request({
        method: 'post',
        url: '/cashier/user/card/card',
        data: {
            page: {
                all: 0,
                total: 1,
                page: params.page || 1,
                page_size: 10
            }
        },
    })
    return result;
}

// 获取会员卡详情
export const getVipCardDetail = async (params) => {
    const result = await request({
        method: 'post',
        url: '/cashier/user/card/detail',
        data: {
            "mobile": params.mobile,
            "condition": {
                "with_card": "1"
            }
        },
    })
    return result;
}

// 新增会员
export const createVipInfo = async (data) => {
    const result = await request({
        method: 'post',
        url: '/cashier/user/card/create',
        data,
    }, true)
    return result;
}


// 会员充值余额
export const rechargeVipBalance = async (params) => {
    const result = await request({
        method: 'post',
        url: '/cashier/trade/recharge/make',
        data: {
            "user_id": params.user_id,
            "price": Number(params.price) * 100,
            "user_member_card_id": params.user_member_card_id,

            "mobile": params.mobile,
            "config": {
                "pay_type": params.pay_type,
                "pay_method": params.pay_method,
                "pay_channel": params.pay_channel,
                "custom_item_id": params.custom_item_id,
            },
            "remark": params.remark
        },
    })
    return result;
}

// 会员充值余额后的handler查询
export const HandlerVipBalance = async (params) => {
    const result = await request({
        method: 'post',
        url: '/cashier/order/recharge/handle',
        data: {
            "order_id": params.order_id,
            "action": params.action,
            "params": params.params
        },
    })
    return result;
}

// 会员获取赠送金额
export const getRechargeSend = async (params) => {
    const result = await request({
        method: 'post',
        url: '/cashier/trade/recharge/gift',
        data: {
            "price": params.price
        },
    })
    return result;
}
