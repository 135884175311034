import { FC } from 'react'


import './index.scss'

interface ILoadingDialogProps {
    setLoadingShow?(type: boolean): void;

}
// loading弹窗
const LoadingDialog: FC<ILoadingDialogProps> = (props: ILoadingDialogProps) => {

    return (
        <div className='loading-dialog flex'>
            <div className="loadingIcon fa">
                <div className="load1" />
                <div className="load2" />
                <div className="load3" />
                <div className="load4" />
            </div>
            <div>
                <div className="loading-title">正在处理订单</div>
                <div className='loading-content'>用户付款中,请稍后</div>

            </div>

        </div>
    )

}

export default LoadingDialog
